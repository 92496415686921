<template>
  <div class="t-form">
    <el-form :model="paramsForm" ref="rForm" :rules="paramsForm.paramsRules">
      <el-table
        v-if="tableVisible"
        :show-overflow-tooltip="true"
        border
        fit
        :data="paramsForm.params"
        style="width: 100%"
      >
        <el-table-column align="center">
          <template slot="header">
            <span>
              <label class="r-import">*</label>
              {{ $t("el.planCourse.course") }}
            </span>
          </template>
          <template slot-scope="scope">
            <p class="single-line" v-show="!scope.row.isEdit">
              <tooltip-over
                :content="scope.row.courseName + ''"
                class="class-menu-name"
                :singleAble="true"
                :refName="'tooltipOvercourseName'"
              ></tooltip-over>
            </p>
            <p v-show="scope.row.isEdit">
              <el-form-item
                :prop="'params.' + scope.$index + '.courseId'"
                :rules="paramsForm.paramsRules.courseId"
              >
                <el-select
                  filterable
                  v-model="scope.row.courseId"
                  :placeholder="$t('el.planCourse.selectCourse')"
                  @change="
                    changeCourseIdGetClassList(scope.row.courseId, scope.$index)
                  "
                >
                  <el-option
                    v-for="(item, i) in courseOptions"
                    v-bind="item"
                    :key="i"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot="header">
            <span>
              <label class="r-import">*</label>
              {{ $t("el.planCourse.class") }}
            </span>
          </template>
          <template slot-scope="scope">
            <p class="single-line" v-show="!scope.row.isEdit">
              <tooltip-over
                :content="scope.row.className + ''"
                class="class-menu-name"
                :singleAble="true"
                :refName="'tooltipOverclassName'"
              ></tooltip-over>
            </p>
            <p v-show="scope.row.isEdit">
              <el-form-item
                :prop="'params.' + scope.$index + '.classId'"
                :rules="paramsForm.paramsRules.classId"
              >
                <el-select
                  filterable
                  v-model="scope.row.classId"
                  :placeholder="$t('el.planCourse.selectCourse')"
                  @focus="
                    getClassData(
                      scope.row.courseId,
                      scope.$index,
                      scope.row.classId
                    )
                  "
                >
                  <el-option
                    v-for="(item, i) in scope.row.classOptions"
                    v-bind="item"
                    :key="i"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </p>
          </template>
        </el-table-column>
        <el-table-column width="180" align="center">
          <template slot="header">
            <span>
              <label class="r-import">*</label>
              {{ $t("el.planCourse.instructor") }}
            </span>
          </template>
          <template slot-scope="scope">
            <p class="single-line" v-show="!scope.row.isEdit">
              <tooltip-over
                :content="scope.row.staffName + ''"
                class="class-menu-name"
                :singleAble="true"
                :refName="'tooltipOverstaffName'"
              ></tooltip-over>
            </p>
            <p v-show="scope.row.isEdit">
              <el-form-item
                :prop="'params.' + scope.$index + '.staffCode'"
                :rules="paramsForm.paramsRules.staffCode"
              >
                <el-select
                  filterable
                  v-model="scope.row.staffCode"
                  :placeholder="$t('el.planCourse.selectTeacher')"
                  @focus="
                    getTeacherData({
                      courseId: scope.row.courseId,
                      index: scope.$index,
                    })
                  "
                >
                  <el-option
                    v-for="(item, i) in scope.row.teacherOptions"
                    v-bind="item"
                    :key="i"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </p>
          </template>
        </el-table-column>
        <el-table-column width="180" align="center">
          <template slot="header">
            <span>
              <label class="r-import">*</label>
              {{ $t("el.planCourse.classroom") }}
            </span>
          </template>
          <template slot-scope="scope">
            <p class="single-line" v-show="!scope.row.isEdit">
              <tooltip-over
                :content="scope.row.classroomName + ''"
                class="class-menu-name"
                :singleAble="true"
                :refName="'tooltipOverclassroomName'"
              ></tooltip-over>
            </p>
            <p v-show="scope.row.isEdit">
              <el-form-item
                :prop="'params.' + scope.$index + '.classroomId'"
                :rules="paramsForm.paramsRules.classroomId"
              >
                <el-select
                  filterable
                  v-model="scope.row.classroomId"
                  :placeholder="$t('el.planCourse.chooseClassroom')"
                >
                  <el-option
                    v-for="(item, i) in classroomOptions"
                    v-bind="item"
                    :key="i"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </p>
          </template>
        </el-table-column>
        <el-table-column width="160" align="center">
          <template slot="header">
            <span>
              <label class="r-import">*</label>
              {{ $t("el.planCourse.forAWeek") }}
              <el-tooltip placement="top">
                <div slot="content">
                  {{ $t("el.planCourse.trainingSession") }}<br />{{
                    $t("el.planCourse.periodLength")
                  }}
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </span>
          </template>
          <template slot-scope="scope">
            <p v-show="!scope.row.isEdit">
              <span style="margin-left: 0.625rem">{{
                scope.row.courseCount
              }}</span>
            </p>
            <p v-show="scope.row.isEdit">
              <el-form-item
                :prop="'params.' + scope.$index + '.courseCount'"
                :rules="paramsForm.paramsRules.courseCount"
              >
                <el-input-number
                  :placeholder="$t('el.planCourse.pleaseFillWeek')"
                  @keyup.native="number($event)"
                  v-model="scope.row.courseCount"
                ></el-input-number>
              </el-form-item>
            </p>
          </template>
        </el-table-column>
        <el-table-column
          width="145"
          align="center"
          :label="$t('el.common.operate')"
        >
          <template slot-scope="scope">
            <el-button
              class="t-form-operate-btn"
              v-show="!scope.row.isEdit"
              type="primary"
              size="small"
              @click="editData(scope.$index)"
              >{{ $t("el.common.edit") }}</el-button
            >
            <el-button
              class="t-form-operate-btn"
              v-show="scope.row.isEdit"
              @click="savePlan(scope.$index)"
              type="primary"
              size="small"
              >{{ $t("el.common.save") }}</el-button
            >
            <el-button
              @click="deleteRow(scope.row.id, scope.$index)"
              class="t-form-operate-btn"
              type="primary"
              size="small"
              >{{ $t("el.common.delete") }}</el-button
            >
          </template>
        </el-table-column>
        <template slot="empty">
          <div class="no-data-pic">
            <img
              src="@/assets/images/pic_empty_people@2x.png"
              alt=""
              style="width: 16.875rem"
            />
          </div>
          <div
            style="
              color: rgba(100, 108, 149, 0.25);
              font-size: 1.125rem;
              font-weight: 600;
              letter-spacing: 0.0625rem;
            "
            class="no-data-text"
          >
            {{ $t("el.common.noData") }}
          </div>
        </template>
      </el-table>
    </el-form>
  </div>
</template>

<script>
import tooltipOver from "@/components/biz-components/tooltipOver/index.vue";
import { mapGetters } from "vuex";

export default {
  name: "tableForm",
  components: {
    tooltipOver,
  },
  props: {
    paramsForm: {
      type: Object,
      default: () => ({}),
    },
    tableVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["planObj"]),
  },
  data() {
    return {
      courseOptions: [],
      classOptions: [],
      teacherOptions: [],
      classroomOptions: [],
      planId: this.$route.query.id,
      schoolCode: "",
    };
  },
  async created() {
    let obj = JSON.parse(this.planObj);
    this.schoolCode = obj.schoolCode;
    await this.getCourseData();
    await this.getClassroomData();
  },
  methods: {
    // 初始化，获取课程下拉框选项
    async getCourseData() {
      let params = {
        planId: this.planId,
      };
      await this.$store.dispatch("getCourseOptions", params).then((res) => {
        let list = res.data || [];
        if (list.length > 0) {
          list.map((item) => {
            item.label = item.courseName;
            item.value = item.courseId;
          });
        }
        this.courseOptions = list;
      });
    },
    // 根据schoolCode获取教室下拉框数据
    async getClassroomData() {
      this.loading = true;
      let paramsData = {
        schoolCode: this.schoolCode,
        offset: 0,
        limit: 1000,
        enable: 1,
      };
      await this.$store.dispatch("getClassroomList", paramsData).then((res) => {
        if (res.success) {
          let classroomOptions = res.data.records || [];
          if (classroomOptions.length > 0) {
            classroomOptions.map((item) => {
              item.label = item.name;
              item.value = item.id;
            });
          }
          this.classroomOptions = classroomOptions;
        }
      });
    },
    // 获取 班级 下拉框数据
    async getClassData(courseId, index, classId) {
      console.log("courseId", courseId);
      if (courseId) {
        let data = {
          schoolCode: this.schoolCode,
          courseId: courseId,
        };
        let tableClassIds = [];
        this.paramsForm.params.forEach((item) => {
          if (item.classId) {
            tableClassIds.push(item.classId);
          }
        });
        await this.$store.dispatch("getClassOptions", data).then((res) => {
          let list = res.data || [];
          if (list.length > 0) {
            list.map((item) => {
              item.label = item.className;
              item.value = item.id;
              if (tableClassIds.indexOf(item.id) > -1) {
                if (item.id !== classId) {
                  this.$set(item, "disabled", true);
                } else {
                  this.$set(item, "disabled", false);
                }
              } else {
                this.$set(item, "disabled", false);
              }
            });
          }
          this.$emit("changeClassOptions", { list, index });
        });
      } else {
        this.$refs.rForm.validateField(
          [`params.${index}.courseId`],
          (Error) => {
            if (!Error) {
              // 验证通过
              console.log("通过了");
            } else {
              console.log("验证不通过");
            }
          }
        );
      }
    },
    /**
     * 下拉框选择班级之后
     * 获取教师下拉框的数据
     */
    async getTeacherData(data) {
      console.log(data);
      let form = {};
      if (data.subjectId && data.gradeId) {
        form = {
          schoolCode: this.schoolCode,
          subjectId: data.subjectId,
          gradeId: data.gradeId,
          courseId: data.courseId,
        };
      } else if (data.courseId) {
        // let tableCourseIds = [];
        // this.paramsForm.params.forEach((item) => {
        //   if (item.courseId) {
        //     tableCourseIds.push(item.courseId);
        //   }
        // });
        let subjectId, gradeId, courseId;
        if (this.courseOptions.length > 0) {
          this.courseOptions.forEach((item) => {
            if (~~item.courseId === ~~data.courseId) {
              subjectId = item.subjectId;
              gradeId = item.gradeId;
              courseId = item.courseId;
            }
          });
        }
        form = {
          schoolCode: this.schoolCode,
          subjectId,
          gradeId,
          courseId,
        };
      } else {
        this.$refs.rForm.validateField(
          [`params.${data.index}.courseId`],
          (Error) => {
            if (!Error) {
              // 验证通过
              console.log("通过了");
            } else {
              console.log("验证不通过");
            }
          }
        );
        return;
      }
      await this.$store.dispatch("getTeacherOptions", form).then((res) => {
        let list = res.data || [];
        if (list.length > 0) {
          list.map((item) => {
            item.label = item.staffName;
            item.value = item.staffCode;
          });
        }
        this.$emit("changeTeacherOptions", { list, index: data.index });
      });
    },
    // 校验 行内表单
    validateField(index) {
      let result = true;
      for (const item of this.$refs.rForm.fields) {
        if (~~item.prop.split(".")[1] === index) {
          this.$refs.rForm.validateField(item.prop, (error) => {
            if (error !== "") {
              result = false;
            }
          });
        }
      }
      return result;
    },
    // 清除校验
    clearAllValidate() {
      this.$nextTick(() => {
        this.$refs.rForm.clearValidate();
      });
    },
    // 点击保存
    savePlan(index) {
      let isvalidate = this.validateField(index);
      console.log(isvalidate);
      // 如果当前行 保存 通过校验，调用接口
      if (isvalidate) {
        console.log(this.paramsForm.params[index]);
        this.$emit("saveData", index);
      }
    },
    // 编辑
    editData(index) {
      console.log(index);
      // this.setCourseDisabled();
      this.$emit("editData", index);
    },
    // 课程的过滤
    setCourseDisabled() {
      let tableCourseIds = [];
      this.paramsForm.params.forEach((item) => {
        if (item.courseId) {
          tableCourseIds.push(item.courseId);
        }
      });
      if (this.courseOptions.length > 0) {
        this.courseOptions.forEach((item) => {
          if (tableCourseIds.indexOf(item.courseId) > -1) {
            this.$set(item, "disabled", true);
          } else {
            this.$set(item, "disabled", false);
          }
        });
      }
    },
    number(e) {
      // 禁用键盘
      var keynum = window.event ? e.keyCode : e.which;
      var keychar = String.fromCharCode(keynum);
      if (
        keynum === 189 ||
        keynum === 190 ||
        keynum === 110 ||
        keynum === 109
      ) {
        this.$message.warning(this.$t("el.common.NoDecimalsnegativeNumbers"));
        e.target.value = "";
      }
    },
    /**
     * 通过planId的值
     * 拼上CourseId的值
     * 获取班级下拉框的列表
     * 获取授课老师下拉框的列表
     */
    async changeCourseIdGetClassList(courseId, index) {
      // 重置班级数据
      this.$emit("changeCourseReset", index);
      // 过滤已选数据
      // let tableCourseIds = [];
      // this.paramsForm.params.forEach((item) => {
      //   if (item.courseId) {
      //     tableCourseIds.push(item.courseId);
      //   }
      // });
      // 获取班级下拉框数据
      await this.getClassData(courseId, index);
      console.log(courseId, index);
      let subjectId, gradeId, staffCode, classroomId;
      if (this.courseOptions.length > 0) {
        this.courseOptions.forEach((item) => {
          // if (tableCourseIds.indexOf(item.courseId) > -1) {
          //   this.$set(item, "disabled", true);
          // } else {
          //   this.$set(item, "disabled", false);
          // }
          if (~~item.courseId === ~~courseId) {
            subjectId = item.subjectId;
            gradeId = item.gradeId;
            staffCode = item.staffCode;
            classroomId = item.classroomId;
          }
        });
        console.log(staffCode, classroomId);
        // 根据 subjectId gradeId获取授课老师下拉框数据
        await this.getTeacherData({
          courseId,
          subjectId,
          gradeId,
          index,
        });
        // 如果有配置 自动填充 授课老师 和 教室
        if (!(!staffCode && !classroomId)) {
          this.$emit("autoGetStaffAndClassroom", {
            staffCode,
            classroomId,
            index,
          });
        }
        if (!(!subjectId && !gradeId)) {
          this.$emit("autoSetSubjectAndGradeId", {
            subjectId,
            gradeId,
            index,
          });
        }
      }
      // 当改变课程的时候，重新校验表单必填项的校验提示文字
      // this.validateField(index);
    },
    /**
     * 该行有id
     * 则调用接口删除
     */
    deleteRow(id, index) {
      this.$emit("deletePlanData", { id, index });
    },
  },
};
</script>

<style lang="less" scoped>
.t-form {
  &-operate-btn {
    color: #6049ff;
    background: none;
    box-shadow: none;
    border: none;
    padding: 0.5625rem 0;
  }
  .r-import {
    color: #f56c6c;
    margin-right: 0.25rem;
    vertical-align: middle;
  }
  .el-button + .el-button {
    margin: 0;
  }
  .el-button {
    padding: 0 0.625rem;
  }
  /deep/ .el-table--border {
    color: #282828;
    font-size: 0.875rem;
    th {
      background: #f5f6f7;
      padding: 0.625rem 0;
      color: #282828;
      font-weight: normal;
    }
    .el-form-item__error {
      padding-top: 0;
      width: 100%;
    }
    .el-form-item__content {
      line-height: 1.875rem;
    }
    .cell {
      overflow: unset;
      > p {
        margin: 0;
        height: 1.875rem;
        line-height: 1.875rem;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-input__inner {
        border: none;
        line-height: 0.0625rem !important;
        height: 1.875rem;
      }
      .el-input__icon {
        line-height: 1.875rem;
      }
      .el-input-number {
        line-height: 1.875rem;
        width: 100%;
        .el-input__inner {
          padding: 0;
        }
      }
      .el-input-number__decrease,
      .el-input-number__increase {
        display: none;
      }
    }
  }
  /deep/ input::-webkit-outer-spin-button,
  /deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  /deep/ input[type="number"] {
    -moz-appearance: textfield !important;
  }
  /deep/ input::-ms-input-placeholder {
    text-align: center;
  }
  /deep/ input::-webkit-input-placeholder {
    text-align: center;
  }
  .no-data-pic {
    font-size: 0;
    padding-top: 0.625rem;
    line-height: 0;
  }
  .no-data-text {
    color: rgba(100, 108, 149, 0.25);
    font-size: 1.125rem;
    font-weight: 600;
    letter-spacing: 0.0625rem;
    margin-top: -1.25rem;
  }
}
</style>
