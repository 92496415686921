<template>
  <!-- 选课详情 -->
  <div class="container-wrap">
    <AddCourse
      ref="addCourse"
      v-if="pageState === 2"
      :schoolYearList="schoolYearListTwo"
      :semesterList="semesterList"
      :courseRangeList="gradeList"
      :gradeSysList="gradeSysList"
    />
    <ViewResults v-if="pageState === 3" :taskId="taskId" />
  </div>
</template>
<script>
import AddCourse from "./addCourse.vue";
import ViewResults from "./viewResult.vue";
import {
  getClassSyslist,
  getClasslist,
  getschoolYearListTwo,
} from "@/api/manage/select-course";
export default {
  name: "selectCoursesDetail",
  components: {
    AddCourse,
    ViewResults,
  },
  data() {
    return {
      taskId: 0,
      pageState: 1,
      schoolYearListTwo: [],
      gradeList: [], // 选课范围下拉数据
      gradeSysList: [],
    };
  },
  watch: {
    "$route.query": {
      handler(newVal, oldVal) {
        console.log("route.query.watch ===== ", newVal, oldVal);
        this.taskId = parseInt(newVal.taskId);
        this.pageState = parseInt(newVal.pageState);
        const isEnd = newVal.pageState.isEnd;
        // pageState 2 新增/编辑 pageState 3 查看结果
        if (this.taskId) {
          if (this.pageState === 2) {
            this.$nextTick(() => {
              if (isEnd) {
                this.$refs.addCourse.isEnd = true;
              }
              this.$refs.addCourse.getTaskDetail(this.taskId);
            });
          }
        } else {
          this.$nextTick(() => {
            this.$refs.addCourse.resetChooseData();
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    semesterList() {
      return this.$store.state.dict.TERM.map((item) => ({
        label: item.dictionaryText,
        value: item.dictionaryKey,
      }));
    },
  },
  created() {
    this.getSchoolYearList();
    this.getCourseRangeList();
    this.getGradeList();
  },
  methods: {
    // 获取学年下拉数据 近2年
    getSchoolYearList() {
      getschoolYearListTwo().then(({ status, data }) => {
        if (status === 200) {
          this.schoolYearListTwo = data.map(({ name, id }) => ({
            label: name,
            value: id,
          }));
        }
      });
    },
    // 获取选课范围下拉列表
    getCourseRangeList() {
      getClasslist().then(({ status, data }) => {
        if (status === 200) {
          this.gradeList = data.map(({ gradeName, gradeId }) => ({
            label: gradeName,
            value: gradeId,
          }));
        }
      });
    },
    // 获取年级
    getGradeList() {
      getClassSyslist().then(({ status, data }) => {
        if (status === 200) {
          this.gradeSysList = data.map(({ name, id }) => ({
            label: name,
            value: id,
          }));
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container-wrap {
  background: #ffffff;
  padding: 30px 50px;
  border-radius: 10px;
  min-height: 660px;
}
</style>
