<template>
  <!-- 教室管理页面-->
  <div class="resourceManage">
    <div class="resourceManage-tool">
      <div class="flex f-c resourceManage-tool-col">
        <div class="flex" style="width: fit-content; line-height: 40px">
          <div class="f-l resourceManage-tool-title">
            {{ $t("el.infrastructure.nameClass") + $t("el.common.colon") }}
          </div>
          <el-input
            class="f-l"
            v-model="form.name"
            :placeholder="$t('el.common.pleaseEnter')"
            size="small"
            :maxlength="30"
            @keyup.enter.native="reGetData"
            style="width: 180px; margin-right: 30px"
          ></el-input>
        </div>

        <div class="flex" style="width: fit-content; line-height: 40px">
          <div class="f-l resourceManage-tool-title">
            {{ $t("el.planCourse.state") + $t("el.common.colon") }}
          </div>
          <el-select
            class="f-l"
            style="width: 135px; margin-right: 30px"
            v-model="form.enable"
            :placeholder="$t('el.common.pleaseSelect')"
            size="small"
          >
            <el-option
              v-for="item in statusList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </div>
      </div>
      <div class="f-c resourceManage-tool-col">
        <button class="btn-query" @click="reGetData">
          {{ $t("el.common.screening") }}
        </button>
        <button class="btn-reset" @click="reset('clear')">
          {{ $t("el.common.reset") }}
        </button>
        <button class="btn-add" @click="add">{{ $t("el.common.new") }}</button>
      </div>
    </div>
    <!-- 表格 -->
    <div class="resourceManage-table">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        :header-cell-class-name="headerStyle"
        :cell-class-name="cellStyle"
        style="width: 100%"
      >
        <template slot="empty">
          <div style="padding-top: 111px; line-height: 0">
            <img
              src="@/assets/images/pic_empty_people@2x.png"
              alt=""
              style="width: 270px"
            />
          </div>
          <div
            style="
              color: rgba(100, 108, 149, 0.25);
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 1px;
              padding-bottom: 120px;
            "
          >
            {{ $t("el.common.noData") }}
          </div>
        </template>
        <el-table-column
          prop="name"
          :label="$t('el.infrastructure.nameClass')"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="capacity"
          label="容纳人数"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.capacity }}{{ $t("el.infrastructure.numberPeople") }}
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="schoolName"
          :label="$t('el.planCourse.school')"
          :show-overflow-tooltip="true"
        >
        </el-table-column> -->
        <!-- <el-table-column
          prop="capacity"
          :show-overflow-tooltip="true"
          :label="$t('el.infrastructure.canAccommodateNumber')"
        >
        </el-table-column> -->
        <el-table-column
          prop="enable"
          label="使用状态"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <!-- <el-switch
              @change="
                changeStatus(scope.row.enableStatus, scope.row.id, scope.$index)
              "
              v-model="scope.row.enableStatus"
              active-color="#6049ff"
            >
            </el-switch> -->
            {{ getStatusName(scope.row.enable) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
              scope.row.createTime
                ? $utils.getFormatterDate(new Date(scope.row.createTime)) +
                  " " +
                  $utils.getFormatterTime(new Date(scope.row.createTime))
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('el.common.operate')" width="200px">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-btn="'btn-classroom-course'"
              @click="handleOperateClasscourse(scope.row)"
              >上课管理</el-button
            >
            <el-button
              type="text"
              v-btn="'btn-classroom-edit'"
              @click="handleEditClassroom(scope.row)"
              >{{ $t("el.common.edit") }}</el-button
            >
            <el-button
              type="text"
              v-btn="'btn-classroom-delete'"
              @click="handleDeleteClassroom(scope.row)"
              >{{ $t("el.common.delete") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分页  -->
    <div class="resourceManage-page" v-show="tableData.length > 0">
      <el-pagination
        layout="prev, pager, next"
        :current-page.sync="params.page"
        :page-size="params.limit"
        :total="total"
      >
      </el-pagination>
      <span class="el-pagination__total"
        >{{ $t("el.pagination.totalname") }}{{ Math.ceil(total / params.limit)
        }}{{ $t("el.pagination.pages") }} {{ total
        }}{{ $t("el.pagination.items") }}
      </span>
    </div>
    <!-- 新增/编辑教室 -->
    <el-dialog
      :visible.sync="editClassroomVisible"
      :width="$t('el.common.widthP40_50')"
    >
      <div class="classroom">
        <div Slot="title" class="classroomtitle">{{ computedTitle }}</div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          :label-width="$t('el.common.width120_240')"
          class="demo-ruleForm"
        >
          <el-form-item :label="$t('el.infrastructure.nameClass')" prop="name">
            <el-input
              v-focus
              style="width: 330px"
              :maxlength="20"
              v-model.trim="ruleForm.name"
              @input="handleNameInput"
            ></el-input>
          </el-form-item>
          <el-form-item label="容纳人数" prop="capacity">
            <el-input-number
              v-model="ruleForm.capacity"
              controls-position="right"
              :min="1"
              :max="2046"
            ></el-input-number>
          </el-form-item>
          <!-- <el-form-item
            :label="$t('el.infrastructure.accommodateNumber')"
            prop="capacity"
          >
            <el-input-number
              :min="1"
              @keyup.native="number($event)"
              v-model="ruleForm.capacity"
            ></el-input-number>
          </el-form-item> -->
          <el-form-item :label="$t('el.planCourse.state')" prop="enable">
            <el-radio-group
              :disabled="~~ruleForm.changeEnable === 1"
              v-model="ruleForm.enable"
            >
              <el-radio :label="1">{{ $t("el.common.enable") }}</el-radio>
              <el-radio :label="0">{{ $t("el.common.disable") }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="editClassroomVisible = false">{{
          $t("el.common.cancel")
        }}</el-button>
        <el-button size="small" type="primary" @click="sureClassroom">{{
          $t("el.common.confirm")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import focus from "@/directive/focus/index";

export default {
  name: "resourceManage",
  directives: {
    focus,
  },
  // 定义数据
  data() {
    return {
      loading: false, // 列表加载状态
      tableData: [],
      form: {
        // 查询条件
        name: "",
        // schoolCode: "",
        enable: "",
      },
      params: {
        // 分页
        limit: 10,
        offset: 0,
        page: 1,
      },
      total: 0,
      ruleForm: {
        // schoolCode: "",
        name: "",
        capacity: 1,
        enable: 1,
        changeEnable: null,
      },

      dialogManage: false,
      editClassroomVisible: false,
      updialogVisible: false,
      sharedialogVisible: false,
      textarea: "", // 下线理由
      downRow: null,
      upRow: null,
      shareRow: null,
      addOrEditId: "",
      // 默认学校
      defaultSchoolCode: "",
    };
  },
  // 计算属性
  computed: {
    ...mapGetters(["eduAuth"]),
    statusList() {
      return [
        {
          key: "",
          value: this.$t("el.schoolResourceManage.all"),
        },
        {
          key: 0,
          value: this.$t("el.common.disable"),
        },
        {
          key: 1,
          value: this.$t("el.common.enable"),
        },
      ];
    }, // 科目选项
    rules() {
      return {
        name: [
          {
            required: true,
            message: "教室名称为必填项",
            trigger: "blur",
          },
        ],
        capacity: [
          {
            required: true,
            message: "容纳人数为必填项",
            trigger: "change",
          },
        ],
        enable: [
          {
            required: true,
            message: this.$t("el.planCourse.SelectState"),
            trigger: "change",
          },
        ],
      };
    },
    computedTitle() {
      let title;
      if (this.addOrEditId === "") {
        title = this.$t("el.infrastructure.newClassroom");
      } else {
        title = this.$t("el.infrastructure.editClassroom");
      }
      return title;
    },
  },
  // 侦听器
  watch: {
    // 监听页数
    "params.page"(newVal, oldVal) {
      // 改变参数
      this.params.offset = this.params.limit * (this.params.page - 1);
      this.getData();
    },
  },
  // 创建完成钩子
  async created() {
    await this.getData();
  },
  // 挂在完成钩子
  mounted() {},
  // 方法定义
  methods: {
    getStatusName(enable) {
      return (
        this.statusList.filter((item) => item.key === enable)[0].value || "-"
      );
    },
    number(e) {
      // 禁用键盘
      var keynum = window.event ? e.keyCode : e.which;
      var keychar = String.fromCharCode(keynum);
      // eslint-disable-next-line eqeqeq
      if (keynum == 189 || keynum == 190 || keynum == 110 || keynum == 109) {
        this.$message.warning(this.$t("el.common.NoDecimalsnegativeNumbers"));
        e.target.value = "";
      }
    },
    // 页数重置 请求数据
    reGetData() {
      this.params.offset = 0;
      this.params.page = 1;
      this.getData();
    },
    headerStyle({ row, rowIndex }) {
      return "bskHeader";
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "bskCell";
    },
    // 获取列表数据
    async getData() {
      console.log(this.form);
      if (this.form.schoolCode !== null) {
        this.loading = true;
        let paramsData = {
          ...this.params,
          ...this.form,
        };
        delete paramsData["page"];
        console.log(paramsData);
        this.$store
          .dispatch("getClassroomList", paramsData)
          .then(({ data }) => {
            console.log(data);
            let records = data.records;
            records.forEach((item) => {
              this.$set(item, "enableStatus", Boolean(item.enable));
            });
            this.tableData = records;
            this.total = data.total;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    // 重置
    async reset(type) {
      for (let item in this.form) {
        if (item !== "schoolCode") {
          this.form[item] = "";
        }
      }
      this.params.page = 1;
      if (type) {
        // await this.optionInit();
      }
      await this.getData();
    },
    // 新增教室按钮
    async add() {
      this.$nextTick(() => {
        if (this.$refs["ruleForm"]) {
          this.$refs["ruleForm"].resetFields();
        }
        // this.ruleForm.schoolCode = this.defaultSchoolCode;
      });
      this.addOrEditId = "";
      this.editClassroomVisible = true;
    },
    // 上课管理
    handleOperateClasscourse(item) {
      this.$router.push({
        path: "/classroomSetting/schedule",
        query: {
          classroomId: item.id,
        },
      });
    },
    // 编辑教室
    async handleEditClassroom(item) {
      this.editClassroomVisible = true;
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
        this.addOrEditId = item.id;
        // 渲染数据
        for (let key in item) {
          for (let ele in this.ruleForm) {
            if (key === ele) {
              this.ruleForm[ele] = item[key];
            }
          }
        }
      });
    },
    // 删除教室
    handleDeleteClassroom(item) {
      if (~~item.enable === 1) {
        this.$alert(
          "当前教室处于启用状态，无法进行删除，请禁用此教室后，再进行删除操作",
          this.$t("el.common.prompt"),
          {
            confirmButtonText: "确定",
            type: "error",
            callback: (action) => {},
          }
        );
      } else if (~~item.enable === 0) {
        this.$confirm(
          "此操作不可撤销，确认要删除这间教室吗？",
          this.$t("el.common.prompt"),
          {
            confirmButtonText: this.$t("el.common.confirm"),
            cancelButtonText: this.$t("el.common.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            this.$store.dispatch("deleteClassroom", item.id).then((res) => {
              if (res.success) {
                this.$message({
                  type: "success",
                  message: this.$t("el.classManage.deleteSuccess") + "！",
                });
                this.reset();
              } else {
                this.$message({
                  type: "error",
                  message: res.message,
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: this.$t("el.courseClassManage.cancelRemove"),
            });
          });
      }
    },
    // 新增教室 确定
    addClass() {
      console.log(this.ruleForm);
      this.$store.dispatch("addClassroom", this.ruleForm).then((res) => {
        if (res.success) {
          this.$message.success(this.$t("el.classManage.addSuccess") + "！");
          this.editClassroomVisible = false;
          this.reset();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    // 修改教室 确定
    editClass() {
      let form = {
        data: this.ruleForm,
        id: this.addOrEditId,
      };
      this.$store.dispatch("updateClassroom", form).then((res) => {
        if (res.success) {
          this.$message.success(this.$t("el.classManage.editSuccess") + "！");
          this.editClassroomVisible = false;
          this.reset();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    // 修改教室状态
    changeStatus(enable, id, index) {
      console.log(enable, id, index);
      let data = {
        id,
        enable: Number(enable),
      };
      this.$store
        .dispatch("updateClassStatus", data)
        .then((res) => {
          console.log(res);
          if (res.success) {
            this.$message.success(this.$t("el.classManage.editSuccess") + "！");
            console.log(this.tableData[index]);
            this.$set(this.tableData[index], "enable", Number(enable));
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
            this.$set(this.tableData[index], "enableStatus", !enable);
            console.log(this.tableData);
          }
        })
        .catch(() => {
          this.tableData.forEach((item) => {
            if (item.id === id) {
              this.$set(item, "enableStatus", !enable);
            }
          });
        });
    },
    /**
     * 新增/编辑教室
     * 确认
     * 通过是否有id判断是新增还是编辑
     */
    sureClassroom() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.addOrEditId === "") {
            this.addClass();
          } else {
            this.editClass();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**
     * 选项初始化
     * 获取所有的学校列表下拉框
     */
    async optionInit() {
      await this.$store.dispatch("getManageSchoolList").then((res) => {
        this.schoolOptions = res.data;
        if (this.schoolOptions.length > 0) {
          let schoolCode;
          this.schoolOptions.forEach((item) => {
            if (item.defaultSchool === 1) {
              schoolCode = item.schoolCode;
            }
          });
          if (schoolCode === undefined) {
            this.form.schoolCode = this.schoolOptions[0].schoolCode;
          } else {
            this.form.schoolCode = schoolCode;
          }
        } else {
          this.form.schoolCode = null;
        }
        this.defaultSchoolCode = this.form.schoolCode;
      });
    },
    // 新增编辑弹窗中不允许输入空格
    handleNameInput(v) {
      this.ruleForm.name = v.replace(/\s+/g, "");
    },
  },
};
</script>

<style lang="less" scoped>
.resourceManage {
  background: #fff;
  padding: 18px 0 46px 0;
  border-radius: 4px;

  .f-l {
    margin-bottom: 3px;
  }
  &-tool {
    // height: 32px;
    line-height: 32px;
    margin: 20px 50px 0 51px;
    &-col {
      padding-bottom: 20px;
    }
    button {
      font-size: 12px;
      // font-family: "MicrosoftYaHei";
      color: rgba(249, 249, 249, 1);
      height: 32px;
      display: flex;
      background: @themeBlue;
      border-radius: 5px;
      float: left;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      cursor: pointer;
      outline: none;
    }
    .btn-reset,
    .btn-query,
    .btn-add {
      width: 65px;
    }
    .btn-query {
      margin-left: 0;
    }
    .btn-reset {
      background-color: #fff;
      color: #000;
      border: 1px solid #dcdfe6;
    }
    &-title {
      // min-width: 120px;
      font-size: 14px;
      // font-family: "MicrosoftYaHei";
      color: rgba(70, 70, 70, 1);
    }
    &-btnicon {
      width: 14px;
      height: 14px;
      margin-right: 3px;
    }
  }
  &-table {
    padding: 0 50px 0 51px;
    /deep/.bskHeader {
      background-color: #f5f6f7 !important;
      color: #323232;
      text-align: center !important;
      font-size: 14px;
      // font-family: "MicrosoftYaHei";
      font-weight: normal;
      height: 50px;
      padding: 0;
      .cell {
        white-space: pre-line;
      }
    }
    /deep/.bskCell {
      text-align: center !important;
      color: #707276;
      font-size: 14px;
      height: 46px;
      padding: 0;
      .cell {
        line-height: 20px;
        overflow: visible;
        white-space: pre-wrap;
        word-wrap: break-word;
        display: block;
        div {
          width: 100%;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          white-space: nowrap;
          display: block;
        }
        .el-switch {
          height: 22px !important;
        }
      }
    }
    /deep/.el-button--text {
      border: 0;
      color: @themeBlue;
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
      background: #f1f6ff;
    }
  }

  &-page {
    padding: 39px 50px 0 51px;
    text-align: center;
    /deep/ .el-pager li {
      height: 30px;
      line-height: 30px;
      width: 30px;
      min-width: 30px;
      border-radius: 50%;
      padding: 0 0px;
      margin: 0 5px;
      font-size: 13px;
      // font-family: "SourceHanSansCN-Medium", "SourceHanSansCN";
      font-weight: 400;
      color: rgba(40, 40, 40, 1);
    }
    /deep/ .el-pager li.active {
      background: @themeBlue;
      color: #fff;
      font-size: 13px;
      // font-family: "SourceHanSansCN-Medium", "SourceHanSansCN";
    }
    /deep/.el-pagination__total {
      font-size: 12px;
      // font-family: "MicrosoftYaHei";
      color: rgba(40, 40, 40, 1);
    }
    /deep/.el-pagination button {
      height: 30px;
      line-height: 30px;
    }
    /deep/ .el-pagination button:hover {
      color: @themeBlue;
    }
  }
  /deep/ .el-table th.gutter {
    display: table-cell !important;
  }
  /deep/ .el-table__body .bskCell:nth-child(4) .cell {
    padding: 0;
  }
  .resource-col {
    padding: 0 10px;
    &:first-child {
      border-bottom: 1px solid #ebeef5;
    }
  }
  .resource-name {
    display: inline-block;
    width: 130px;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    vertical-align: middle;
    padding-right: 12px;
  }
}
/deep/ .el-button.is-disabled.el-button--text {
  color: #ccc;
}
.resourceManage-page {
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}
/deep/ .el-dialog__body {
  padding-bottom: 0;
}
/deep/ .el-dialog {
  min-width: 600px;
}
.classroom {
  margin-top: -60px;
  .classroomtitle {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 36px;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .classroominfo {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
    line-height: 14px;
    text-align: left;
    margin: 15px 0 21px 0;
  }
  /deep/ .el-form-item__content {
    text-align: left;
  }
  .uplineinfo {
    margin: 15px 0 0px 0;
    display: flex;
    .uplineinfo-icon {
      height: 18px;
      font-size: 18px;
    }
    .uplineinfo-con {
      padding: 0 10px 0 10px;
      text-align: left;
      color: #606266;
      line-height: 22px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
    }
  }
}
/deep/ .el-input__count {
  right: 18px;
  background: transparent;
}
.classroom {
  text-align: center;
  .demo-ruleForm {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    /deep/ .el-form-item {
      margin-bottom: 22px;
      width: fit-content;
    }
  }
}
</style>
