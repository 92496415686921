<template>
  <!-- 课程管理 -->
  <div v-loading="allLoading" class="container-wrap">
    <xm-table
      ref="xmTable"
      :fatch-method="fatchMethod"
      :form-items="formItems"
      :columns="columns"
      :formOptions="{
        hiddenDefaultFormBtn: true,
      }"
      :autoLoad="false"
      :tableOptions="{
        height: tableHeight,
      }"
      @reset-form="resetFormCallback"
    >
      <template slot="formBtn">
        <el-button
          type="primary"
          size="small"
          class="teachManage-tool-btnicon"
          @click="query()"
          >{{ $t("el.common.screening") }}</el-button
        >
        <el-button
          size="small"
          class="teachManage-tool-btnicon"
          @click="resetForm()"
          >{{ $t("el.common.reset") }}</el-button
        >
      </template>
      <template slot="courseName" slot-scope="scope">
        <!-- 超出悬浮，不超出不悬浮 -->
        <tooltip-over
          :content="scope.row.courseName"
          class="class-menu-name"
          :singleAble="false"
        ></tooltip-over>
        <!-- <div class="course-name">
            {{ scope.row.courseName }}
          </div> -->
      </template>
      <template slot="otherHandle" slot-scope="scope">
        <el-button type="text" size="small" @click="goClass(scope.row)">{{
          $t("el.planCourse.classManagement")
        }}</el-button>
      </template>
    </xm-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import tooltipOver from "@/components/biz-components/tooltipOver/index.vue";
import {
  getCourseCategory,
  课程可用时段列表,
} from "@/api/manage/select-course.js";
import { mapState } from "vuex";
import { timeToSeconds, secondsToTime } from "@/utils";
export default {
  name: "classManage",
  components: {
    tooltipOver,
  },
  // 定义数据
  data() {
    return {
      tableHeight: undefined,
      schoolList: [],
      gradeList: [],
      termList: [],
      subjectList: [],
      courseTypeList: [],
      可用时段Options: [],
      allLoading: false,
    };
  },
  mounted() {
    this.init();
  },
  // 计算属性
  computed: {
    ...mapGetters(["attendSchoolList"]),
    /**
     * 定义列
     */
    columns() {
      return [
        // 课程名称
        {
          label: this.$t("el.planCourse.CourseName"),
          // prop: "courseName",
          slotName: "courseName",
          showtooltip: "false", // 传字符串‘false’时候不显示默认的
          fixed: "left",
          width: "160px",
        },
        // 课程状态
        {
          label: this.$t("el.planCourse.courseStateLabel"),
          prop: "onOffStatus",
          formatter: ({ onOffStatus, status }) =>
            status === 0 && onOffStatus === 0 ? "正常" : "停用",
        },
        {
          label: "可用时段",
          prop: "availableTimePeriodsVO",
          width: "180px",
          formatter: (row, col, val) => {
            return (
              val
                ?.map(
                  (item) =>
                    `${item.name} (${secondsToTime(
                      item.startTime
                    )} - ${secondsToTime(item.endTime)}) `
                )
                .join("、") || "-"
            );
          },
        },
        // 所属机构
        {
          label: this.$t("el.planCourse.affiliation"),
          prop: "organizationName",
          width: "180px",
        },
        // 课程来源
        {
          label: this.$t("el.courseList.courseSource"),
          prop: "sourceCategoryName",
        },
        // 来源名称
        {
          label: this.$t("el.courseList.courseSourceName"),
          prop: "originTenantName",
          width: "180px",
        },
        {
          label: this.$t("el.courseList.coursePrice"),
          prop: "coursePrice",
          width: "100px",
        },
        // {
        //   label: this.$t("el.planCourse.school"),
        //   prop: "schoolName",
        // },
        // 年级
        {
          label: this.$t("el.courseList.grade"),
          prop: "gradeName",
        },
        // 科目
        {
          label: this.$t("el.courseList.subject"),
          prop: "subjectName",
          width: "100px",
        },
        // 学期
        {
          label: this.$t("el.courseList.semester"),
          prop: "termName",
          width: "100px",
        },
        // 课程类别
        {
          label: this.$t("el.courseList.courseType"),
          prop: "categoryName",
        },
        // 教材版本
        {
          label: this.$t("el.planCourse.teachingMaterialVersion"),
          prop: "versionName",
          width: "100px",
        },
        {
          label: this.$t("el.planCourse.classNumber"),
          prop: "classCount",
        },
        {
          label: this.$t("el.planCourse.curriculumPlaces"),
          prop: "courseQuota",
          formatter(row, column, value) {
            return value || "-";
          },
        },
        // 已报名人数
        {
          label: this.$t("el.planCourse.registrationNumber"),
          prop: "reportedCount",
          width: "120px",
        },
        // {
        //   label: '是否计分',
        //   prop: 'enable',
        //   formatter(row, column, value) {
        //     return (
        //       {
        //         0: '否',
        //         1: '是'
        //       }[value] || ''
        //     );
        //   }
        // },
        {
          label: this.$t("el.common.operate"),
          width: this.$t("el.planCourse.w100_160"),
          slotName: "otherHandle",
          fixed: "right",
        },
      ];
    },
    // 定义查询表单
    formItems() {
      return [
        // 课程名称
        {
          itemType: "input",
          prop: "courseName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.CourseName"),
          selectDefault: true,
          clearable: true,
          itemStyle: "width:150px",
        },
        // 所属机构
        {
          itemType: "input",
          prop: "organizationName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.affiliation"),
          selectDefault: true,
          clearable: true,
          itemStyle: "width:150px",
        },
        // {
        //   itemType: "select",
        //   prop: "schoolCode",
        //   size: "small",
        //   placeholder: this.$t("el.common.pleaseSelect"),
        //   label: this.$t("el.planCourse.school"),
        //   selectDefault: true,
        //   itemStyle: "width:310px;",
        //   defaultParam: "defaultSchool",
        //   options: this.schoolList,
        // },
        // 年级
        {
          itemType: "select",
          prop: "gradeId",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.schoolResourceManage.grade"),
          selectDefault: false,
          clearable: true,
          itemStyle: "width:150px;",
          options: this.gradeList,
        },
        // 科目
        {
          itemType: "select",
          prop: "subjectId",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.schoolResourceManage.subjects"),
          selectDefault: false,
          clearable: true,
          itemStyle: "width:150px;",
          options: this.subjectList,
        },
        // 学期
        {
          itemType: "select",
          prop: "termId",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.courseList.semester"),
          selectDefault: false,
          clearable: true,
          itemStyle: "width:150px;",
          options: this.termOptions,
        },
        // 课程来源
        {
          itemType: "select",
          prop: "sourceId",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.courseSource"),
          selectDefault: false,
          clearable: true,
          itemStyle: "width:150px;",
          options: [
            { label: "机构", value: 1 },
            { label: "区域", value: 2 },
          ],
        },
        // 课程类别
        {
          itemType: "select",
          prop: "categoryId",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.courseCategory"),
          selectDefault: false,
          clearable: true,
          itemStyle: "width:150px;",
          options: this.courseTypeList,
        },
        {
          itemType: "select",
          prop: "availableTimePeriodsId",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: "可用时段",
          selectDefault: false,
          clearable: true,
          itemStyle: "width:150px",
          options: this.可用时段Options,
        },

        // 开关
        {
          itemType: "select",
          prop: "onOffStatus",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.courseStateLabel"),
          selectDefault: false,
          clearable: true,
          itemStyle: "width:150px",
          options: [
            { label: "正常", value: 0 },
            { label: "停用", value: 1 },
          ],
        },
      ];
    },
    ...mapState({
      // 学期下拉数据
      termOptions(state) {
        return state.dict.courseTermId.map((item) => ({
          label: item.dictionaryText,
          value: item.dictionaryKey,
        }));
      },
      saasDictData: (state) => state.dict.saasDictData,
    }),
  },
  methods: {
    async init() {
      if (Array.isArray(this.saasDictData)) {
        this.selectList = JSON.parse(JSON.stringify(this.saasDictData));
        this.saasDictData.forEach((item) => {
          if (item.code === "gradeId") {
            // 年级选项
            this.gradeList = item.children.map(({ id, cnName }) => ({
              value: id,
              label: cnName,
            }));
          } else if (item.code === "subjectId") {
            // 科目选项
            this.subjectList = item.children.map(({ id, cnName }) => ({
              value: id,
              label: cnName,
            }));
          } else if (item.code === "termId") {
            this.termList = item.children.map(({ id, cnName }) => ({
              value: id,
              label: cnName,
            }));
          }
        });
      }
      getCourseCategory().then((res) => {
        if (res.status === 200) {
          this.courseTypeList = res.data.map((item) => ({
            label: item.cnName,
            value: item.id,
          }));
        }
      });
      课程可用时段列表().then((res) => {
        if (res.status === 200) {
          this.可用时段Options = res.data.map((item) => ({
            label: `${item.name} (${secondsToTime(
              item.startTime
            )} - ${secondsToTime(item.endTime)}) `,
            value: item.id,
          }));
        }
      });

      this.$refs.xmTable.setFormValue("onOffStatus", 0);
      this.query();
    },
    /**
     * 筛选
     */
    query() {
      if (this.$refs["xmTable"]) {
        this.$refs["xmTable"].query();
      }
    },

    /**
     * 重置
     */
    resetForm() {
      this.$refs["xmTable"].resetForm();
    },

    resetFormCallback(queryParams) {
      // console.log('queryParams',queryParams);
      this.setDefaultSchool(queryParams, this.schoolList);
    },

    //
    setDefaultSchool(queryParams, schoolList) {
      let hasDefault = false;
      schoolList.forEach((element) => {
        if (element.defaultSchool) {
          this.$set(queryParams, "schoolCode", element.schoolCode);
          // queryParams.schoolCode = element.schoolCode;
          hasDefault = true;
        }
      });
      if (!hasDefault) {
        if (schoolList && schoolList.length > 0) {
          this.$set(queryParams, "schoolCode", schoolList[0].schoolCode);
        }
        // queryParams.schoolCode = schoolList[0].schoolCode;
      }
    },

    // 获取学校列表
    async getAuthSchoolList() {
      await this.$store.dispatch("getSchoolList");

      this.schoolList = this.attendSchoolList;
      return this.attendSchoolList;
    },

    // // 获取年级列表
    // async getGradeList() {
    //   await this.$store.dispatch("getGradeList");
    //   this.gradeList = this.allGradeList;
    //   return this.allGradeList;
    // },
    // // 获取科目列表
    // async getSubjectList() {
    //   await this.$store.dispatch("getSubjectList");
    //   this.subjectList = this.allSubjectList;
    //   return this.allSubjectList;
    // },

    /**
     * 获取列表数据的请求方法
     */
    fatchMethod(params) {
      return this.$store
        .dispatch("getCourseList", this.handparams(params))
        .then((result) => {
          //   console.log("result",result);
          return result;
        });
    },
    // 点击跳转到班级管理
    goClass(row) {
      this.$router.push({
        path: "/planCourseManage/courseClassManage",
        query: {
          courseId: row.courseId,
          schoolCode: row.schoolCode,
          courseName: row.courseName,
          schoolName: row.schoolName,
          tenantInfoId: row.tenantInfoId,
          tenantType: row.tenantType,
          tenantId: row.tenantId,
          originTenantId: row.originTenantId,
        },
      });
    },
    /**
     * 窗口变化, tableHeight: 固定表头
     */
    onWindowResize() {
      var height = document.documentElement.clientHeight;
      height -= 380;
      if (height < 280) height = 280;
      this.tableHeight = height + "px";
    },
    // 参数处理
    handparams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      // console.log("data===" + JSON.stringify(params))
      return params;
    },
  },
};
</script>

<style lang="less" scoped>
.container-wrap {
  background: #ffffff;
  padding: 30px 50px;
  border-radius: 10px;

  /deep/ .el-button--small,
  .el-button--small.is-round {
    padding: 9px 15px;
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
}
/deep/ .el-dialog {
  margin-top: 24 px !important;
  margin-bottom: 24 px !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: left;
}
.xm-table .bskCell .cell .class-menu-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  /*! autoprefixer: off */
}
// .xm-table .bskCell .cell .class-menu-name{
//   white-space: normal;
// }
</style>
