<template>
  <div v-loading="allLoading" class="preview-contain">
    <h3 v-if="showTitle">{{ $t("el.planCourse.schedulePreview") }}</h3>
    <div style="position: relative; margin-top: 20px">
      <div class="select-content flex flex-m">
        <el-select
          size="small"
          v-model="courseId"
          :placeholder="$t('el.planCourse.selectCourse')"
          :popper-append-to-body="false"
          popper-class="select-popper"
        >
          <el-option
            v-for="item in previewCourseList"
            :key="item.id"
            :label="item.courseName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          class="ml20"
          size="small"
          v-model="classId"
          :popper-append-to-body="false"
          popper-class="select-popper"
          :placeholder="$t('el.courseClassManage.classRules')"
        >
          <el-option
            v-for="item in previewClassList"
            :key="item.id"
            :label="item.className"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          class="ml20"
          size="small"
          v-model="staffCode"
          :popper-append-to-body="false"
          popper-class="select-popper"
          :placeholder="$t('el.planCourse.selectTeacher')"
        >
          <el-option
            v-for="item in previewTeacherList"
            :key="item.staffCode"
            :label="item.staffName"
            :value="item.staffCode"
          >
          </el-option>
        </el-select>
      </div>
      <el-tabs
        style="padding-top: 52px"
        v-model="activeName"
        @tab-click="tableTabClick"
      >
        <el-tab-pane :label="$t('el.planCourse.totalSchedule')" name="1">
          <school-timetabel
            :tableList="tableList"
            class="total-timetable"
          ></school-timetabel>
        </el-tab-pane>
        <el-tab-pane :label="$t('el.planCourse.TheClassSchedule')" name="2">
          <school-timetabel :tableList="tableClassList" class="total-timetable">
            <!-- 自定义 item 项 -->
            <!-- <template slot="customSlot" slot-scope="scope">
            <div>北京快到北京快到社保卡解放军好看的</div>
          </template> -->
          </school-timetabel>
        </el-tab-pane>
        <el-tab-pane :label="$t('el.planCourse.TheTeacherSchedule')" name="3">
          <school-timetabel
            :tableList="tableTeacherList"
            class="total-timetable"
          >
          </school-timetabel>
        </el-tab-pane>
        <el-tab-pane :label="$t('el.planCourse.TheClassroomSchedule')" name="4">
          <school-timetabel
            :tableList="tableClassRoomList"
            class="total-timetable"
          >
          </school-timetabel>
        </el-tab-pane>
      </el-tabs>
      <div v-if="isPreview && !emptyData" class="bottom-content">
        <div v-if="hasConflicted" class="flex flex-m bottom-content-label">
          <el-image class="icon-clash" :src="iconClash"></el-image>
          <span class="ml10">{{
            $t("el.infrastructure.MarkedRegenerate")
          }}</span>
        </div>
        <div class="mt20">
          <el-button @click="updatePreview" size="small">{{
            $t("el.planCourse.changeSetting")
          }}</el-button>
          <el-button @click="completePreview" size="small" type="primary">{{
            $t("el.planCourse.complete")
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import schoolTimetabel from "../biz-components/school-timetable/index.vue";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  name: "schedulePreview",
  data() {
    return {
      activeName: "1",
      iconClash: require("../../assets/images/icon/icon-course-clash.png"),
      allLoading: true,
      staffCode: "",
      classId: "",
      courseId: "",
      planId: "",
      courseTableType: "1",
      tableList: [],
      emptyData: false,
      tableTeacherList: [],
      tableClassRoomList: [],
      hasConflicted: false,
      /**
       * 课表总体数据。单个课表 list 里 放一个数据。 showTableTitle： 是否显示表格标题，tableTitle：标题
       *
       * columns： 列数据 及其属性配置
       *
       * tableData： 行数据及数据内容   courseList： 对应 columns中的 prop 数据， item 中的 课程 课程列表
       */
      tableClassList: [
        // {
        //   showTableTitle: true,
        //   tableTitle: "的首付跨境电商发快递是",
        //   columns: [
        //     {
        //       type: "common",
        //       prop: "date",
        //       label: "djgfgjdj",
        //       fixed: "left",
        //       align: "center",
        //       ["header-align"]: "center",
        //       formatter(row, column, value, index) {
        //         return row.startTime + "~" + row.endTime;
        //       },
        //     },
        //     {
        //       prop: "courseList" + 0,
        //       week: "周一",
        //       time: "2021/11/28",
        //       slotName: "customSlot", // 自定义 item
        //       headerSlotName: "header", // 自定义表头
        //     },
        //     {
        //       prop: "courseList" + 1,
        //       week: "周一",
        //       time: "2021/11/28",
        //     },
        //     {
        //       week: "周一",
        //       time: "2021/11/28",
        //     },
        //     {
        //       week: "周一",
        //       time: "2021/11/28",
        //     },
        //     {
        //       week: "周一",
        //       time: "2021/11/28",
        //     },
        //     {
        //       week: "周一",
        //       time: "2021/11/28",
        //     },
        //   ],
        //   tableData: [
        //     {
        //       startTime: "04:30",
        //       endTime: "05:30",
        //       courseList0: [{}, {}, {}],
        //       courseList1: [{}, {}],
        //     },
        //     {
        //       startTime: "04:30",
        //       endTime: "05:30",
        //       courseList0: [{}, {}, {}],
        //       courseList1: [{}],
        //     },
        //   ],
        // },
      ],
      columns: [],
    };
  },
  computed: {
    ...mapGetters([
      "previewCourseList",
      "previewClassList",
      "previewTeacherList",
    ]),
  },
  props: {
    // planId: {
    //   type: String,
    //   default: "3",
    // },
    showTitle: {
      type: Boolean,
      default: true,
    },
    isPreview: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    schoolTimetabel,
  },
  mounted() {
    this.init();
  },
  watch: {
    courseId: {
      async handler(val, old) {
        if (val === "") {
          await this.getSelectList("all");
        } else {
          await this.getSelectList("course");
        }
        this.getTableData();
      },
      // deep: true,
    },
    classId: {
      async handler(val, old) {
        if (val === "") {
          await this.getSelectList("all");
        } else {
          await this.getSelectList("class");
        }
        this.getTableData();
      },
      // deep: true,
    },
    staffCode: {
      async handler(val, old) {
        if (val === "") {
          await this.getSelectList("all");
        } else {
          await this.getSelectList("teacher");
        }
        this.getTableData();
      },
      // deep: true,
    },
  },
  methods: {
    ...mapActions([
      "planTeachingCourse",
      "planTeachingTeacher",
      "planTeachingClass",
      "getCourseTablePreview",
      "courseTableComplete",
      "getCourseTableShow",
    ]),
    async init() {
      this.planId = this.$route.query.id || "3";
      await this.getSelectList("all");
      this.getTableData();
    },

    async getSelectList(type) {
      let params;
      if (type !== "course") {
        params = {
          planId: this.planId,
          classId: this.classId,
          staffCode: this.staffCode,
        };
        await this.planTeachingCourse(this.handparams(params));
      }
      if (type !== "teacher") {
        params = {
          planId: this.planId,
          classId: this.classId,
          courseId: this.courseId,
        };

        await this.planTeachingTeacher(this.handparams(params));
      }
      if (type !== "class") {
        params = {
          planId: this.planId,
          staffCode: this.staffCode,
          courseId: this.courseId,
        };
        await this.planTeachingClass(this.handparams(params));
      }
    },
    // 切换课表
    tableTabClick(tab) {
      console.log("tab", tab);
      this.courseTableType = tab.name;

      this.getTableData();
    },
    // 课程 切换选择
    // async onChangeCourseSearch() {
    //   let params;
    //   if (this.classId === "") {
    //     params = {
    //       planId: this.planId,
    //       staffCode: this.staffCode,
    //       courseId: this.courseId,
    //     };
    //     await this.planTeachingClass(this.handparams(params));
    //   }
    //   this.getTableData();
    // },
    // // 课程 切换选择
    // onChangeClassSearch() {
    //   this.getTableData();
    // },
    // // 课程 切换选择
    // onChangeTeacherSearch() {
    //   this.getTableData();
    // },

    // 获取预览课表
    getTableData() {
      this.allLoading = true;
      let params = {
        classId: this.classId,
        courseId: this.courseId,
        courseTableType: this.courseTableType || "1",
        planId: this.planId,
        staffCode: this.staffCode,
      };
      if (this.isPreview) {
        return this.getCourseTablePreview(this.handparams(params))
          .then((result) => {
            // console.log("result", result);
            this.allLoading = false;
            this.hasConflicted = result.data.conflicted;
            this.convertTableData(result);
          })
          .catch(() => {
            this.allLoading = false;
          });
      } else {
        return this.getCourseTableShow(this.handparams(params))
          .then((result) => {
            // console.log("result", result);
            this.allLoading = false;
            this.convertTableData(result);
          })
          .catch(() => {
            this.allLoading = false;
          });
      }
    },
    // 转换课表数据
    convertTableData(result) {
      let columns = [];
      let a = {
        type: "common",
        prop: "coursePeriod",
        label: "",
        width: 96,
        fixed: "left",
        align: "center",
        "header-align": "center",
      };
      columns.push(a);
      let dayOfWeekCodes = [];
      let timePeriods = [];
      // 获取横坐标表头数据
      result.data.x.forEach((element) => {
        let b = {
          prop: element.dayOfWeekCode,
          week: element.dayOfWeek,
          width: 128,
          time: element.courseDate,
        };
        dayOfWeekCodes.push(element.dayOfWeekCode);
        columns.push(b);
      });
      result.data.y.forEach((element) => {
        timePeriods.push(element);
      });
      if (
        result.data &&
        result.data.allTables instanceof Array &&
        result.data.allTables.length > 0
      ) {
        this.emptyData = false;
        result.data.allTables.forEach((element) => {
          element.columns = columns; // 表头数据
          // 判断是否显示标题
          if (this.courseTableType === "1") {
            element.showTableTitle = false;
          } else {
            element.showTableTitle = true;
          }
          let tableData = [];

          timePeriods.forEach((item) => {
            let hasTime = false; // 判断数据中是否有纵坐标 的值，有，赋值，无，置空
            let index = -1;
            let maxContent = 0;
            element.allContents.forEach((e, i) => {
              // 可用 find(item =>item === e.coursePeriod) 进行优化
              if (item === e.coursePeriod) {
                dayOfWeekCodes.forEach((obj) => {
                  e.periodContents.forEach((ele) => {
                    if (ele.dayOfWeekCode === obj) {
                      // console.log("ele.dayOfWeekContents", ele.dayOfWeekContents);
                      maxContent =
                        maxContent > ele.dayOfWeekContents.length
                          ? maxContent
                          : ele.dayOfWeekContents.length;
                    }
                  });
                });
              }
            });

            element.allContents.forEach((e, i) => {
              // 可用 find(item =>item === e.coursePeriod) 进行优化
              if (item === e.coursePeriod) {
                index = i;
                hasTime = true;

                dayOfWeekCodes.forEach((obj) => {
                  let a = [];
                  let hasObj = false; // 判断数据中是否有obj 的值，有，赋值，无，置空 横坐标

                  e.periodContents.forEach((ele) => {
                    if (ele.dayOfWeekCode === obj) {
                      hasObj = true;
                      a = ele.dayOfWeekContents; // dayOfWeekContents 一周中当天的数据
                      const contentLength = ele.dayOfWeekContents.length;
                      // 课程数据补齐到最大长度
                      if (maxContent > contentLength) {
                        for (let x = 0; x < maxContent - contentLength; x++) {
                          a.push({});
                        }
                      }
                    }
                  });
                  // console.log("a", a);
                  if (hasObj) {
                    e[obj] = a;
                  } else {
                    e[obj] = [];
                    // 课程数据补齐到最大长度
                    for (let x = 0; x < maxContent; x++) {
                      e[obj].push({});
                    }
                  }
                });
              }
            });

            if (!hasTime) {
              tableData.push({
                coursePeriod: item,
                periodContents: [],
              });
            } else {
              tableData.push(element.allContents[index]);
            }
          });
          console.log("tableData", tableData);
          element.tableData = tableData;
        });
      } else {
        this.emptyData = true;
        if (result.data) {
          result.data.allTables = [];
        } else {
          result.data = {};
          result.data.allTables = [];
        }
      }

      // console.log("result.data.allTables", result.data.allTables);
      switch (this.courseTableType) {
        case "1":
          // this.tableList = [];
          this.tableList = result.data.allTables;
          break;
        case "2":
          this.tableClassList = result.data.allTables;
          break;
        case "3":
          this.tableTeacherList = result.data.allTables;
          break;
        case "4":
          this.tableClassRoomList = result.data.allTables;
          break;

        default:
          this.tableList = result.data.allTables;
          break;
      }
    },

    // 课表点击完成
    completePreview() {
      this.allLoading = true;
      // this.$emit("changeStep", 3);
      this.courseTableComplete(this.planId)
        .then((result) => {
          this.allLoading = false;
          console.log("result", result);
          this.$emit("changeStep", 3);
        })
        .catch(() => {
          this.allLoading = false;
        });
    },
    // 修改设置
    updatePreview() {
      this.$emit("changeStep", 0);
    },
    // 参数处理
    handparams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      // console.log("data===" + JSON.stringify(params))
      return params;
    },
  },
};
</script>

<style lang="less" scoped>
.preview-contain {
  padding: 0 10px;
  /deep/ .el-tabs__nav-wrap::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #e4e7ed;
    z-index: 1;
  }
  /deep/ .el-tabs__item.is-active {
    width: fit-content;
    padding: 0 12px;
    height: 28px;
    background: #f7f7fc;
    border-radius: 14px;
    border: 1px solid #6049ff !important;
    color: #6049ff;
    line-height: 28px;
    text-align: center;
    margin-right: 20px;
  }
  /deep/ .el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0 0 20px;
    height: 32px;
    line-height: 32px;
    // width: 388px;
  }
  /deep/ .el-tabs__item {
    width: fit-content;
    padding: 0 12px;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 28px;
    display: inline-block;
    list-style: none;
    font-size: 14px;

    position: relative;
    margin-right: 20px;

    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    line-height: 20px;
  }
  /deep/ .el-tabs__nav-scroll {
    width: 100%;
  }
  /deep/ .el-button--small,
  .el-button--small.is-round {
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #6049ff;
    color: #6049ff;
    font-size: 13px;
  }
  /deep/ .el-button--primary {
    color: #fff;
    background: #6049ff;
    -webkit-box-shadow: 0px 5px 15px 0px rgb(172 178 193 / 36%);
    box-shadow: 0px 5px 15px 0px rgb(172 178 193 / 36%);
  }
  .select-content {
    position: absolute;
    top: 0;
    left: 0;
    height: 32px;
    /deep/ .el-select {
      display: inline-block;
      position: relative;
      width: 180px;
    }
    /deep/.select-popper {
      .el-select-dropdown__item.selected {
        font-weight: normal;
        color: #6049ff;
        font-weight: 700;
      }
    }
  }
  .total-timetable {
    // height: 420px;
    overflow-y: auto;
  }
  .bottom-content {
    text-align: center;
    padding-top: 20px;
    &-label {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;

      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}
.icon-clash {
  width: 12px;
  height: 12px;
}
.ml20 {
  margin-left: 20px;
}
.ml10 {
  margin-left: 10px;
}
.mt20 {
  margin-top: 20px;
}
</style>
<style lang="less">
.el-scrollbar.el-scrollbar_x {
  .el-scrollbar__wrap {
    overflow-x: visible !important;
    margin-bottom: 0px !important;
  }
}
</style>
