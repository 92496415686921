<template>
  <!-- 已弃用 -->
  <div class="plan-course-details">
    <router-menu-title :routerTitleList="routerTitleList"></router-menu-title>
    <el-steps finish-status="success" :active="activeStep" align-center>
      <el-step v-for="(item, index) in stepData" :key="item" :title="item">
        <template v-if="index === activeStep" slot="icon">
          <img class="plan-course-details-active-img" :src="activeImg" alt="" />
        </template>
        <template v-else slot="icon">
          <span class="plan-course-details-inactive-block"></span>
        </template>
      </el-step>
    </el-steps>
    <div class="plan-course-details-content">
      <component
        :ref="activeName"
        @changeFirst="changeFirst"
        @changeStep="changeStep"
        :is="activeName"
      ></component>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import routerMenuTitle from "@/components/biz-components/router-menu-title/index";
import basicSetting from "@/components/use-components/basic-setting";
import teachingPlan from "@/components/use-components/teaching-plan";
import schedulePreview from "@/components/use-components/schedule-preview";
import planFinish from "@/components/use-components/plan-finish";
import AppVue from "../../../App.vue";

export default {
  name: "planCourseDetails",
  components: {
    routerMenuTitle,
    basicSetting,
    teachingPlan,
    schedulePreview,
    planFinish,
  },
  data() {
    return {
      activeStep: 0,

      activeImg: require("@/assets/images/stepActive.png"),
    };
  },
  beforeRouteLeave(to, from, next) {
    // 如果离开此页面 还原步骤条
    this.$store.commit("SET_PLANDETAIL_STEP", 0);
    if (this.activeStep === 0) {
      this.$refs.basicSetting.autoSave(
        this.$t("el.common.saveSuccessfully"),
        this.$t("el.common.saveFailed")
      );
    }
    if (this.activeStep === 1) {
      this.$refs.teachingPlan.autoSave(
        this.$t("el.common.saveSuccessfully"),
        this.$t("el.common.saveFailed")
      );
    }
    next();
  },
  computed: {
    ...mapGetters(["planStep"]),
    routerTitleList() {
      return [
        {
          name: this.$t("el.planCourse.classSchedule"),
        },
        {
          name: this.$t(this.$route.meta.title),
        },
      ];
    },
    stepData() {
      return [
        this.$t("el.planCourse.infrastructure"),
        this.$t("el.planCourse.teachingPlan"),
        this.$t("el.planCourse.schedulePreview"),
        this.$t("el.planCourse.complete"),
      ];
    },
    activeName() {
      let data = [
        "basicSetting",
        "teachingPlan",
        "schedulePreview",
        "planFinish",
      ];
      return data[this.activeStep];
    },
  },
  created() {
    this.activeStep = Number(this.planStep);
  },
  methods: {
    // 按钮改变步骤
    changeStep(index) {
      this.activeStep = index;
      this.$store.commit("SET_PLANDETAIL_STEP", index);
    },
    // 基本设置点击下一步，传递学校id和最大的每周节数
    changeFirst(data) {
      this.$store.commit("SET_GET_SCHOOLCODE_COUNT", JSON.stringify(data));
    },
  },
};
</script>

<style lang="less" scoped>
.plan-course-details {
  background: #fff;
  padding: 20px;
  // width: 1053px;
  box-sizing: border-box;
  /deep/ .el-steps {
    padding: 30px 45px;
    .el-step__icon {
      width: 26px;
      height: 26px;
    }
    .el-step__head.is-process {
      height: 26px;
      .el-step__icon.is-text {
        color: #fff;
        border: none;
      }
    }
    .el-step__head.is-wait {
      height: 26px;
      .el-step__icon.is-text {
        width: 8px;
        height: 8px;
        margin-top: 2px;
        background: #8c8c8c;
        border: none;
        vertical-align: middle;
      }
    }
    .el-step__head.is-success {
      color: #fff;
      border-color: #5f4afe;
      .el-step__icon.is-text {
        background: #5f4afe;
      }
    }
    .el-step__title.is-wait {
      color: rgba(#000, 0.45);
    }
    .el-step__head.is-finish {
      color: #6049ff;
      border-color: #6049ff;
      .el-step__line {
        background: #6049ff;
      }
    }
    .el-step__title.is-process,
    .el-step__title.is-success {
      color: #5f4afe;
      font-weight: bold;
    }
    .el-step__title.is-finish {
      color: #ccc;
    }
    .el-step__line {
      background-color: #8c8c8c;
    }
    .el-step__title {
      font-size: 14px;
    }
  }
  &-content {
    padding: 0 0 32px;
  }
  &-active-img {
    width: 100%;
    height: 100%;
  }
  &-inactive-block {
    background: #8c8c8c;
  }
}
</style>
