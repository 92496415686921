/**
 * 排课管理-基础设置
 */
import axios from "@/plugins/axios.js";

// 获取排课详情
export function getPlanDetail(id) {
  return axios.get("/planBase/detail/" + id);
}

// 获取课程列表
export function getPlanCourseList(params) {
  return axios.get("/courseManage/myCourseSelector", { params });
}

// 获取老师列表
export function getPlanTeacherList(params) {
  return axios.post("/planBase/getTecher", params);
}

// 保存排课信息
export function savePlan(data) {
  return axios.post("/planBase/save", data);
}
// 获取教室列表
export function getClassroomList(params) {
  return axios.get("/classroom/list", {
    params,
  });
}

// 点击下一步时候获取最新的课程数据
export function deleteCourse(params) {
  return axios.get(
    "/planBase/deleteCourse/" + params.schoolCode + "/" + params.planId
  );
}
