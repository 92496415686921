<template>
  <!-- 查看课表 已弃用  -->
  <div v-loading="allLoading" class="container-content-table">
    <router-menu-title :routerTitleList="routerTitleList"></router-menu-title>
    <schedule-preview
      ref="schedule"
      class="schedule-preview"
      :showTitle="false"
      :isPreview="false"
    ></schedule-preview>
    <el-button
      class="btn-import"
      size="small"
      @click="exportPlanTable"
      v-loading="exportLoading"
      :disabled="exportLoading"
      >{{ $t("el.common.export") }}</el-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import routerMenuTitle from "../../../components/biz-components/router-menu-title/index.vue";
import schedulePreview from "../../../components/use-components/schedule-preview.vue";
import { exportPlanTable } from "@/api/manage/course-list";
import moment from "moment";
export default {
  // 定义数据
  data() {
    return {
      allLoading: false,
      exportLoading: false,
    };
  },
  async created() {
    this.init();
  },
  components: {
    routerMenuTitle,
    schedulePreview,
  },
  // 计算属性
  computed: {
    ...mapGetters(["attendSchoolList"]),
    routerTitleList() {
      return [
        {
          name: this.$t("el.planCourse.classSchedule"),
        },
        {
          name: this.$t(this.$route.meta.title),
        },
      ];
    },
  },
  methods: {
    async init() {},

    // 导出课表
    exportPlanTable() {
      this.exportLoading = true;
      let params = {
        classId: this.$refs.schedule.classId,
        courseId: this.$refs.schedule.courseId,
        courseTableType: this.$refs.schedule.courseTableType || "1",
        planId: this.$refs.schedule.planId,
        staffCode: this.$refs.schedule.staffCode,
      };
      exportPlanTable(this.handparams(params)).then((res) => {
        let url = window.URL.createObjectURL(res);
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        let date = moment().format("YYYY-MM-DD");
        link.setAttribute(
          "download",
          date + this.$t("el.common.Exportschedule") + ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.exportLoading = false;
      });
    },
    /**
     * 窗口变化, tableHeight: 固定表头
     */
    onWindowResize() {
      var height = document.documentElement.clientHeight;
      height -= 380;
      if (height < 280) height = 280;
      this.tableHeight = height + "px";
    },
    // 参数处理
    handparams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      // console.log("data===" + JSON.stringify(params))
      return params;
    },
  },
};
</script>

<style lang="less" scoped>
.container-content-table {
  background: #ffffff;
  padding: 20px 20px;
  border-radius: 10px;
  // width: 1053px;
  box-sizing: border-box;
  position: relative;
  .schedule-preview {
    margin-top: 30px;
  }

  .btn-import {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  /deep/ .el-button--small,
  .el-button--small.is-round {
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #6049ff;
    color: #6049ff;
    font-size: 13px;
  }
  /deep/ .el-button--primary {
    color: #fff;
    background: #6049ff;
    -webkit-box-shadow: 0px 5px 15px 0px rgb(172 178 193 / 36%);
    box-shadow: 0px 5px 15px 0px rgb(172 178 193 / 36%);
  }
}
</style>
