<template>
  <!-- 资源使用分析-->
  <div class="resourceManage" :class="{ EnClass: isEn }">
    <div class="resourceManage-tool">
      <div class="resourceManage-tool-item">
        <div class="resourceManage-tool-item-title">
          {{ $t("el.schoolResourceManage.teacherName")
          }}{{ $t("el.symbol.colon") }}
        </div>
        <el-input
          class="resourceManage-tool-item-option"
          v-model="form.staffName"
          :placeholder="$t('el.common.pleaseEnter')"
          size="small"
        ></el-input>
      </div>
      <!-- <div class="resourceManage-tool-item">
        <div class="resourceManage-tool-item-title">
          {{ $t("el.schoolResourceManage.workNumber")
          }}{{ $t("el.symbol.colon") }}
        </div>
        <el-input
          class="resourceManage-tool-item-option"
          v-model="form.staffCode"
          :placeholder="$t('el.common.pleaseEnter')"
          size="small"
        ></el-input>
      </div> -->
      <!-- <div class="resourceManage-tool-item">
        <div class="resourceManage-tool-item-title">
          {{ $t("el.schoolResourceManage.school") }}{{ $t("el.symbol.colon") }}
        </div>
        <el-input
          class="resourceManage-tool-item-option"
          v-model="form.schoolName"
          :placeholder="$t('el.common.pleaseEnter')"
          size="small"
        ></el-input>
      </div> -->
      <div class="resourceManage-tool-item">
        <div class="resourceManage-tool-item-title">
          {{ $t("el.schoolResourceManage.resourceName")
          }}{{ $t("el.symbol.colon") }}
        </div>
        <el-input
          class="resourceManage-tool-item-option"
          v-model="form.resourceName"
          :placeholder="$t('el.common.pleaseEnter')"
          size="small"
        ></el-input>
      </div>
      <div class="resourceManage-tool-item">
        <div class="resourceManage-tool-item-title">
          {{ $t("el.schoolResourceManage.subjects")
          }}{{ $t("el.symbol.colon") }}
        </div>
        <el-select
          class="resourceManage-tool-item-option"
          v-model="form.subjectId"
          :placeholder="$t('el.common.pleaseSelect')"
          size="small"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in subjectOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <div class="resourceManage-tool-item">
        <div class="resourceManage-tool-item-title">
          {{ $t("el.schoolResourceManage.grade") }}{{ $t("el.symbol.colon") }}
        </div>
        <el-select
          class="resourceManage-tool-item-option"
          v-model="form.gradeId"
          :placeholder="$t('el.common.pleaseSelect')"
          size="small"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in gradeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>

      <div class="resourceManage-tool-item">
        <div class="resourceManage-tool-item-title">
          {{ $t("el.planCourse.CourseName") }}{{ $t("el.symbol.colon") }}
          <el-input
            class="resourceManage-tool-item-option"
            v-model="form.courseName"
            :placeholder="$t('el.common.pleaseEnter')"
            size="small"
          ></el-input>
        </div>
      </div>
      <div class="resourceManage-tool-item">
        <div class="resourceManage-tool-item-title">
          {{ $t("el.schoolResourceManage.chapter") }}{{ $t("el.symbol.colon") }}
        </div>
        <el-input
          class="resourceManage-tool-item-option"
          v-model="form.knowledgeName"
          :placeholder="$t('el.common.pleaseEnter')"
          size="small"
        ></el-input>
      </div>

      <div class="selectFooter">
        <div class="resourceManage-tool-item">
          <button class="btn-query" @click="getData">
            {{ $t("el.common.search") }}
          </button>
          <button class="btn-reset" @click="reset">
            {{ $t("el.common.reset") }}
          </button>
        </div>
        <button class="btn-query" @click="exporttable">
          {{ $t("el.schoolResourceManage.derivedForm") }}
        </button>
      </div>
    </div>
    <!-- 表格 -->
    <div class="resourceManage-table">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        :header-cell-class-name="headerStyle"
        :cell-class-name="cellStyle"
        style="width: 100%"
      >
        <template slot="empty">
          <div style="padding-top: 111px; line-height: 0">
            <img
              src="@/assets/images/pic_empty_people@2x.png"
              alt=""
              style="width: 270px"
            />
          </div>
          <div
            style="
              color: rgba(100, 108, 149, 0.25);
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 1px;
              padding-bottom: 120px;
            "
          >
            {{ $t("el.common.noData") }}
          </div>
        </template>
        <el-table-column
          prop="staffName"
          :label="$t('el.schoolResourceManage.teacherName')"
          width="100px"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="courseName"
          :label="$t('el.planCourse.CourseName')"
          :show-overflow-tooltip="true"
          min-width="100px"
        >
        </el-table-column>
        <el-table-column
          prop="knowledgeName"
          :show-overflow-tooltip="true"
          min-width="110px"
          :label="$t('el.schoolResourceManage.sectionName')"
        >
        </el-table-column>
        <el-table-column
          prop="resourceName"
          min-width="140px"
          :label="$t('el.schoolResourceManage.resourceName')"
        >
          <span slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.resourceName"
              placement="top"
            >
              <span>{{ scope.row.resourceName }}</span>
            </el-tooltip>
          </span>
        </el-table-column>
        <el-table-column
          prop="resourceType"
          :label="$t('el.common.type')"
          width="78px"
          :show-overflow-tooltip="true"
        >
          <span slot-scope="scope">
            {{ changedata(scope.row.resourceType) }}
          </span>
        </el-table-column>
        <el-table-column
          prop="likeCount"
          :label="$t('el.dataAnalysis.thumbUpNumber')"
          width="78px"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="downloadPersonTime"
          width="78px"
          :label="$t('el.dataAnalysis.downloadsNumber')"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="downloadCount"
          width="78px"
          :label="$t('el.dataAnalysis.downloads')"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="teachCount"
          width="78px"
          :label="$t('el.dataAnalysis.classesNumber')"
          :show-overflow-tooltip="true"
        >
          <span slot-scope="scope">
            {{ scope.row.resourceType == "JA" ? "--" : scope.row.teachCount }}
          </span>
        </el-table-column>
        <el-table-column
          prop="deriveCount"
          width="78px"
          :label="$t('el.dataAnalysis.citations')"
          :show-overflow-tooltip="true"
        >
          <span slot-scope="scope">
            {{ scope.row.resourceType == "JA" ? "--" : scope.row.deriveCount }}
          </span>
        </el-table-column>
        <el-table-column
          fixed="right"
          prop="onlineOperateTime"
          :label="$t('el.common.operate')"
          :show-overflow-tooltip="true"
          width="160px"
        >
          <span style="vertical-align: middle" slot-scope="scope">
            <el-button type="text" @click="handleLook(scope.row)">{{
              $t("el.common.view")
            }}</el-button>
            <el-button type="text" @click="exportFile(scope.row)">{{
              $t("el.common.download")
            }}</el-button>
          </span>
        </el-table-column>
      </el-table>
    </div>
    <!--分页  -->
    <div class="resourceManage-page" v-show="tableData.length > 0">
      <el-pagination
        layout="prev, pager, next"
        :current-page.sync="params.page"
        :page-size="params.limit"
        :total="total"
      >
      </el-pagination>
      <span class="el-pagination__total"
        >{{ $t("el.pagination.totalname") }}{{ Math.ceil(total / params.limit)
        }}{{ $t("el.pagination.pages") }} {{ total
        }}{{ $t("el.pagination.items") }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getToken } from "@/core/auth";
import moment from "moment";
import {
  queryCollectiveList,
  updateOnlineState,
  platformShare,
} from "@/api/home/resourcemanage";
import { queryAuditRecordsList } from "@/api/home/auditmanage";
import {
  statisticsPage,
  statisticsExport,
  addDownloadLikeRecord,
  getVersion,
} from "@/api/manage/compared-analyze";
import ManageOption from "../components/manageOption.vue";
import axios from "axios";
import gradeAndSubject from "@/mixin/gradeAndSubject.js";

export default {
  name: "rescourceAnalyze",
  // 注册组件
  components: {
    // ManageOption,
  },
  mixins: [gradeAndSubject],
  // 定义数据
  data() {
    return {
      loading: false, // 列表加载状态
      tableData: [],
      form: {
        // 查询条件
        staffName: "",
        // staffCode: "",
        // schoolName: "",
        subjectId: "",
        gradeId: "",
        courseName: "",
        knowledgeName: "",
        resourceName: "",
      },
      params: {
        // 分页
        limit: 10,
        offset: 0,
        page: 1,
      },
      total: 0,
      dialogManage: false,
      gradeOptions: [], // 年级选项
      subjectOptions: [], // 科目选项
      isEn: false,
    };
  },
  // 计算属性
  computed: {
    ...mapGetters(["eduAuth"]),
  },
  // 侦听器
  watch: {
    // 监听页数
    "params.page"(newVal, oldVal) {
      // 改变参数
      this.params.offset = this.params.limit * (this.params.page - 1);
      this.getData();
    },
    "$i18n.locale": {
      immediate: true,
      handler(val, old) {
        this.isEn = val === "en_US";
      },
    },
  },
  // 创建完成钩子
  created() {
    this.getData();
    this.optionInit();
  },
  // 挂在完成钩子
  mounted() {},
  // 方法定义
  methods: {
    /**
     * 类型转化
     */
    changedata(data) {
      if (data === "KJ") {
        return this.$t("el.classroomWps.courseware");
      }
      if (data === "JA") {
        return this.$t("el.classroomWps.lessonPlan");
      }
      if (data === "ZY") {
        return this.$t("el.classroomWps.homework");
      }
      if (data === "BKSP") {
        return this.$t("el.classroomWps.lessonVideo");
      }
      if (data === "KQYX") {
        return this.$t("el.classroomWps.preview");
      } else {
        return "--";
      }
    },
    /**
     * 导出表格
     */
    exporttable() {
      let time = moment().format("MMDD");
      let paramsdata = {
        ...this.form,
      };
      axios({
        method: "post",
        url: process.env.VUE_APP_BASE_API + "/resource/statistics/export",
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        responseType: "blob",
        data: paramsdata,
      }).then((res) => {
        if (!res) {
          return;
        }
        // var filename = submitForm.beginTime + '至' + submitForm.endTime + '油卡充值记录';
        let url = window.URL.createObjectURL(res.data);
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute(
          "download",
          this.$t("el.router.ResourceUsageAnalysis") + "_" + time + ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
      });
    },
    headerStyle({ row, rowIndex }) {
      return "bskHeader";
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "bskCell";
    },
    getData() {
      this.loading = true;
      let paramsData = {
        ...this.params,
        ...this.form,
      };
      delete paramsData["page"];
      statisticsPage(paramsData)
        .then(({ data }) => {
          this.tableData = data.records;
          this.total = data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    reset() {
      for (let item in this.form) {
        this.form[item] = "";
      }
      this.form.isOnline = null;
      this.params.page = 1;
      this.getData();
    },
    /**
     * 查看资源
     * @param {*} row
     */
    handleLook(row) {
      // let officePreviewUrl = this.$options.filters["officePreviewUrl"];
      // window.open(
      //   officePreviewUrl(row.resourceUrl, this.getFormat(row.resourceUrl), false, true)
      // );
      let type = 2;
      if (
        ["doc", "docx"].indexOf(
          row.resourceUrl.substring(row.resourceUrl.lastIndexOf(".") + 1)
        ) !== -1
      ) {
        type = 1;
      } else {
        type = 2;
      }

      getVersion({ resourceId: row.resourceId }).then((res) => {
        let version = res.data;
        let format = row.resourceUrl.substring(
          row.resourceUrl.lastIndexOf(".") + 1
        );
        this.getwpsInit(
          row.resourceId,
          format,
          version,
          type,
          row.courseId,
          row.resourceType
        );
      });
    },
    /**
     * 跳转到查看WPS页面
     */
    getwpsInit(id, format, version, types, courseId, resourceType) {
      this.$utils.openWindow(
        "lookWps?id=" +
          id +
          "&format=" +
          format +
          "&version=" +
          version +
          "&type=" +
          types +
          "&courseId=" +
          courseId +
          "&resourceType=" +
          resourceType
      );
    },
    /**
     *下载资源
     * @param {*} row
     */
    exportFile(row) {
      console.log("re", row.resourceUrl);
      let resourceName = row.resourceName;
      let queryParams = {
        objectName: row.resourceUrl,
        displayName: resourceName,
        isDownload: 1,
      };
      addDownloadLikeRecord(row.resourceId, 3).then(() => {
        this.$utils.download("/obs/info/download", queryParams, {
          method: "get",
        });
      });
    },

    /**
     * 获取文件格式
     * @param {*} url
     */
    getFormat(url) {
      let formatIndex = url.lastIndexOf(".");
      let format = "";
      if (formatIndex >= 0) {
        format = url.substring(formatIndex + 1);
      }
      return format;
    },
    /**
     * 选项初始化
     */
    optionInit() {
      this.handleGetGradeAndSubject(); // mixin获取年级与科目
    },
  },
};
</script>

<style lang="less" scoped>
.selectFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.resourceManage {
  background: #fff;
  padding: 18px 0 46px 0;
  border-radius: 4px;
  &-tool {
    // height: 32px;
    line-height: 32px;
    margin: 20px 50px 0 51px;
    &-col {
      padding-bottom: 20px;
    }
    button {
      font-size: 12px;
      // font-family: "MicrosoftYaHei";
      color: rgba(249, 249, 249, 1);
      height: 32px;
      display: flex;
      background: @themeBlue;
      border-radius: 5px;
      float: left;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      cursor: pointer;
      outline: none;
    }
    .btn-reset,
    .btn-query {
      min-width: 65px;
      padding-left: 12px;
      padding-right: 12px;
    }
    .btn-query {
      margin-left: 0;
    }
    .btn-reset {
      background-color: #fff;
      color: #000;
      border: 1px solid #dcdfe6;
    }
    &-title {
      min-width: 120px;
      font-size: 14px;
      // font-family: "MicrosoftYaHei";
      color: rgba(70, 70, 70, 1);
    }
    &-item-title {
      font-size: 14px;
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #606266;
    }
    &-item-option {
      width: 180px;
    }
    &-btnicon {
      width: 14px;
      height: 14px;
      margin-right: 3px;
    }
  }
  &-table {
    padding: 0 50px 0 51px;
    /deep/.bskHeader {
      background-color: #f5f6f7 !important;
      color: #323232;
      text-align: center !important;
      font-size: 14px;
      // font-family: "MicrosoftYaHei";
      font-weight: normal;
      height: 50px;
      padding: 0;
      .cell {
        white-space: pre-line;
      }
    }
    /deep/.bskCell {
      text-align: center !important;
      color: #707276;
      font-size: 14px;
      height: 46px;
      padding: 0;
      .cell {
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        white-space: nowrap;
        display: block;
        div {
          width: 100%;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          white-space: nowrap;
          display: block;
        }
      }
    }
    /deep/.el-button--text {
      border: 0;
      color: @themeBlue;
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
      background: #f1f6ff;
    }
  }

  &-page {
    padding: 39px 50px 0 51px;
    text-align: center;
    /deep/ .el-pager li {
      height: 30px;
      line-height: 30px;
      width: 30px;
      min-width: 30px;
      border-radius: 50%;
      padding: 0 0px;
      margin: 0 5px;
      font-size: 13px;
      // font-family: "SourceHanSansCN-Medium", "SourceHanSansCN";
      font-weight: 400;
      color: rgba(40, 40, 40, 1);
    }
    /deep/ .el-pager li.active {
      background: @themeBlue;
      color: #fff;
      font-size: 13px;
      // font-family: "SourceHanSansCN-Medium", "SourceHanSansCN";
    }
    /deep/.el-pagination__total {
      font-size: 12px;
      // font-family: "MicrosoftYaHei";
      color: rgba(40, 40, 40, 1);
    }
    /deep/.el-pagination button {
      height: 30px;
      line-height: 30px;
    }
    /deep/ .el-pagination button:hover {
      color: @themeBlue;
    }
  }
  /deep/ .el-table th.gutter {
    display: table-cell !important;
  }
  /deep/ .el-table__body .bskCell:nth-child(4) .cell {
    padding: 0;
  }
  .resource-col {
    padding: 0 10px;
    &:first-child {
      border-bottom: 1px solid #ebeef5;
    }
  }
  .resource-name {
    display: inline-block;
    width: 130px;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    vertical-align: middle;
    padding-right: 12px;
  }
}
/deep/ .el-button.is-disabled.el-button--text {
  color: #ccc;
}
.resourceManage-page {
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}
.offline {
  margin-top: -40px;
  .offlinetitle {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 36px;
    text-align: left;
  }
  .offlineinfo {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
    line-height: 14px;
    text-align: left;
    margin: 15px 0 21px 0;
  }
}
</style>
