<template>
  <div class="basic-setting" v-loading="allLoading">
    <h3>{{ $t("el.planCourse.infrastructure") }}</h3>
    <el-form
      ref="basicForm"
      :model="basicForm"
      :rules="basicFormRules"
      label-width="120px"
    >
      <el-form-item
        :label="$t('el.planCourse.courseName') + $t('el.common.colon')"
        prop="planName"
      >
        <el-input
          size="small"
          v-model="basicForm.planName"
          :maxlength="50"
          :placeholder="$t('el.planCourse.enterScheduleName')"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item
        :label="$t('el.planCourse.school') + $t('el.common.colon')"
        prop="schoolCode"
      >
        <el-select
          size="small"
          style="width: 320px"
          v-model="basicForm.schoolCode"
          :placeholder="$t('el.planCourse.selectSchool')"
          @change="changeSchoolCode"
        >
          <el-option
            v-for="(item, i) in schoolList"
            :value="item.value"
            :label="item.label"
            :key="i"
          />
        </el-select>
      </el-form-item> -->
      <el-form-item
        :label="$t('el.planCourse.arrangingDate') + $t('el.common.colon')"
        prop="date"
      >
        <el-date-picker
          size="small"
          :clearable="false"
          v-model="basicForm.date"
          type="daterange"
          :range-separator="$t('el.common.to')"
          :start-placeholder="$t('el.common.startDate')"
          :end-placeholder="$t('el.common.endDate')"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          format="yyyy/MM/dd"
          @change="changeDate"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        :label="$t('el.planCourse.circulationRules')"
        prop="loopRule"
      >
        <el-radio-group v-model="basicForm.loopRule" @change="loopRuleChange">
          <el-radio :label="1">{{
            $t("el.planCourse.oneWeekIterations")
          }}</el-radio>
          <el-radio :label="2">{{
            $t("el.planCourse.twoWeekIterations")
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        :label="$t('el.planCourse.numberWeek')"
        prop="courseDays"
        label-width="120px"
      >
        <el-checkbox-group
          v-model="basicForm.courseDays"
          v-if="basicForm.loopRule === 1"
        >
          <el-checkbox
            :label="item.value"
            v-for="item in singleWeek"
            :key="item.value"
            :disabled="canChooseDays.indexOf(item.value) == -1"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
        <el-checkbox-group
          v-model="basicForm.courseDays"
          v-if="basicForm.loopRule === 2"
        >
          <el-checkbox
            :label="item.value"
            v-for="item in doubleWeek"
            :key="item.value"
            :disabled="canChooseDays.indexOf(item.value) == -1"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item :label="$t('el.planCourse.classTime')" prop="coursePeriods">
        <el-tag
          v-for="(item, index) in basicForm.coursePeriods"
          :key="index"
          closable
          effect="plain"
          type="info"
          :disable-transitions="true"
          @close="handleClose(item)"
          class="time-tag"
          >{{ item }}</el-tag
        >
        <br
          v-if="basicForm.coursePeriods && basicForm.coursePeriods.length > 0"
        />
        <el-button type="text" @click="getTimeList" class="choose-button">
          {{ $t("el.planCourse.choose") }}
        </el-button>
      </el-form-item>
      <el-form-item :label="$t('el.planCourse.rowCourse')" prop="toPlanCourses">
        <div>
          <el-button
            type="text"
            icon="el-icon-plus"
            @click="addLine"
            class="choose-button"
            >{{ $t("el.planCourse.add") }}</el-button
          >
          <w-form
            @editData="editData"
            @saveData="saveData"
            @deleteData="deleteData"
            :tableVisible="tableVisible"
            :paramsForm="paramsForm"
            :courseOptions="courseOptions"
            :classOptions="classOptions"
            :schoolCode="basicForm.schoolCode"
            :canSave="canSave"
          ></w-form>
        </div>
      </el-form-item>
    </el-form>
    <!-- <el-dialog
      title="选择时段"
      :visible.sync="chooseTimeVisible"
      width="600px"
      class="add-time-dialog"
    >
      <el-form label-width="60px" label-position="left">
        <el-form-item label="学校：">
          <el-select disabled v-model="basicForm.schoolCode">
            <el-option
              v-for="(item, i) in schoolList"
              :value="item.value"
              :label="item.label"
              :key="i"
            />
          </el-select>
        </el-form-item>
        4644564646465
        <el-form-item>
          jksahjkfsadhkfsdhajk
          <el-checkbox-group v-model="chooseTimes" size="mini">
            <el-checkbox-button
              class="time-button"
              v-for="item in periodList"
              :key="item.id"
              :label="
                item.startTime.substring(0, 5) +
                  '~' +
                  item.endTime.substring(0, 5)
              "
              >{{
                item.startTime.substring(0, 5) +
                  "~" +
                  item.endTime.substring(0, 5)
              }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="text"
          class="add-block"
          @click="addPeriodVisible = true"
          >添 加</el-button
        >
        <el-button
          @click="
            chooseTimeVisible = false;
            chooseTimes = [];
          "
          >{{ $t("el.common.cancel") }}</el-button
        >
        <el-button type="primary" @click="confirmTime">{{ $t("el.common.confirm") }}</el-button>
      </span>
    </el-dialog> -->
    <el-dialog
      :title="$t('el.planCourse.chooseTime')"
      :visible.sync="chooseTimeVisible"
      width="600px"
      class="add-time-dialog"
    >
      <el-form label-width="60px" label-position="left">
        <!-- <el-form-item
          :label="$t('el.planCourse.school') + $t('el.common.colon')"
        >
          {{ schoolName }}
        </el-form-item> -->
        <el-form-item v-loading="periodLoading">
          <el-checkbox-group
            v-model="chooseTimes"
            size="mini"
            @change="changePeriodCheck"
          >
            <el-checkbox-button
              class="time-button"
              v-for="item in periodList"
              :key="item.id"
              :label="
                item.startTime.substring(0, 5) +
                '~' +
                item.endTime.substring(0, 5)
              "
              >{{
                item.startTime.substring(0, 5) +
                "~" +
                item.endTime.substring(0, 5)
              }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="text"
          class="add-block"
          @click="handelAddPeriodVisible"
          >{{ $t("el.planCourse.add") }}</el-button
        >
        <el-button @click="chooseTimeVisible = false">{{
          $t("el.common.cancel")
        }}</el-button>
        <el-button type="primary" @click="confirmTime">{{
          $t("el.common.confirm")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('el.planCourse.newPeriodTime')"
      :visible.sync="addPeriodVisible"
      width="600px"
      class="add-time-dialog"
    >
      <el-form
        label-width="120px"
        ref="addPeriodgForm"
        :model="addPeriodgForm"
        :rules="rules"
      >
        <!-- <el-form-item
          :label="$t('el.planCourse.school') + $t('el.common.colon')"
        >
          {{ schoolName }}
        </el-form-item> -->
        <el-form-item
          :label="$t('el.planCourse.periodTime') + $t('el.common.colon')"
          prop="date"
        >
          <el-time-picker
            v-model="addPeriodgForm.date"
            size="small"
            is-range
            arrow-control
            style="width: 320px"
            :range-separator="$t('el.common.to')"
            :start-placeholder="$t('el.common.startTime')"
            :end-placeholder="$t('el.common.endTime')"
            :placeholder="$t('el.planCourse.selectTimeRange')"
            value-format="HH:mm:00"
            format="HH:mm"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="addPeriodVisible = false"
          v-loading="addPeriodLoading"
          >{{ $t("el.common.cancel") }}</el-button
        >
        <el-button
          type="primary"
          @click="confirmAddPeriod"
          v-loading="addPeriodLoading"
          >{{ $t("el.common.confirm") }}</el-button
        >
      </span>
    </el-dialog>
    <div class="basic-setting-option">
      <el-button type="primary" @click="nextStep" v-loading="saveLoading">{{
        $t("el.common.nextStep")
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import {
  getPlanDetail,
  getPlanTeacherList,
  savePlan,
  deleteCourse,
} from "@/api/manage/basic-setting";
import WForm from "./table-form/w-form";
import AppVue from "../../App.vue";
export default {
  name: "basicSetting",
  components: {
    WForm,
  },
  data() {
    return {
      planId: this.$route.query.id,
      canChooseDays: [],
      chooseTimeVisible: false,
      addPeriodVisible: false,
      periodLoading: false,
      addPeriodLoading: false,
      saveLoading: false,
      allLoading: false,
      canSave: false,
      timer: null, // 定时器
      message: "",
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      chooseTimes: [],
      basicForm: {
        loopRule: 1,
        // schoolCode: "",
        date: [],
        courseDays: [],
        coursePeriods: [],
        // date:['2021-12-10','2021-12-11']
      },
      schoolCode: "",
      schoolList: [], // 学校列表
      periodList: [], // 时段列表
      addPeriodgForm: {
        date: "",
      },
      schoolName: "",
      paramsForm: {
        params: [],
        paramsRules: {
          courseId: [
            {
              required: true,
              message: this.$t("el.planCourse.selectCourse"),
              trigger: "change",
            },
          ],
        },
      },
      courseOptions: [],
      classOptions: [],
      teacherOptions: [],
      classroomOptions: [],
      tableVisible: true,
      // basicFormRules:{},
    };
  },
  watch: {
    "$i18n.locale": {
      handler(val, old) {
        this.paramsForm.paramsRules.courseId[0].message = this.$t(
          "el.planCourse.selectCourse"
        );
        // this.$set(this.paramsForm, "paramsRules", this.paramsForm.paramsRules);
        console.log("paramsForm", this.paramsForm);
      },
      immediate: true,
      deep: true,
    },
    "basicForm.schoolCode": {
      handler(newVal) {
        this.schoolCode = newVal;
      },
      deep: true,
      immediate: true,
    },
    "paramsForm.params": {
      handler(newVal) {
        this.basicForm.toPlanCourses = [...newVal];
        console.log(this.basicForm.toPlanCourses, "watch");
      },
    },
    "addPeriodgForm.date": {
      handler(val, old) {
        console.log(val, old);
        if (val !== null && val !== undefined) {
          if (val.length === 2) {
            if (val[0] === undefined) {
              this.$set(this.addPeriodgForm, "date", [
                moment().format("HH:mm:00"),
                moment(val[1]).format("HH:mm:00"),
              ]);
            } else if (val[1] === undefined) {
              this.$set(this.addPeriodgForm, "date", [
                moment(val[0]).format("HH:mm:00"),
                moment().format("HH:mm:00"),
              ]);
            }
          }
        }
      },
      deep: true,
    },
    basicForm: {
      handler(newVal) {
        this.canSave = true;
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    this.planId = this.$route.query.id; // 获取计划ID
    await this.getAuthSchoolList();
    await this.getPlanDetail();
  },
  computed: {
    ...mapGetters(["attendSchoolList"]),
    rules() {
      return {
        date: [
          {
            required: true,
            message: this.$t("el.planCourse.pleaseSelectTimePeriod"),
            trigger: "change",
          },
        ],
      };
    },
    basicFormRules() {
      return {
        planName: [
          {
            required: true,
            message: this.$t("el.planCourse.enterScheduleName"),
          },
        ],
        // schoolCode: [
        //   { required: true, message: this.$t("el.planCourse.selectSchool") },
        // ],
        date: [
          {
            required: true,
            message: this.$t("el.planCourse.SelectSchedulePeriod"),
          },
          {
            trigger: "change",
            validator: this.checkDate,
          },
        ],
        loopRule: [
          {
            required: true,
            message: this.$t("el.planCourse.SelectcirculationRules"),
          },
        ],
        courseDays: [
          {
            required: true,
            message: this.$t("el.planCourse.pleaseSelectWeekDay"),
          },
        ],
        coursePeriods: [
          { required: true, message: this.$t("el.planCourse.selectTimeClass") },
        ],
        toPlanCourses: [
          {
            required: true,
            message: this.$t("el.planCourse.pleaseSelectRowCourse"),
          },
        ],
      };
    },
    singleWeek() {
      return [
        { value: "w1", label: this.$t("el.common.Monday") },
        { value: "w2", label: this.$t("el.common.Tuesday") },
        { value: "w3", label: this.$t("el.common.Wednesday") },
        { value: "w4", label: this.$t("el.common.Thursday") },
        { value: "w5", label: this.$t("el.common.Friday") },
        { value: "w6", label: this.$t("el.common.Saturday") },
        { value: "w7", label: this.$t("el.common.Sunday") },
      ];
    },
    doubleWeek() {
      return [
        { value: "w1", label: this.$t("el.common.Monday") },
        { value: "w2", label: this.$t("el.common.Tuesday") },
        { value: "w3", label: this.$t("el.common.Wednesday") },
        { value: "w4", label: this.$t("el.common.Thursday") },
        { value: "w5", label: this.$t("el.common.Friday") },
        { value: "w6", label: this.$t("el.common.Saturday") },
        { value: "w7", label: this.$t("el.common.week7") },
        { value: "w8", label: this.$t("el.common.week8") },
        { value: "w9", label: this.$t("el.common.week9") },
        { value: "w10", label: this.$t("el.common.week10") },
        { value: "w11", label: this.$t("el.common.week11") },
        { value: "w12", label: this.$t("el.common.week12") },
        { value: "w13", label: this.$t("el.common.week13") },
        { value: "w14", label: this.$t("el.common.week14") },
      ];
    },
  },
  mounted() {
    this.timer = setInterval(this.autoSave, 30000); // 执行定时器
  },
  destroyed() {
    clearInterval(this.timer); // 销毁定时器
  },
  methods: {
    // 获取详情
    async getPlanDetail() {
      this.allLoading = true;
      if (this.planId) {
        await getPlanDetail(this.planId).then((res) => {
          this.schoolName = res.data.schoolName;
          this.basicForm = res.data;
          this.paramsForm.params = res.data.toPlanCourses;
          if (!this.basicForm.planStartDate) {
            this.initDay();
          } else {
            this.$set(this.basicForm, "date", [
              this.basicForm.planStartDate,
              this.basicForm.planEndDate,
            ]);
          }
          if (
            this.basicForm.courseDays &&
            this.basicForm.courseDays.length > 0
          ) {
            this.basicForm.courseDays = this.basicForm.courseDays.split(",");
          } else {
            this.basicForm.courseDays = [];
          }
          if (
            !this.basicForm.coursePeriods ||
            this.basicForm.coursePeriods.length === 0
          ) {
            this.basicForm.coursePeriods = [];
          } else {
            this.basicForm.coursePeriods =
              this.basicForm.coursePeriods.split(",");
          }
          this.$nextTick(() => {
            // 清除表单校验信息
            this.$refs["basicForm"] && this.$refs["basicForm"].clearValidate();
            this.$refs["basicForm"] &&
              this.$refs["basicForm"].validateField("date");
            if (!this.basicForm.planStartDate) {
              this.canSave = true;
            } else {
              this.canSave = false;
            }
            this.getCanChooseDays();
          });
          this.allLoading = false;
        });
      }
    },
    // 自动保存
    autoSave(saveSuccessfully, saveFailed) {
      this.basicForm.saveType = 0;
      // this.basicForm.toPlanCourses=[...this.paramsForm.params];
      this.basicForm.planId = Number(this.planId);
      if (this.basicForm.date) {
        this.basicForm.planStartDate = this.basicForm.date[0];
        this.basicForm.planEndDate = this.basicForm.date[1];
      }
      let autoSaveForm = { ...this.basicForm };
      autoSaveForm.courseDays = autoSaveForm.courseDays.toString();
      autoSaveForm.coursePeriods = autoSaveForm.coursePeriods.toString();
      if (autoSaveForm.toPlanCourses && autoSaveForm.toPlanCourses.length > 0) {
        autoSaveForm.toPlanCourses.forEach((item, index) => {
          if (item.courseId === null) {
            autoSaveForm.toPlanCourses.splice(index, 1);
          }
        });
      }
      if (this.canSave) {
        // this.message="正在自动保存...";
        const messageItem = this.$message({
          message: this.$t("el.common.AutoSaving"),
          duration: 0,
        });
        savePlan(autoSaveForm)
          .then((res) => {
            // setTimeout(() => {
            messageItem.message =
              saveSuccessfully || this.$t("el.common.saveSuccessfully");
            // }, 1000);
            setTimeout(() => {
              messageItem.close();
            }, 1000);
          })
          .catch((e) => {
            messageItem.type = "error";
            messageItem.message = saveFailed || this.$t("el.common.saveFailed");
            setTimeout(() => {
              messageItem.close();
            }, 1000);
          });
        this.canSave = false;
      } else {
        console.log(this.canSave, "nosave");
      }
    },
    // 点击下一步时校验表单并且直接到下一步
    nextStep() {
      this.basicForm.planId = Number(this.planId);
      if (this.paramsForm.params.length > 0) {
        this.paramsForm.params.forEach((item, index) => {
          if (item.courseId === null) {
            this.paramsForm.params.splice(index, 1);
          }
        });
      }
      this.basicForm.toPlanCourses = [...this.paramsForm.params];
      this.$refs.basicForm.validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          if (this.basicForm.date) {
            this.basicForm.planStartDate = this.basicForm.date[0];
            this.basicForm.planEndDate = this.basicForm.date[1];
          }
          let nextForm = { ...this.basicForm };
          nextForm.saveType = 1;
          nextForm.courseDays = nextForm.courseDays.toString();
          nextForm.coursePeriods = nextForm.coursePeriods.toString();
          if (nextForm.toPlanCourses.length > 0) {
            nextForm.toPlanCourses.forEach((item, index) => {
              if (item.courseId === null) {
                nextForm.toPlanCourses.splice(index, 1);
              }
            });
          }
          savePlan(nextForm)
            .then((res) => {
              let planParams = {
                planId: this.planId,
                // schoolCode: this.schoolCode,
              };
              deleteCourse(planParams)
                .then((res) => {
                  let params = {
                    // schoolCode: this.basicForm.schoolCode,
                    courseCount:
                      this.basicForm.courseDays.length *
                      this.basicForm.coursePeriods.length,
                  };
                  this.$notify.closeAll();
                  this.$emit("changeFirst", params); // 提交schoolCode和每周最大节数
                  this.$emit("changeStep", 1); // 下一步
                  this.saveLoading = false;
                })
                .catch((e) => {
                  this.saveLoading = false;
                });
            })

            .catch((e) => {
              this.saveLoading = false;
            });
        }
      });
    },
    // 改变选中时间段时候的校验
    changePeriodCheck(value) {
      let timeArr = [...value];
      let time = timeArr[timeArr.length - 1];
      // console.log("time")
      timeArr.every((item) => {
        if (item !== time) {
          let itemArr = item.split("~");
          let nowTimeArr = time.split("~");
          if (nowTimeArr[0] >= itemArr[1] || nowTimeArr[1] <= itemArr[0]) {
            return true;
          } else {
            this.$message.error(
              this.$t("el.infrastructure.CoincidesTimePperiod")
            );
            this.chooseTimes.splice(this.chooseTimes.indexOf(time), 1);
            return false;
          }
        }
      });
    },
    // 校验排课日期
    checkDate(rule, value, callback) {
      // moment().format("YYYY-MM-DD")
      let isBefore = moment(value[0]).isBefore(moment().format("YYYY-MM-DD"));
      if (isBefore) {
        callback(new Error(this.$t("el.infrastructure.ReselectCourseDate")));
      } else {
        callback();
      }
      // return !isBefore;
    },
    // 更改循环规则的时候删除上课天数
    loopRuleChange(newVal) {
      this.basicForm.courseDays = [];
      this.getCanChooseDays();
      this.$nextTick(() => {
        this.$refs["basicForm"] &&
          this.$refs["basicForm"].clearValidate(["courseDays"]);
      });
    },
    // 更改派克日期的时候删除上课天数
    changeDate(newVal) {
      this.basicForm.courseDays = [];
      this.getCanChooseDays();
      this.$nextTick(() => {
        this.$refs["basicForm"] &&
          this.$refs["basicForm"].clearValidate(["courseDays"]);
      });
    },
    // 获取日期后根据所选日期中的星期将每周上课天数disabled
    getCanChooseDays() {
      let startDay = this.basicForm.date[0]; // 选中的第一天日期
      let startWeekday =
        moment(startDay).day() === 0 ? 7 : moment(startDay).day(); // 开始的日期是周几
      let endDay = this.basicForm.date[1]; // 选中的末尾一天
      let totalDays = moment(endDay).diff(moment(startDay), "days") + 1; // 一共几天
      this.canChooseDays = []; // 置空可选星期
      if (this.basicForm.loopRule === 1) {
        // 单周循环
        if (totalDays < 7) {
          for (let i = 0; i < totalDays; i++) {
            this.canChooseDays.push("w" + startWeekday);
            startWeekday++;
            if (startWeekday > 7) {
              startWeekday = startWeekday - 7;
            }
          }
        } else {
          this.canChooseDays = ["w1", "w2", "w3", "w4", "w5", "w6", "w7"];
        }
      } else {
        if (totalDays < 14) {
          for (let i = 0; i < totalDays; i++) {
            this.canChooseDays.push("w" + startWeekday);
            startWeekday++;
            if (startWeekday > 14) {
              startWeekday = startWeekday - 14;
            }
          }
        } else {
          this.canChooseDays = [
            "w1",
            "w2",
            "w3",
            "w4",
            "w5",
            "w6",
            "w7",
            "w8",
            "w9",
            "w10",
            "w11",
            "w12",
            "w13",
            "w14",
          ];
        }
      }
    },
    // 更改选择的学校时
    changeSchoolCode(newVal) {
      this.paramsForm.params = [];
      this.schoolCode = newVal;
      this.schoolList.forEach((item) => {
        if (item.schoolCode === newVal) {
          this.schoolName = item.schoolName;
        }
      });
      this.basicForm.coursePeriods = []; // 清空已选时间段
    },
    // 设置默认时间
    initDay() {
      let nextday = moment().add(1, "days").format("YYYY-MM-DD"); // 第二天的日期
      let monthday = moment()
        .add(1, "days")
        .add(1, "months")
        .format("YYYY-MM-DD"); // 当前日期的30天后
      // this.basicForm.date = [nextday, monthday]; 直接赋值会引起不可更改
      this.$set(this.basicForm, "date", [nextday, monthday]); // set赋值之后才可更改
    },
    // 设置默认学校
    setDefaultSchool(queryParams, schoolList) {
      schoolList.forEach((element) => {
        if (element.defaultSchool) {
          this.$set(queryParams, "schoolCode", element.schoolCode);
        }
      });
      if (schoolList && schoolList.length > 0) {
        this.$set(queryParams, "schoolCode", schoolList[0].schoolCode);
      }
    },
    // 获取时段列表
    getTimeList() {
      this.periodLoading = true;
      this.chooseTimeVisible = true;
      this.chooseTimes = this.basicForm.coursePeriods;
      let data = {
        // schoolCode: this.basicForm.schoolCode,
        offset: 0,
        limit: 900,
      };
      this.$store.dispatch("getClassTimePage", data).then((result) => {
        this.periodList = result.data.records;
        this.periodLoading = false;
      });
    },
    // 确认弹窗中选中的时段
    confirmTime() {
      this.basicForm.coursePeriods = this.chooseTimes;
      this.chooseTimeVisible = false;
      this.sortBasicFormTime();
    },
    // 点击显示添加时间段并且重置表单
    handelAddPeriodVisible() {
      this.addPeriodgForm = {};
      this.addPeriodVisible = true;
      if (this.$refs["addPeriodgForm"]) {
        this.$nextTick(() => {
          this.$refs["addPeriodgForm"] &&
            this.$refs["addPeriodgForm"].clearValidate();
        });
      }
    },
    // 确认添加时段
    confirmAddPeriod() {
      this.$refs["addPeriodgForm"].validate(async (valid) => {
        if (valid) {
          this.addPeriodLoading = true;
          let params = {};
          if (this.addPeriodgForm.date) {
            params.startTime = this.addPeriodgForm.date[0];
            params.endTime = this.addPeriodgForm.date[1];
          }
          // params.schoolCode = this.basicForm.schoolCode;
          await this.addPeriod(params)
            .then((res) => {
              this.addPeriodLoading = false;
            })
            .catch(() => {
              this.addPeriodLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 新增上课时段
    addPeriod(params) {
      return this.$store
        .dispatch("addPeriod", this.handparams(params))
        .then((result) => {
          console.log("result", result);
          if (result.success) {
            this.addPeriodLoading = false;
            this.$message.success(this.$t("el.classManage.addSuccess"));
            this.addPeriodVisible = false;
            this.getTimeList();
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // 获取学校列表
    async getAuthSchoolList() {
      await this.$store.dispatch("getSchoolList");
      this.schoolList = this.attendSchoolList;
      return this.attendSchoolList;
    },
    // 删除时段标签
    handleClose(tag) {
      this.basicForm.coursePeriods.splice(
        this.basicForm.coursePeriods.indexOf(tag),
        1
      );
    },
    // 改变更新状态
    changeCanSave(val) {
      this.canSave = val;
    },
    // 点击编辑，所有的表单变为可编辑状态
    editData(index) {
      this.canSave = true;
      this.$set(this.paramsForm.params[index], "isEdit", true);
    },
    // 点击保存，表单校验，如果通过，调用接口，将数据变为只读状态
    saveData(index) {
      this.autoSave();
      this.canSave = true;
      this.$set(this.paramsForm.params[index], "isEdit", false);
    },
    // 删除一行
    deleteData(index) {
      this.$confirm(
        this.$t("el.common.deleteData"),
        this.$t("el.common.prompt"),
        {
          confirmButtonText: this.$t("el.common.delete"),
          cancelButtonText: this.$t("el.common.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.paramsForm.params.splice(index, 1);
        })
        .catch(() => {
          console.log(this.$t("el.common.cancel"));
        });
    },
    // 新增一行 避免表单校验
    addLine() {
      this.tableVisible = false;
      let newObj = {
        gradeId: null,
        gradeName: "",
        subjectId: null,
        subjectName: "",
        staffCode: null,
        classroomId: null,
        courseId: null,
        courseName: "",
        staffName: "",
        classroom: "",
        isEdit: true,
      };
      this.$nextTick(() => {
        this.paramsForm.params.unshift(newObj);
        this.tableVisible = true;
      });
    },
    // 参数处理
    handparams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      // console.log("data===" + JSON.stringify(params))
      return params;
    },
    // 排序时段字符串
    sortBasicFormTime() {
      this.basicForm.coursePeriods.sort((a, b) => {
        return a > b ? 1 : -1;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.basic-setting {
  /deep/ .el-form-item {
    margin-bottom: 18px;
  }
  .time-tag {
    margin-right: 10px;
  }
  .choose-button {
    color: #6049ff;
  }
}
.basic-setting-option {
  padding-top: 30px;
  text-align: center;
}
/deep/.el-checkbox-button__inner {
  border: 1px solid #dcdfe6;
  border-radius: 4px !important;
  margin-right: 10px;
  margin-bottom: 10px;
}
/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #6049ff;
  background: #6049ff;
}
/deep/.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #6049ff;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #6049ff;
  border-color: #6049ff;
}
/deep/.el-radio__input.is-checked + .el-radio__label {
  color: #6049ff;
}
/deep/.el-checkbox-button.is-checked .el-checkbox-button__inner {
  background-color: #6049ff;
  border-color: #6049ff;
  -webkit-box-shadow: -1px 0 0 0 #6049ff;
  box-shadow: -1px 0 0 0 #6049ff;
}
.add-time-dialog {
  /deep/.el-dialog__footer {
    padding: 10px 30px 30px;
  }
  /deep/ .el-input {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0;
    }
    .el-input__inner {
      line-height: 1px !important;
    }
  }
  h3 {
    text-align: start;
  }
  .mock-tips {
    text-align: start;
    padding-left: 130px;
    color: #f56c6c;
    font-size: 12px;
    line-height: 1;
    margin: 0;
    margin-top: -8px;
  }
  .s-tip {
    margin-bottom: 20px;
    color: #999;
    text-align: start;
  }
  .s-exchange {
    color: #6049ff;
    cursor: pointer;
  }
  .el-select {
    width: 100%;
  }
  /deep/ .el-input--suffix {
    width: 100%;
  }
  /deep/ .el-dialog__body {
    padding: 0 30px;
  }
  /deep/ .el-form-item__content {
    text-align: start;
  }
  /deep/ .el-form-item {
    margin-bottom: 15px;
  }
  /deep/ .short-margin {
    .el-form-item__label,
    .el-form-item__content {
      line-height: 30px;
    }
  }
  /deep/ .el-form-item__error {
    top: 95%;
  }
  .dialog-footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .add-block {
    margin-right: auto;
    color: #6049ff;
  }
}
.auto-save-message {
  right: 10px;
}
</style>
