<template>
  <div class="add-course-box" v-loading="loading">
    <div class="back">
      <el-button
        round
        size="small"
        style="border-radius: 16px"
        @click="cancel()"
      >
        &lt; {{ $t("el.common.back") }}</el-button
      >
      <span style="margin-left: 10px">
        {{ $t("el.planCourse.selectCourses") }} /
        <span class="gary">
          {{
            this.isEnd
              ? $t("el.planCourse.viewSelectCourseTask")
              : this.isUpdate
              ? $t("el.planCourse.updateSelectCourseTask")
              : $t("el.planCourse.addSelectCourseTask")
          }}
        </span>
      </span>
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
    >
      <el-form-item
        :label="$t('el.planCourse.selectCouresTaskName')"
        prop="taskName"
      >
        <el-input
          v-model="ruleForm.taskName"
          :disabled="isEnd"
          size="medium"
          :placeholder="$t('el.common.pleaseEnter')"
          style="width: 480px"
          maxlength="30"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('el.planCourse.academicYearSemester')"
        prop="schoolYearId"
      >
        <!-- 学年学期 -->
        <el-select
          v-model="ruleForm.schoolYearId"
          size="medium"
          :disabled="isEnd || ruleForm.isEnroll"
          :placeholder="$t('el.common.pleaseSelect')"
          style="width: 150px"
          :popper-append-to-body="false"
          popper-class="select"
          @change="getChangeCourseList"
        >
          <el-option
            v-for="item in selectedYearOption.concat(schoolYearList)"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-model="ruleForm.termId"
          :disabled="isEnd || ruleForm.isEnroll"
          size="medium"
          :placeholder="$t('el.common.pleaseSelect')"
          style="width: 150px; margin-left: 20px"
          :popper-append-to-body="false"
          popper-class="select"
          @change="getChangeCourseList"
        >
          <el-option
            v-for="item in semesterList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 选课范围 -->
      <el-form-item
        :label="$t('el.planCourse.selectCourseRange')"
        prop="gradeIds"
      >
        <el-select
          v-model="ruleForm.gradeIds"
          size="medium"
          :disabled="isEnd"
          multiple
          :placeholder="$t('el.common.pleaseSelect')"
          style="width: 320px"
          :popper-append-to-body="false"
          popper-class="select"
        >
          <el-option
            v-for="item in courseRangeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('el.planCourse.selectCourseDate')"
        prop="dateRange"
      >
        <el-date-picker
          v-model="ruleForm.dateRange"
          :disabled="isEnd"
          type="daterange"
          range-separator="-"
          value-format="yyyy/MM/dd"
          :start-placeholder="$t('el.common.startDate')"
          :end-placeholder="$t('el.common.endDate')"
          align="right"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        :label="$t('el.planCourse.kechengtuikuan')"
        prop="refundFlag"
      >
        <el-radio-group
          v-model="ruleForm.refundFlag"
          :disabled="isEnd || ruleForm.isEnroll"
        >
          <el-radio :label="1" class="filter-item-radio">{{
            $t("el.planCourse.yunxushenqing")
          }}</el-radio>
          <el-radio :label="0">{{ $t("el.planCourse.buketuikuan") }}</el-radio>
        </el-radio-group>
        <span
          v-show="ruleForm.refundFlag == 1"
          style="color: #aaa; margin-left: 20px"
          >{{ $t("el.planCourse.noRefundNotice") }}</span
        >
      </el-form-item>
      <el-form-item
        :label="$t('el.planCourse.optionalCourses')"
        prop="tableData"
      >
        <!-- 添加课程 btn -->
        <el-button
          v-if="!isEnd"
          style="color: #6049ff"
          type="text"
          size="medium"
          icon="el-icon-plus"
          @click="openDialog"
        >
          {{ $t("el.planCourse.selectCourseButton") }}
        </el-button>
        <!--  表格  -->
        <el-table
          :data="
            tableData.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize
            )
          "
          border
          :header-cell-style="{
            background: '#f5f6f7',
            color: '#555',
            padding: '2px 0',
          }"
          style="width: 100%"
        >
          <el-table-column
            prop="courseName"
            width="200"
            :label="$t('el.planCourse.CourseName')"
          />
          <el-table-column
            prop="organizationName"
            width="140"
            :label="$t('el.planCourse.affiliation')"
          />
          <el-table-column
            prop="sourceCategoryName"
            :label="$t('el.planCourse.courseSource')"
          />
          <el-table-column
            prop="coursePrice"
            :label="$t('el.planCourse.coursePrice')"
            width="100"
          />
          <el-table-column
            prop="isCharge"
            :label="$t('el.planCourse.shifoushoufei')"
          >
            <template slot-scope="scope">
              <el-switch
                @change="(val) => handleIsChargechange(val, scope.row)"
                :disabled="scope.row.coursePrice == 0"
                v-model="scope.row.isCharge"
                active-color="#5F4AFF"
                inactive-color=""
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            prop="originTenantName"
            :label="$t('el.courseList.courseSourceName')"
          />
          <el-table-column
            prop="gradeName"
            :label="$t('el.planCourse.applyGrade')"
          >
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.gradeName"
                placement="top"
              >
                <p class="ellipsis">{{ scope.row.gradeName }}</p>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="subjectName"
            :label="$t('el.courseList.subject')"
          />
          <el-table-column
            prop="termName"
            :label="$t('el.courseList.semester')"
          />
          <el-table-column
            prop="categoryName"
            :label="$t('el.planCourse.courseCategory')"
          />
          <el-table-column
            prop="classCount"
            :label="$t('el.planCourse.numberOfOptionalClasses')"
          />
          <el-table-column
            prop="courseQuota"
            :label="$t('el.planCourse.curriculumPlaces')"
          >
            <template slot-scope="scope">
              {{ scope.row.courseQuota || "-" }}
            </template>
          </el-table-column>

          <el-table-column
            prop="address"
            :label="$t('el.common.operate')"
            fixed="right"
            v-if="!isEnd"
          >
            <template slot-scope="scope">
              <el-button
                @click="deleteItem(scope.row)"
                type="text"
                size="small"
                style="color: #6049ff"
              >
                {{ $t("el.courseClassManage.remove") }}</el-button
              >
            </template></el-table-column
          >
        </el-table>

        <!-- 分页 -->
        <el-pagination
          v-if="this.tableData.length > 0"
          background
          layout="prev, pager, next, total"
          :total="this.tableData.length"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-form-item>
      <template v-if="!isEnd">
        <el-button
          size="medium"
          type="primary"
          @click="submitAdd('ruleForm')"
          >{{ $t("el.common.confirm") }}</el-button
        >
        <el-button size="medium" @click="cancel">{{
          $t("el.common.cancel")
        }}</el-button>
      </template>
    </el-form>
    <AddCourseDialog
      ref="addDialog"
      :semesterList="semesterList"
      :gradeList="courseRangeList"
      :gradeSysList="gradeSysList"
    />
  </div>
</template>

<script>
import AddCourseDialog from "./addDialog.vue";
import {
  addSelectCourseTask,
  removeCourseItem,
  getTaskItem,
  updateSelectCourseTask,
  校验收款方,
} from "@/api/manage/select-course";

export default {
  name: "AddCourse",
  components: {
    AddCourseDialog,
  },
  props: {
    // 学年
    schoolYearList: {
      type: Array,
      default: () => [],
    },
    // 学期
    semesterList: {
      type: Array,
      default: () => [],
    },
    // 选课范围
    courseRangeList: {
      type: Array,
      default: () => [],
    },
    // 年级
    gradeSysList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (!this.ruleForm.schoolYearId || !this.ruleForm.termId) {
        callback(new Error(this.$t("el.common.pleaseSelect")));
      } else {
        callback();
      }
    };
    var validateTable = (rule, value, callback) => {
      console.log("value", value);
      if (this.tableData.length === 0) {
        callback(new Error(this.$t("el.planCourse.selectCourse")));
      } else {
        callback();
      }
    };
    return {
      selectedYearOption: [],
      taskId: null,
      isUpdate: false,
      loading: false,
      currentPage: 1,
      pageSize: 10,
      tableTotal: 0,
      ruleForm: {
        taskName: "",
        termId: "", // 学期
        gradeIds: "",
        schoolYearId: "",
        dateRange: [],
        isEnroll: false,
        refundFlag: 1,
      },
      rules: {
        taskName: [
          {
            required: true,
            message: this.$t("el.common.pleaseEnter"),
            trigger: "blur",
          },
          {
            min: 1,
            max: 30,
            message: this.$t("el.planCourse.CharactersCustom", [1, 30]),
            trigger: "blur",
          },
        ],
        schoolYearId: [
          { required: true, validator: validatePass, trigger: "change" },
        ],
        gradeIds: [
          {
            required: true,
            message: this.$t("el.common.pleaseSelect"),
            trigger: "change",
          },
        ],
        dateRange: [
          {
            required: true,
            message: this.$t("el.planCourse.selectTimeRange"),
            trigger: "change",
          },
        ],
        refundFlag: [
          {
            required: true,
            trigger: "change",
            message: this.$t("el.common.pleaseSelect"),
          },
        ],
        tableData: [
          {
            required: true,
            validator: validateTable,
            trigger: "change",
          },
        ],
      },
      tableData: [],
      isEnd: false, // 是否已结束选课
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs["ruleForm"].clearValidate();
    });
  },
  methods: {
    // 添加课程dialong
    openDialog() {
      // let idList = this.tableData.map((v) => v.courseId);
      // 获取已选课程id
      // this.$refs.addDialog.selectRowId = idList || [];
      this.$refs.addDialog.hasTableData = this.tableData;
      this.$refs.addDialog.dialogVisible = true;
      this.$refs.addDialog.isUpdate = this.isUpdate;
      this.$refs.addDialog.extraParam = {
        schoolYearId: this.ruleForm.schoolYearId,
        termId: this.ruleForm.termId,
      };
      this.$refs.addDialog.state = true;
    },
    submitAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const reqFunciton = this.ruleForm.id
            ? updateSelectCourseTask
            : addSelectCourseTask;
          const {
            taskName,
            termId,
            schoolYearId,
            gradeIds,
            dateRange,
            refundFlag,
          } = this.ruleForm;
          const idList = [];
          this.tableData.forEach((item) => {
            const obj = {
              courseId: item.courseId,
              courseTenantId: item.tenantId,
              courseTenantType: item.tenantType,
              courseTenantInfoId: item.tenantInfoId,
              coursePrice: item.coursePrice,
              isCharge: item.isCharge ? 1 : 0,
              isNewAdd: item.isNewAdd,
            };
            idList.push(obj);
          });
          const params = {
            id: this.isUpdate ? this.ruleForm.id : null,
            taskName,
            termId, // 学期
            gradeIds: gradeIds.join(","),
            schoolYearId,
            taskStartTime: dateRange[0],
            taskEndTime: dateRange[1],
            refundFlag,
            courseList: idList,
          };
          reqFunciton(params)
            .then((res) => {
              this.$message.success(res.message);
              this.isUpdate = false;
              // this.$parent.pageState = 1;
              this.$router.go(-1);
            })
            .catch((err) => {
              console.log("e", err);
            });
        } else {
          return false;
        }
      });
    },
    // 移除
    deleteItem(item) {
      this.$confirm(this.$t("el.planCourse.isRemoveCourseListItem"), "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (this.isUpdate) {
          // 调用接口查询是否可删
          // removeCourseItem(this.ruleForm.id, item.tenantInfoId).then(
          //   (result) => {
          // 删除一条
          this.tableData = this.tableData.filter(
            (v) => v.tenantInfoId !== item.tenantInfoId
          );
          this.copyTableData = this.copyTableData.filter(
            (ele) => ele.tenantInfoId !== item.tenantInfoId
          );
          // }
          // );
        } else {
          // 新增移除
          this.tableData.splice(this.tableData.indexOf(item), 1);
          this.$refs.addDialog.finallyData = JSON.parse(
            JSON.stringify(this.tableData)
          );
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    // 获取当前选课任务详情
    getTaskDetail(id) {
      this.taskId = id;
      this.loading = true;
      getTaskItem(id).then((res) => {
        const result = res.data;
        this.isUpdate = true;
        this.ruleForm.id = result.id;
        this.ruleForm.isEnroll = result.isEnroll;
        this.ruleForm.taskName = result.taskName;
        this.ruleForm.refundFlag = result.refundFlag;

        this.ruleForm.schoolYearId = result.schoolYearId;
        if (this.schoolYearList.every((i) => i.value !== result.schoolYearId)) {
          this.selectedYearOption = [
            { label: result.schoolYearName, value: result.schoolYearId },
          ];
        }

        this.ruleForm.termId = Number(result.termId);
        this.ruleForm.gradeIds = result.gradeIds
          .split(",")
          .map((item) => Number(item));
        this.ruleForm.dateRange = [result.taskStartTime, result.taskEndTime];
        this.tableData = result.courseNameList;
        this.copyTableData = JSON.parse(JSON.stringify(result.courseNameList));
        this.loading = false;
      });
    },
    cancel() {
      this.isEnd = false;
      this.ruleForm = {
        taskName: "",
        termId: "", // 学期
        gradeIds: "",
        schoolYearId: "",
        dateRange: [],
        refundFlag: 1,
      };
      this.selectedYearOption = [];
      this.$router.go(-1);
      // this.$parent.pageState = 1;
    },
    resetChooseData() {
      this.$refs.addDialog.resetChooseData();
    },

    // 已选课程 数据需要根据学年学期切换再去查询最新的
    getChangeCourseList() {
      if (this.tableData.length > 0) {
        const idList = this.tableData.map((item) => item.tenantInfoId);
        const data = {
          // onOffStatus: 0,
          // offset: this.page,
          // limit: 10,
          schoolYearId: this.ruleForm.schoolYearId, // 学年ID
          termById: this.ruleForm.termId, // 学年学期ID
          courseIds: idList.join(","), // 已选课程id
        };
        return this.$store
          .dispatch("getCourseList", this.handparams(data))
          .then((result) => {
            this.tableData = result.data.records;
          });
      }
    },
    handparams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      return params;
    },
    handleIsChargechange(val, row) {
      if (val) {
        this.loading = true;
        校验收款方({
          tenantType: row.tenantType,
          tenantInfoId: row.tenantInfoId,
          tenantId: row.tenantId,
        })
          .then((res) => {
            if (res.data) {
              return;
            } else {
              this.$message.error(
                this.$t("学校/区域名称还没有设置收款账号，暂时不能发布收费课程")
              );
              row.isCharge = 0;
            }
          })
          .catch((e) => {
            row.isCharge = 0;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.add-course-box {
  .back {
    margin-bottom: 20px;
  }
  .gary {
    color: gray;
    font-size: 14px;
  }
  /deep/.el-pagination.is-background {
    text-align: center;
    margin-top: 10px;
  }
  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #6049ff;
    color: #fff;
    border-radius: 50%;
  }
}
/deep/.el-select-dropdown {
  position: absolute !important;
  left: 0px !important;
}
.ellipsis {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #6049ff;
  background: #6049ff;
}
/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #6049ff;
}
</style>
