function focusDom(el) {
  el.focus();
  // element-ui
  el.children[0].focus();
  // 元素有变化，如show或者父元素变化可以加延时或判断
  setTimeout((_) => {
    el.children[0].focus();
  });
}

export default {
  bind(el, binding) {
    focusDom(el);
  },
  update(el) {
    focusDom(el);
  },
};
