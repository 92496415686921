<template>
  <div>
    <el-dialog
      :title="$t('el.planCourse.selectCourseButton')"
      :visible.sync="dialogVisible"
      @closed="closedDialog"
      width="63%"
    >
      <div v-loading="loading">
        <el-form :model="ruleForm" :inline="true">
          <!-- 课程名称 -->
          <el-form-item :label="$t('el.planCourse.CourseName')">
            <el-input
              v-model="ruleForm.courseName"
              size="small"
              clearable
              :placeholder="$t('el.common.pleaseEnter')"
              style="width: 115px"
            ></el-input>
          </el-form-item>
          <!-- 所属机构 -->
          <el-form-item :label="$t('el.planCourse.affiliation')">
            <el-input
              v-model="ruleForm.organizationName"
              size="small"
              clearable
              :placeholder="$t('el.common.pleaseEnter')"
              style="width: 115px"
            />
          </el-form-item>
          <!-- 课程来源 -->
          <el-form-item :label="$t('el.planCourse.courseSource')">
            <el-select
              v-model="ruleForm.sourceId"
              size="small"
              clearable
              :placeholder="$t('el.common.pleaseSelect')"
              style="width: 115px"
              :popper-append-to-body="false"
              popper-class="select"
            >
              <el-option
                v-for="item in sourceOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- // 科目 -->
          <el-form-item :label="$t('el.courseList.subject')">
            <el-select
              v-model="ruleForm.subjectId"
              size="small"
              clearable
              :placeholder="$t('el.common.pleaseSelect')"
              style="width: 155px"
              :popper-append-to-body="false"
              popper-class="select"
            >
              <el-option
                v-for="item in courseSubjectList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- // 年级 -->
          <el-form-item :label="$t('el.courseList.grade')">
            <el-select
              v-model="ruleForm.gradeId"
              size="small"
              clearable
              :placeholder="$t('el.common.pleaseSelect')"
              style="width: 155px"
              :popper-append-to-body="false"
              popper-class="select"
            >
              <el-option
                v-for="item in gradeSysList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- // 学期 -->
          <el-form-item :label="$t('el.courseList.semester')">
            <el-select
              v-model="ruleForm.termId"
              size="small"
              clearable
              :placeholder="$t('el.common.pleaseSelect')"
              style="width: 155px"
              :popper-append-to-body="false"
              popper-class="select"
            >
              <el-option
                v-for="item in termOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- // 类别 -->
          <el-form-item :label="$t('el.planCourse.courseCategory')">
            <el-select
              v-model="ruleForm.categoryId"
              size="small"
              clearable
              :placeholder="$t('el.common.pleaseSelect')"
              style="width: 155px"
              :popper-append-to-body="false"
              popper-class="select"
            >
              <el-option
                v-for="item in courseCategoryList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 按钮 -->
          <el-button style="margin-left: 10px" size="medium" @click="reset">{{
            $t("el.common.reset")
          }}</el-button>
          <el-button size="medium" type="primary" @click="search">{{
            $t("el.common.screening")
          }}</el-button>
        </el-form>

        <!--**** 表格 ****-->
        <el-table
          ref="multipleTable"
          :data="courseList"
          border
          style="width: 100%; margin-top: 10px"
          @select="selectRow"
          @select-all="selectAll"
          :row-key="getRowKeys"
          :header-cell-style="{
            background: '#f5f6f7',
            color: '#555',
          }"
          :empty-text="$t('el.planCourse.noChooseCourse')"
          height="400px"
        >
          <el-table-column
            :reserve-selection="true"
            type="selection"
            width="55"
            :selectable="selectable"
          >
          </el-table-column>
          <el-table-column
            v-for="(item, index) in columns"
            :prop="item.prop"
            :label="item.label"
            :key="index"
          >
            <template slot-scope="scope">
              <el-tooltip
                v-if="item.ellipsis"
                class="item"
                effect="dark"
                :content="scope.row[item.prop]"
                placement="top"
              >
                <p v-if="item.ellipsis" class="ellipsis">
                  {{ scope.row[item.prop] }}
                </p>
              </el-tooltip>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="this.$t('el.planCourse.curriculumPlaces')"
            prop="courseQuota"
          >
            <template slot-scope="scope">
              {{
                scope.row.courseQuota || scope.row.courseQuota == 0
                  ? scope.row.courseQuota
                  : "-"
              }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          @current-change="currentChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next, total"
          :total="total"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $t("el.common.cancel")
        }}</el-button>
        <el-button type="primary" @click="submitAdd">
          {{ $t("el.common.confirm") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCourseCategory,
  getCourseSubject,
} from "@/api/manage/select-course.js";
import { mapState } from "vuex";

export default {
  name: "AddCourseDialogContent",
  data() {
    return {
      dialogVisible: false,
      courseSubjectList: [], // 科目下拉列表
      courseCategoryList: [], // 课程类别下拉列表
      courseList: [],
      isUpdate: false,
      currentPage: 1,
      finallyData: [],
      loading: true,
      state: true,
      total: 0,
      pageSize: 10,
      page: 0,
      copyFinallyData: [],
      sourceOptions: [
        { label: "机构", value: 1 },
        { label: "区域", value: 2 },
      ],
      StatusOptions: [
        { label: "正常", value: 0 },
        { label: "停用", value: 1 },
      ],
      ruleForm: {
        sourceId: "",
        organizationName: "",
        courseName: "",
        gradeId: "",
        subjectId: "",
        termId: "",
        categoryId: "",
      },
      selectALLState: false,
      extraParam: {
        schoolYearId: "",
        termId: "",
      },
    };
  },
  props: {
    semesterList: {
      type: Array,
      default: () => [],
    },
    gradeSysList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    dialogVisible(state) {
      if (state) {
        this.getCourseCategoryList();
        this.getCourseSubjectList();
        this.fatchMethod();
      }
    },
  },
  computed: {
    columns() {
      return [
        // {
        //   type: "selection",
        //   "reserve-selection": true,
        //   width: "48px",
        //   selectable: this.selectable,
        // },
        {
          label: this.$t("el.planCourse.CourseName"),
          prop: "courseName",
        },
        {
          label: this.$t("el.planCourse.affiliation"),
          prop: "organizationName",
        },
        {
          label: this.$t("el.planCourse.courseSource"),
          prop: "sourceCategoryName",
        },
        {
          label: this.$t("el.planCourse.coursePrice"),
          prop: "coursePrice",
        },
        // 来源名称
        {
          label: this.$t("el.courseList.courseSourceName"),
          prop: "originTenantName",
        },
        {
          label: this.$t("el.planCourse.applyGrade"),
          prop: "gradeName",
          ellipsis: true,
        },
        {
          label: this.$t("el.courseList.subject"),
          prop: "subjectName",
        },
        {
          label: this.$t("el.courseList.semester"),
          prop: "termName",
        },
        {
          label: this.$t("el.planCourse.courseCategory"),
          prop: "categoryName",
        },
        {
          label: this.$t("el.planCourse.numberOfOptionalClasses"),
          prop: "classCount",
        },
        // {
        //   label: this.$t("el.planCourse.curriculumPlaces"),
        //   prop: "courseQuota",
        // },
      ];
    },
    ...mapState({
      // 学期下拉数据
      termOptions(state) {
        console.log("state.dict", state.dict);
        return state.dict.courseTermId.map((item) => ({
          label: item.dictionaryText,
          value: item.dictionaryKey,
        }));
      },
    }),
  },
  methods: {
    // 编辑时 已经选了的  禁止取消勾选  copyFinallyData：新增的可以勾选，编辑不可
    // finallyData：对话框勾选了就不可去掉
    selectable(row) {
      // if (this.isUpdate) {
      //   return !this.copyFinallyData.some(
      //     (item) => item.courseId === row.courseId
      //   );
      // } else {
      //   return true;
      // }
      return !this.$parent.tableData.some(
        (item) => item.tenantInfoId === row.tenantInfoId
      );
    },
    currentChange(val) {
      this.currentPage = val;
      // this.page 接口需要offset
      this.page = (val - 1) * this.pageSize;
      this.fatchMethod();
      this.state = false;
    },
    // 勾选单条
    selectRow(val, row) {
      console.log("row 单条id", row.tenantInfoId);
      if (
        this.finallyData.some((item) => item.tenantInfoId === row.tenantInfoId)
      ) {
        // 有就去掉
        this.finallyData = this.finallyData.filter(
          (item) => item.tenantInfoId !== row.tenantInfoId
        );
      } else {
        // 没有 就存
        this.finallyData = [...this.finallyData, row];
      }
      console.log(" this.finallyData", this.finallyData);
    },
    // 全选
    selectAll(selection) {
      this.selectAllState = !this.selectAllState;
      //   新增的全选
      if (!this.isUpdate) {
        // 未限制：
        // if (selection.length == 0) {
        //   this.courseList.forEach((row) => {
        //     if (
        //       this.finallyData.some((item) => item.courseId === row.courseId)
        //     ) {
        //       this.finallyData = this.finallyData.filter(
        //         (item) => item.courseId !== row.courseId
        //       );
        //     }
        //   });
        // } else {
        //   this.finallyData = [...this.finallyData, ...selection];
        // }
        // -------------------------------------
        // 限制： 进入外层表格的 不能勾选不能删了  没有进入表格的可以操作
        console.log("selection", this.selectAllState, selection);
        // 新增时过滤掉已勾选的数据  表格已经有数据的情况下
        if (this.$parent.tableData.length > 0) {
          const idList = this.$parent.tableData.map(
            (item) => item.tenantInfoId
          );
          const arr = selection.filter(
            (item) => !idList.includes(item.tenantInfoId)
          );
          // 如果点了单选，接着多选的情况 单选的时候还未进入外层表格 会出现重复
          const temp = [...this.finallyData, ...arr];
          this.finallyData = Array.from(new Set(temp));
        } else {
          // 第一次新增 全选 取消全选的情况
          if (selection.length == 0) {
            this.courseList.forEach((row) => {
              if (
                this.finallyData.some(
                  (item) => item.tenantInfoId === row.tenantInfoId
                )
              ) {
                this.finallyData = this.finallyData.filter(
                  (item) => item.tenantInfoId !== row.tenantInfoId
                );
              }
            });
          } else {
            const temp = [...this.finallyData, ...selection];
            this.finallyData = Array.from(new Set(temp));
          }
        }
      } else {
        // 编辑
        console.log("selection", this.selectAllState, selection);
        // 如果selection的数据已经在 copy中，已经是选中的了，就不加
        // if (selection.length !== 0) {
        //   selection.forEach((element) => {
        //     if (
        //       !this.copyFinallyData.some(
        //         (item) => item.courseId === element.courseId
        //       )
        //     ) {
        //       const temp = [...this.finallyData, ...selection];
        //       this.finallyData = Array.from(new Set(temp));
        //     }
        //   });
        // }
        // if (this.$parent.tableData.length > 0) {
        if (this.selectAllState) {
          const idList = this.$parent.tableData.map(
            (item) => item.tenantInfoId
          );
          const arr = selection.filter(
            (item) => !idList.includes(item.tenantInfoId)
          );
          console.log("arr", arr);
          // 如果点了单选，接着多选的情况 单选的时候还未进入外层表格 会出现重复
          const temp = [...this.finallyData, ...arr];
          this.finallyData = Array.from(new Set(temp));
        } else {
          const idList = this.$parent.tableData.map(
            (item) => item.tenantInfoId
          );
          this.finallyData = this.finallyData.filter((item) =>
            idList.includes(item.tenantInfoId)
          );
        }
      }
      console.log("finallyData", this.finallyData);
    },
    // 确定添加课程
    submitAdd() {
      // 赋值给外层表格;
      this.$parent.tableData = JSON.parse(JSON.stringify(this.finallyData));
      this.state = true;
      this.dialogVisible = false;
    },
    // 筛选课程表格
    fatchMethod() {
      this.loading = true;
      let data = {
        taskId: this.$parent.taskId,
        sourceId: this.ruleForm.sourceId,
        organizationName: this.ruleForm.organizationName,
        courseName: this.ruleForm.courseName,
        gradeId: this.ruleForm.gradeId,
        subjectId: this.ruleForm.subjectId,
        termId: this.ruleForm.termId,
        categoryId: this.ruleForm.categoryId,
        onOffStatus: 0,
        offset: this.page,
        limit: 10,
        schoolYearId: this.extraParam.schoolYearId, // 学年ID
        termById: this.extraParam.termId, // 学年学期ID
      };
      return this.$store
        .dispatch("getCourseChooseList", this.handparams(data))
        .then((result) => {
          this.courseList = result.data.records;
          this.courseList.forEach((item) => (item.isNewAdd = 1));
          this.total = result.data.total;
          return result;
        })
        .finally(() => {
          if (this.isUpdate) {
            // 用于编辑已选的课程不可取消勾选  禁用  （copyTableData获取已提交的数据）
            this.copyFinallyData = JSON.parse(
              JSON.stringify(this.$parent.copyTableData)
            );
            console.log("this.copyFinallyData ---000---", this.copyFinallyData);
          }
          if (this.state) {
            this.finallyData = this.$parent.tableData;
          }
          this.$refs.multipleTable.clearSelection();
          // 勾选上已选的;
          this.courseList.forEach((row) => {
            if (
              this.finallyData.some(
                (item) => item.tenantInfoId === row.tenantInfoId
              )
            ) {
              this.$refs.multipleTable.toggleRowSelection(row, true);
            }
          });
          this.loading = false;
        });
    },

    getRowKeys(row) {
      return row.tenantInfoId;
    },
    // 参数处理
    handparams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      return params;
    },
    // 获取课程类别下拉数据
    getCourseCategoryList() {
      getCourseCategory().then(({ status, data }) => {
        if (status === 200) {
          this.courseCategoryList = data.map(({ cnName, id }) => ({
            label: cnName,
            value: id,
          }));
        }
      });
    },
    // 获取科目下拉数据
    getCourseSubjectList() {
      getCourseSubject().then(({ status, data }) => {
        if (status === 200) {
          this.courseSubjectList = data.map(({ name, id }) => ({
            label: name,
            value: id,
          }));
        }
      });
    },
    resetChooseData() {
      this.currentPage = 1;
      this.finallyData = [];
    },
    closedDialog() {
      this.currentPage = 1;
      this.page = 0;
      this.ruleForm = {};
      this.currentPage = 1;
      this.finallyData = [];
    },
    search() {
      this.currentPage = 1;
      this.fatchMethod();
    },
    reset() {
      this.currentPage = 1;
      this.page = 0;
      this.ruleForm = {};
      this.fatchMethod();
    },
  },
};
</script>

<style>
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #6049ff;
  border-color: #6049ff;
}

.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: #6049ff;
}
.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: #6049ff;
}
</style>
<style scoped>
.ellipsis {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
