<template>
  <!-- 选课管理 -->
  <div v-loading="allLoading" class="container-wrap">
    <xm-table
      ref="xmTable"
      :fatch-method="fatchMethod"
      :form-items="formItems"
      :columns="columns"
      :row-key="(row) => row.id"
      :formOptions="{
        hiddenDefaultFormBtn: true,
      }"
      :tableOptions="{
        height: tableHeight,
      }"
      noEmptyText="el.common.noTaskData"
      @reset-form="resetFormCallback"
    >
      <template slot="formBtn">
        <el-button
          size="small"
          class="teachManage-tool-btnicon"
          @click="resetForm()"
          >{{ $t("el.common.reset") }}</el-button
        >
        <el-button
          type="primary"
          size="small"
          class="teachManage-tool-btnicon"
          @click="query()"
          >{{ $t("el.common.screening") }}</el-button
        >
        <el-button
          v-btn="'btn-select-course-add'"
          size="small"
          type="primary"
          class="teachManage-tool-btnicon"
          @click="goAddCourse"
          >{{ $t("el.common.add") }}</el-button
        >
      </template>
      <template slot="courseName" slot-scope="scope">
        <!-- 超出悬浮，不超出不悬浮 -->
        <tooltip-over
          :content="scope.row.courseName"
          class="class-menu-name"
          :singleAble="false"
        ></tooltip-over>
      </template>
      <template slot="otherHandle" slot-scope="{ row }">
        <el-button
          v-btn="'btn-select-course-view'"
          type="text"
          size="mini"
          @click="jumpResult(row)"
          >{{ $t("el.planCourse.viewResults") }}</el-button
        >
        <el-button
          v-btn="'btn-select-course-edit'"
          type="text"
          size="mini"
          @click="editCourse(row)"
          >{{
            judgeStatus(row.taskStartTime, row.taskEndTime) == 1
              ? $t("el.common.view")
              : $t("el.common.edit")
          }}</el-button
        >
        <el-button
          v-btn="'btn-select-course-delete'"
          type="text"
          size="mini"
          :style="{
            color:
              judgeStatus(row.taskStartTime, row.taskEndTime) == 1 && '#c0c4cc',
          }"
          :disabled="judgeStatus(row.taskStartTime, row.taskEndTime) == 1"
          @click="deleteTask(row)"
          >{{ $t("el.common.delete") }}</el-button
        >
      </template>
    </xm-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getClasslist,
  getSelectCourseTaskList,
  deleteTaskItem,
} from "@/api/manage/select-course";
export default {
  name: "classManage",
  data() {
    return {
      tableHeight: undefined,
      editId: "", // 编辑数据的id
      gradeList: [], // 选课范围下拉数据
      termList: [],
      subjectList: [],
      allLoading: false,
      tableData: [
        {
          id: 1,
          courseName: "1",
          courseSource: "sadsa",
        },
        {
          id: 2,
          courseName: "1",
          courseSource: "courseSource",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["attendSchoolList"]),
    columns() {
      return [
        // 选课任务名称
        {
          label: this.$t("el.planCourse.selectCouresTaskName"),
          prop: "taskName",
          width: "160px",
        },
        // 开课学年
        {
          label: this.$t("el.planCourse.openingAcademicYear"),
          prop: "schoolYearName",
          width: "140px",
        },
        // 开课学期
        {
          label: this.$t("el.planCourse.openingSemester"),
          prop: "termName",
          width: "120px",
        },
        // 选课范围
        {
          label: this.$t("el.planCourse.selectCourseRange"),
          prop: "gradeNames",
          width: "160px",
        },
        // 选课时间
        {
          label: this.$t("el.planCourse.selectCourseDate"),
          prop: "selectCourseDate",
          width: "200px",
          formatter: ({ taskStartTime, taskEndTime }) =>
            `${taskStartTime} - ${taskEndTime}`,
        },
        // 课程退款
        {
          label: this.$t("el.planCourse.kechengtuikuan"),
          prop: "refundFlag",
          formatter: ({ refundFlag }) => {
            return ["不可退款", "允许退款"][Number(refundFlag)];
          },
        },
        // 课程数量
        {
          label: this.$t("el.planCourse.numberOfCourses"),
          prop: "courseCount",
        },
        // 状态
        {
          label: this.$t("el.planCourse.state"),
          prop: "state",
          formatter: ({ taskStartTime, taskEndTime }) => {
            const state = this.judgeStatus(taskStartTime, taskEndTime);
            return ["未开始", "已结束", "进行中"][state];
          },
        },
        // 创建人
        {
          label: this.$t("el.planCourse.founder"),
          prop: "creatorName",
          width: "100px",
          formatter: ({ creatorName }) => creatorName || "-",
        },
        // 创建时间
        {
          label: this.$t("el.infrastructure.creationTime"),
          prop: "createTime",
          width: "180px",
        },
        // 操作
        {
          label: this.$t("el.common.operate"),
          slotName: "otherHandle",
          fixed: "right",
          width: "200px",
        },
      ];
    },
    // 定义查询表单
    formItems() {
      return [
        // 选课任务名称
        {
          itemType: "input",
          prop: "taskName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.selectCouresTaskName"),
          clearable: true,
          itemStyle: "width:150px;",
        },
        // 开课学年
        {
          itemType: "select",
          prop: "schoolYearId",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.openingAcademicYear"),
          selectDefault: false,
          clearable: true,
          itemStyle: "width:150px;",
          options: this.schoolList,
        },
        // 开课学期
        {
          itemType: "select",
          prop: "termId",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.openingSemester"),
          selectDefault: false,
          clearable: true,
          itemStyle: "width:150px;",
          options: this.semesterList,
        },
        // 选课范围
        {
          itemType: "select",
          prop: "gradeIds",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.selectCourseRange"),
          selectDefault: false,
          clearable: true,
          itemStyle: "width:150px;",
          options: this.gradeList,
        },
        // 状态
        {
          itemType: "select",
          prop: "status",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.state"),
          selectDefault: false,
          clearable: true,
          itemStyle: "width:150px;",
          options: [
            { label: "未开始", value: 1 },
            { label: "进行中", value: 2 },
            { label: "已结束", value: 3 },
          ],
        },
        // 选课时间
        {
          itemType: "daterange",
          prop: "taskTime",
          size: "small",
          prop1: "startTime",
          prop2: "endTime",
          "popper-append-to-body": false,
          "value-format": "yyyy-MM-dd",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.selectCourseDate"),
          clearable: true,
        }, // 课程退款
        {
          itemType: "select",
          prop: "refundFlag",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.kechengtuikuan"),
          selectDefault: false,
          clearable: true,
          itemStyle: "width:150px;",
          options: [
            { label: this.$t("el.planCourse.yunxutuikuan"), value: 1 },
            { label: this.$t("el.planCourse.buketuikuan"), value: 0 },
          ],
        },
        // 创建人
        {
          itemType: "input",
          prop: "creator",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.founder"),
          clearable: true,
          itemStyle: "width:150px;",
        },
        // 创建时间
        {
          itemType: "daterange",
          prop: "createTime",
          size: "small",
          prop1: "startTime",
          prop2: "endTime",
          "popper-append-to-body": false,
          "value-format": "yyyy-MM-dd",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.infrastructure.creationTime"),
          clearable: true,
        },
      ];
    },
    schoolList() {
      return this.$store.state.dict.SCHOOL_YEAR.map((item) => ({
        label: item.dictionaryText,
        value: item.dictionaryKey,
      }));
    },
    semesterList() {
      return this.$store.state.dict.TERM.map((item) => ({
        label: item.dictionaryText,
        value: item.dictionaryKey,
      }));
    },
  },
  created() {
    this.getCourseRangeList();
  },
  methods: {
    // 获取选课范围下拉列表
    getCourseRangeList() {
      getClasslist().then(({ status, data }) => {
        if (status === 200) {
          this.gradeList = data.map(({ gradeName, gradeId }) => ({
            label: gradeName,
            value: gradeId,
          }));
        }
      });
    },
    // 筛选
    query() {
      if (this.$refs["xmTable"]) {
        this.$refs["xmTable"].query();
      }
    },
    // 重置
    resetForm() {
      this.$refs["xmTable"].resetForm();
    },
    // 重置成功回调
    resetFormCallback(queryParams) {
      console.log("重置回调", queryParams);
    },
    // 查看结果
    jumpResult(row) {
      this.$router.push({
        path: "/select-courses/detail",
        query: {
          pageState: 3,
          taskId: row.id,
        },
      });
    },
    // 编辑
    editCourse(row) {
      this.$router.push({
        path: "/select-courses/detail",
        query: {
          pageState: 2,
          taskId: row.id,
          isEnd: this.judgeStatus(row.taskStartTime, row.taskEndTime) == 1,
        },
      });
    },

    handParams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      return params;
    },

    /**
     * 获取列表数据的请求方法
     */
    fatchMethod(params) {
      const { createTime, taskTime, ...rest } = params;
      const [createStartTime, createEndTime] = Array.isArray(createTime)
        ? createTime
        : [];
      const [taskStartTime, taskEndTime] = Array.isArray(taskTime)
        ? taskTime
        : [];
      return getSelectCourseTaskList(
        this.handParams({
          createStartTime,
          createEndTime,
          taskStartTime,
          taskEndTime,
          ...rest,
        })
      )
        .then((result) => {
          return result;
        })
        .catch(() => {
          return null;
        });
    },
    // 删除选课
    deleteTask(row) {
      this.$confirm(
        this.$t("el.planCourse.deleteCourseSelectionTask") + "？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        deleteTaskItem(row.id).then(({ status }) => {
          if (status === 200) {
            this.$message.success("删除成功");
            this.query();
          }
        });
      });
    },

    judgeStatus(taskStartTime, taskEndTime) {
      let startTime = "";
      let endTime = "";
      if (taskStartTime) {
        const standardStartTime = taskStartTime.split("/").join("-");
        startTime = new Date(`${standardStartTime} 00:00:00`).getTime();
      }
      if (taskEndTime) {
        const standardEndTime = taskEndTime.split("/").join("-");
        endTime = new Date(`${standardEndTime} 23:59:59`).getTime();
      }
      const time = new Date().getTime();
      if (startTime && time < startTime) {
        // 未开始
        return 0;
      }
      if (endTime && time > endTime) {
        // 已结束
        return 1;
      }
      if (startTime && endTime && time > startTime && time < endTime) {
        // 进行中
        return 2;
      }
      return "";
    },
    /**
     * 窗口变化, tableHeight: 固定表头
     */
    onWindowResize() {
      var height = document.documentElement.clientHeight;
      height -= 380;
      if (height < 280) height = 280;
      this.tableHeight = height + "px";
    },
    goAddCourse() {
      this.$router.push({
        path: "/select-courses/detail",
        query: {
          pageState: 2,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container-wrap {
  background: #ffffff;
  padding: 30px 50px;
  border-radius: 10px;
  min-height: 660px;

  /deep/ .el-button--small,
  .el-button--small.is-round {
    padding: 9px 15px;
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
  /deep/ .el-range-editor--small.el-input__inner {
    width: 220px;
  }
}
/deep/ .el-dialog {
  margin-top: 24 px !important;
  margin-bottom: 24 px !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: left;
}
.xm-table .bskCell .cell .class-menu-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  /*! autoprefixer: off */
}
</style>
