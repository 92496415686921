/**
 * 问卷管理
 */
import axios from "@/plugins/axios.js";

/**
 * 问卷列表分页
 * @return {Promise}
 */
export function getQuestionnaireList(data) {
  return axios.post(
    "/questionnaire/management/page/questionnaireManagement",
    data
  );
}

/**
 * 删除问卷
 * @return {Promise}
 */
export function deleteQuestionnaireById(id) {
  return axios.delete(`/questionnaire/management/detele/${id}`);
}

/**
 * 添加问卷
 * @return {Promise}
 */
export function addQuestionnaire(data) {
  return axios.post(
    `/questionnaire/management/add/questionnaireManagement`,
    data
  );
}

/**
 * 编辑问卷
 * @return {Promise}
 */
export function updateQuestionnaire(data) {
  return axios.post(
    `/questionnaire/management/update/questionnaireManagement`,
    data
  );
}

/**
 * 获取问卷发放范围  学生管理模块内全部年级
 * @return {Promise}
 */
export function getQuestionnaireGrade() {
  return axios.post(`questionnaire/management/grade`);
}

/**
 * 是否可以删除问卷
 * @return {Promise}
 */
export function questionnaireCanDelete(id) {
  return axios.delete(`/questionnaire/management/detele/pre/${id}`);
}
