<template>
  <div class="plan-finish">
    <el-image class="icon-finish" :src="iconSuccess"></el-image>
    <div class="plan-finish-text">
      {{ $t("el.planCourse.scheduleGenerateSuccess") }}
    </div>

    <div class="mt20">
      <el-button @click="goBack" size="small">{{
        $t("el.planCourse.returnsList")
      }}</el-button>
      <el-button @click="checkPlanTable" size="small" type="primary">{{
        $t("el.planCourse.checkSchedule")
      }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "planFinish",
  data() {
    return {
      iconSuccess: require("@/assets/images/icon/icon-dui.png"),
    };
  },
  methods: {
    checkPlanTable() {
      this.$router.replace({
        name: "schoolTimetabelCheck",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.plan-finish {
  height: 400px;
  padding-top: 60px;
  text-align: center;
  .icon-finish {
    width: 100px;
    height: 100px;
  }
  &-text {
    font-size: 26px;
    color: #333333;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 60px;
  }
  /deep/ .el-button--small,
  .el-button--small.is-round {
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #6049ff;
    color: #6049ff;
    font-size: 13px;
  }
  /deep/ .el-button--primary {
    color: #fff;
    background: #6049ff;
    -webkit-box-shadow: 0px 5px 15px 0px rgb(172 178 193 / 36%);
    box-shadow: 0px 5px 15px 0px rgb(172 178 193 / 36%);
  }
}
</style>
