<template>
  <!-- 排课列表 -->
  <div v-loading="allLoading" class="container-wrap">
    <xm-table
      ref="xmTable"
      :fatch-method="fatchMethod"
      :form-items="formItems"
      :columns="columns"
      :formOptions="{
        hiddenDefaultFormBtn: true,
      }"
      :autoLoad="false"
      :showPlanBtn="true"
      :tableOptions="{
        height: tableHeight,
        customCellStyle: false,
        headerStyle: headerStyle,
      }"
      @reset-form="resetFormCallback"
      @changeQueryParams="changeQueryParams"
    >
      <template slot="formBtn">
        <el-button
          type="primary"
          size="small"
          class="teachManage-tool-btnicon"
          @click="query()"
          >{{ $t("el.common.screening") }}</el-button
        >
        <el-button
          size="small"
          class="teachManage-tool-btnicon"
          @click="resetForm()"
          >{{ $t("el.common.reset") }}</el-button
        >
        <el-button
          size="small"
          class="teachManage-tool-btnicon"
          type="primary"
          @click="newAdd()"
          >{{ $t("el.common.new") }}</el-button
        >
      </template>

      <template slot="otherHandle" slot-scope="scope">
        <el-button
          v-if="scope.row.planStatus === 2"
          type="text"
          size="small"
          @click="toDetails(scope.row.id)"
          >{{ $t("el.planCourse.view") }}</el-button
        >
        <el-button
          v-if="scope.row.planStatus === 1"
          type="text"
          size="small"
          @click="editDetails(scope.row.id)"
          >{{ $t("el.planCourse.arrangeClass") }}</el-button
        >
        <el-button
          size="small"
          type="text"
          @click="deleteAttendClass(scope.row)"
          >{{ $t("el.planCourse.delete") }}</el-button
        >
      </template>
    </xm-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  // 定义数据
  data() {
    return {
      tableHeight: undefined,
      schoolList: [],
      dialogForm: {},
      canCommit: true,
      allLoading: false,
      dialogTitle: "新增时段",
      rules: {
        schoolCode: [
          {
            required: true,
            message: this.$t("el.planCourse.selectSchool"),
            trigger: "change",
          },
        ],
        date: [
          {
            required: true,
            message: this.$t("el.planCourse.pleaseSelectTimePeriod"),
            trigger: "change",
          },
        ],
      },
      creatorData: [],
      nowSchoolCode: "",
      isSelectDefault: false,
    };
  },
  mounted() {
    this.init();
  },
  // 计算属性
  computed: {
    ...mapGetters(["attendSchoolList", "creatorList"]),
    /**
     * 定义列
     */
    columns() {
      let that = this;
      return [
        {
          label: this.$t("el.planCourse.courseName"),
          prop: "planName",
          // labelRule: 2,
          lineRule: true,
          showtooltip: "false",
          width: "150px",
        },
        // {
        //   label: this.$t("el.planCourse.school"),
        //   prop: "schoolName",
        //   // labelRule: 2,
        //   lineRule: true,
        //   showtooltip: "false",
        //   width: "150px",
        // },
        {
          label: this.$t("el.planCourse.arrangingDate"),
          prop: "planStartDate",
          lineRule: true,
          showtooltip: "false",
          singleAble: true,
          formatter(row) {
            if (row.planStartDate && row.planEndDate) {
              return row.planStartDate + "~" + row.planEndDate;
            } else {
              return "";
            }
          },
        },
        {
          label: this.$t("el.planCourse.creationDate"),
          prop: "createTime",
          lineRule: true,
          showtooltip: "false",
          singleAble: true,
        },
        {
          label: this.$t("el.planCourse.founder"),
          prop: "creatorName",
          lineRule: true,
          showtooltip: "false",
          singleAble: true,
        },
        {
          label: this.$t("el.planCourse.state"),
          prop: "planStatus",
          lineRule: true,
          showtooltip: "false",
          singleAble: true,
          formatter(row) {
            return that.$t(
              ["el.planCourse.courseArranging", "el.planCourse.completed"][
                row.planStatus - 1
              ]
            );
          },
        },
        {
          label: this.$t("el.common.operate"),
          slotName: "otherHandle",
          width: this.$t("el.planCourse.operateWidth"),
          showtooltip: "false",
        },
      ];
    },
    // 定义查询表单
    formItems() {
      console.log("this.schoolList", this.schoolList);
      return [
        // {
        //   itemType: "select",
        //   prop: "schoolCode",
        //   placeholder: this.$t("el.planCourse.selectSchool"),
        //   label: this.$t("el.planCourse.school"),
        //   selectDefault: this.isSelectDefault,
        //   itemStyle: "width:310px;",
        //   defaultParam: "defaultSchool",
        //   options: this.schoolList,
        // },
        {
          itemType: "input",
          prop: "planName",
          placeholder: this.$t("el.planCourse.enterScheduleName"),
          label: this.$t("el.planCourse.courseName"),
        },
        {
          itemType: "daterange",
          prop: "courseDateRange",
          size: "small",
          prop1: "planDateStart",
          prop2: "planDateEnd",
          placeholder: this.$t("el.planCourse.SelectRange"),
          label: this.$t("el.planCourse.arrangingDate"),
        },
        {
          itemType: "daterange",
          prop: "createDateRange",
          size: "small",
          prop1: "createDateStart",
          prop2: "createDateEnd",
          placeholder: this.$t("el.planCourse.SelectRange"),
          label: this.$t("el.planCourse.creationDate"),
        },
        {
          itemType: "select",
          prop: "creator",
          placeholder: this.$t("el.planCourse.selectFounder"),
          label: this.$t("el.planCourse.founder"),
          clearable: true,
          options: this.creatorData,
        },
        {
          itemType: "select",
          prop: "planStatus",
          placeholder: this.$t("el.planCourse.SelectState"),
          label: this.$t("el.planCourse.state"),
          options: [
            {
              value: "",
              label: this.$t("el.planCourse.all"),
            },
            {
              value: 1,
              label: this.$t("el.planCourse.courseArranging"),
            },
            {
              value: 2,
              label: this.$t("el.planCourse.completed"),
            },
          ],
        },
      ];
    },
  },
  methods: {
    // cellStyle() {
    //   return "two-line-cell";
    // },
    headerStyle({ row, rowIndex }) {
      return "bskHeader";
    },
    async init() {
      this.isSelectDefault = true;
      this.getCreatorData();
      this.query();
      // await this.getAuthSchoolList();
      // if (this.schoolList.length > 0) {
      //   await this.getCreatorData();
      //   this.query();
      // } else {
      //   this.allLoading = false;
      // }
    },
    /**
     * 筛选
     */
    query() {
      // console.log("this.$refs", this.$refs["xmTable"]);
      if (this.$refs["xmTable"]) {
        this.allLoading = true;
        this.$refs["xmTable"].query();
      }
    },
    // 如果select更改
    async changeQueryParams(val) {
      console.log(val.schoolCode, this.nowSchoolCode);
      if (val.schoolCode) {
        if (val.schoolCode !== this.nowSchoolCode) {
          this.isSelectDefault = false;
          console.log("val.schoolCode", val.schoolCode);
          this.nowSchoolCode = val.schoolCode;
          this.$refs["xmTable"].setFormValue("creator", "");
          await this.getCreatorData();
        }
      } else {
        if (val.schoolCode !== this.nowSchoolCode) {
          this.isSelectDefault = false;
          this.nowSchoolCode = val.schoolCode;
          this.$refs["xmTable"].setFormValue("creator", "");
          await this.getCreatorData();
        }
      }
    },
    // 新建
    newAdd() {
      this.$store.dispatch("addPlanCourseData").then((res) => {
        if (res.success) {
          this.$router.push({
            name: "planCourseDetails",
            query: { id: res.data },
          });
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },

    // 排课已完成-查看排课详情，会跳到之后的新页面 查看课表
    toDetails(id) {
      // eslint-disable-next-line object-curly-spacing
      this.$router.push({ name: "schoolTimetabelCheck", query: { id } });
    },

    // 排课未完成-跳转编辑页面
    editDetails(id) {
      this.$store.commit("SET_PLANDETAIL_STEP", 0);
      // eslint-disable-next-line object-curly-spacing
      this.$router.push({ name: "planCourseDetails", query: { id } });
    },

    /**
     * 重置
     */
    resetForm() {
      this.isSelectDefault = true;
      this.$refs["xmTable"].resetForm();
    },

    resetFormCallback(queryParams) {
      this.setDefaultSchool(queryParams, this.schoolList);
    },

    // 设置默认学校
    setDefaultSchool(queryParams, schoolList) {
      let hasDefault = false;
      schoolList.forEach((element) => {
        if (element.defaultSchool) {
          this.$set(queryParams, "schoolCode", element.schoolCode);
          hasDefault = true;
        }
      });
      if (!hasDefault) {
        if (schoolList && schoolList.length > 0) {
          this.$set(queryParams, "schoolCode", schoolList[0].schoolCode);
        }
      }
    },

    // 获取学校列表
    async getAuthSchoolList() {
      await this.$store.dispatch("getSchoolList");

      this.schoolList = this.attendSchoolList;
      if (this.schoolList.length > 0) {
        this.nowSchoolCode = this.attendSchoolList[0].schoolCode;
      }
      return this.attendSchoolList;
    },

    // 获取创建人下拉框数据
    async getCreatorData() {
      await this.$store.dispatch("getCreatorData", this.nowSchoolCode);

      this.creatorData = this.creatorList;
      console.log("creatorData", this.creatorData);
      return this.creatorData;
    },

    // 点击删除
    deleteAttendClass(row) {
      let tip;
      if (row.planStatus === 2) {
        tip = this.$t("el.courseList.deleteSchedulingTask1");
      } else if (row.planStatus === 1) {
        tip = this.$t("el.courseList.deleteSchedulingTask");
      }
      this.$confirm(tip, this.$t("el.common.reminder"), {
        confirmButtonText: this.$t("el.common.confirm"),
        cancelButtonText: this.$t("el.common.cancel"),
        type: "warning",
      })
        .then(() => {
          this.$store
            .dispatch("deletePlanCourseData", row.id)
            .then((result) => {
              if (result.success) {
                this.$message.success(
                  this.$t("el.classManage.deleteSuccess") + "！"
                );
                this.query();
              }
            });
        })
        .catch(() => {
          // this.$message.error("删除失败！");
        });
    },
    /**
     * 获取列表数据的请求方法
     */
    fatchMethod(params) {
      this.allLoading = true;
      delete params.courseDateRange;
      delete params.createDateRange;
      delete params.total;
      delete params.page;
      console.log("params", params);
      params.createDateStart = this.$utils.getFormatterDate(
        params.createDateStart
      );
      params.createDateEnd = this.$utils.getFormatterDate(params.createDateEnd);
      params.planDateStart = this.$utils.getFormatterDate(params.planDateStart);
      params.planDateEnd = this.$utils.getFormatterDate(params.planDateEnd);
      return this.$store
        .dispatch("getPlanCourseList", this.handparams(params))
        .then((result) => {
          console.log("result", result);
          this.allLoading = false;
          return result;
        })
        .catch(() => {
          this.allLoading = false;
        });
    },

    /**
     * 窗口变化, tableHeight: 固定表头
     */
    onWindowResize() {
      var height = document.documentElement.clientHeight;
      height -= 380;
      if (height < 280) height = 280;
      this.tableHeight = height + "px";
    },
    // 参数处理
    handparams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      // console.log("data===" + JSON.stringify(params))
      return params;
    },
  },
};
</script>

<style lang="less" scoped>
.container-wrap {
  background: #ffffff;
  padding: 30px 50px;
  border-radius: 10px;

  /deep/ .el-button--small,
  .el-button--small.is-round {
    padding: 9px 15px;
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
  /deep/ .el-date-editor--daterange.el-input__inner {
    width: 230px;
    height: 40px;
  }
  /deep/ .el-range-editor--small .el-range-separator {
    line-height: 32px;
  }
  // /deep/ .xm-table .bskCell .cell {
  //   height: 40px;
  //   white-space: pre-wrap;
  //   display: -webkit-box;
  // }
  /deep/ .el-button--default,
  .el-button--default:hover {
    color: #6049ff;
    background-color: #fff;
    border-color: #6049ff !important;
  }
  /deep/ .xm-table .el-table__row {
    td {
      height: 65px;
    }
    .two-line-cell:first-child,
    .two-line-cell:nth-child(2) {
      height: 65px;
      overflow: visible;
      .cell.el-tooltip {
        width: 100%;
        line-height: 20px;
        height: 40px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        white-space: unset;
        span {
          height: 40px;
          display: block;
        }
        .more-line-height {
          line-height: 38px;
        }
      }
    }
  }
}
/deep/ .el-dialog {
  margin-top: 24 px !important;
  margin-bottom: 24 px !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: left;
}
</style>
