<template>
  <div class="teaching-plan">
    <div v-loading="pageLoading">
      <h3>{{ $t("el.planCourse.teachingPlan") }}</h3>
      <div>
        <p class="teaching-plan-add">
          <label @click="addLine">
            <i class="el-icon-plus"></i>
            <span>{{ $t("el.planCourse.add") }}</span>
          </label>
        </p>
      </div>
      <div>
        <t-form
          ref="tRef"
          v-loading="tableLoading"
          @editData="editData"
          @saveData="saveData"
          @deletePlanData="deletePlanData"
          @autoGetStaffAndClassroom="autoGetStaffAndClassroom"
          @autoSetSubjectAndGradeId="autoSetSubjectAndGradeId"
          @changeCourseReset="changeCourseReset"
          @changeClassOptions="changeClassOptions"
          @changeTeacherOptions="changeTeacherOptions"
          :tableVisible="tableVisible"
          :paramsForm="paramsForm"
        ></t-form>
      </div>
      <div class="teaching-plan-operate-btns">
        <el-button @click="prev" type="primary" plain>{{
          $t("el.common.previousStep")
        }}</el-button>
        <el-button @click="next" type="primary">{{
          $t("el.planCourse.generateSchedule")
        }}</el-button>
      </div>
    </div>
    <p class="loading-tips" v-show="pageLoading">
      {{ $t("el.planCourse.beingGeneratedSchedule") }}
    </p>
  </div>
</template>

<script>
import TForm from "./table-form/t-form";
import { mapGetters } from "vuex";

export default {
  name: "teachingPlan",
  components: {
    TForm,
  },
  data() {
    return {
      paramsForm: {
        params: [],
        paramsRules: {
          courseId: [
            {
              required: true,
              message: this.$t("el.planCourse.selectCourse"),
              trigger: "change",
            },
          ],
          classId: [
            {
              required: true,
              trigger: "change",
              validator: this.checkClass,
            },
          ],
          staffCode: [
            {
              required: true,
              trigger: "change",
              validator: this.chackStaffCode,
            },
          ],
          classroomId: [
            {
              required: true,
              message: this.$t("el.planCourse.chooseClassroom"),
              trigger: "change",
            },
          ],
          courseCount: [
            {
              required: true,
              trigger: "blur",
              validator: (rule, value, callback) => {
                console.log("value", value);
                if (value === "" || value === null || value === undefined) {
                  callback(
                    new Error(this.$t("el.planCourse.enterNumberSessionsWeek"))
                  );
                } else {
                  if (value > this.courseCount) {
                    callback(
                      new Error(
                        this.$t("el.planCourse.maximumNumberSection") +
                          `${this.courseCount}`
                      )
                    );
                  } else {
                    callback();
                  }
                }
              },
            },
          ],
        },
      },
      classroomOptions: [],
      newCourseOptions: [],
      tableVisible: true,
      planId: this.$route.query.id,
      tableLoading: false,
      schoolCode: "",
      courseCount: null,
      timer: null, // 定时器
      updateIndexData: [],
      messageItem: undefined,
      pageLoading: false,
      isAllSucess: true,
    };
  },
  computed: {
    ...mapGetters([
      "courseOptions",
      "classOptions",
      "teacherOptions",
      "planObj",
    ]),
  },
  watch: {
    "paramsForm.params.length": {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal);
        if (newVal > oldVal) {
          this.paramsForm.params.forEach((item, index) => {
            this.$watch(
              () => item.courseId,
              function (val, old) {
                console.log(index);
                this.updateIndexData.push(index);
              }
            );
            this.$watch(
              () => item.classId,
              function (val, old) {
                console.log(index);
                this.updateIndexData.push(index);
              }
            );
            this.$watch(
              () => item.courseCount,
              function (val, old) {
                console.log(index);
                this.updateIndexData.push(index);
              }
            );
            this.$watch(
              () => item.classroomId,
              function (val, old) {
                console.log(index);
                this.updateIndexData.push(index);
              }
            );
          });
        }
      },
    },
  },
  async created() {
    console.log(this.planObj);
    let obj = JSON.parse(this.planObj);
    this.schoolCode = obj.schoolCode;
    this.courseCount = Number(obj.courseCount);
    await this.getList();
  },
  mounted() {
    this.timer = setInterval(this.autoSave, 30000); // 执行定时器
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    // 自动保存
    autoSave(saveSuccessfully, saveFailed) {
      this.isAllSucess = true;
      if (this.updateIndexData.length > 0) {
        this.messageItem = this.$message({
          message: this.$t("el.common.Saving"),
          duration: 0,
        });
        setTimeout(() => {
          this.timeOutSaveData(saveSuccessfully, saveFailed);
        }, 1000);
      }
    },
    async timeOutSaveData(saveSuccessfully, saveFailed) {
      let newIndexData = [...new Set(this.updateIndexData)];
      await this.saveAllData("auto", newIndexData);
      if (this.isAllSucess) {
        this.messageItem.message =
          saveSuccessfully || this.$t("el.common.saveSuccessfully");
      } else {
        this.messageItem.type = "error";
        this.messageItem.message =
          saveFailed || this.$t("el.common.saveFailed");
      }
      setTimeout(() => {
        this.messageItem.close();
      }, 1000);
    },
    // 校验班级
    checkClass(rule, value, callback) {
      console.log(rule.field.split("params.")[1].split(".")[0]);
      let index = rule.field.split("params.")[1].split(".")[0];
      if (!this.paramsForm.params[index].courseId) {
        callback(new Error(this.$t("el.planCourse.pleaseSelectCourse")));
      } else if (!value) {
        callback(new Error(this.$t("el.courseClassManage.classRules")));
      } else {
        callback();
      }
    },
    // 校验授课老师
    chackStaffCode(rule, value, callback) {
      console.log(rule.field.split("params.")[1].split(".")[0]);
      let index = rule.field.split("params.")[1].split(".")[0];
      if (!this.paramsForm.params[index].courseId) {
        callback(new Error(this.$t("el.planCourse.pleaseSelectCourse")));
      } else if (!value) {
        callback(new Error(this.$t("el.planCourse.selectTeacher")));
      } else {
        callback();
      }
    },
    // 获取授课计划列表
    async getList() {
      this.tableLoading = true;
      await this.$store
        .dispatch("getPlanTeachingList", this.planId)
        .then((res) => {
          console.log(res);
          let datas = res.data || [];
          if (datas.length > 0) {
            datas.forEach((item) => {
              if (item.courseId === undefined) {
                this.$set(item, "courseId", null);
              }
              if (item.classId === undefined) {
                this.$set(item, "classId", null);
              }
              if (item.classroomId === undefined) {
                this.$set(item, "classroomId", null);
              }
              if (item.courseCount === undefined) {
                this.$set(item, "courseCount", undefined);
              }
              this.$set(item, "isEdit", false);
              this.$set(item, "classOptions", []);
              this.$set(item, "teacherOptions", []);
            });
          }
          this.paramsForm.params = datas;
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    // 点击编辑，所有的表单变为可编辑状态
    async editData(index) {
      console.log(index);
      console.log(this.$refs.tRef);
      if (this.paramsForm.params[index].courseId) {
        await this.$refs.tRef.getClassData(
          this.paramsForm.params[index].courseId,
          index
        );
      }
      // if (
      //   this.paramsForm.params[index].subjectId &&
      //   this.paramsForm.params[index].gradeId
      // ) {
      await this.$refs.tRef.getTeacherData({
        courseId: this.paramsForm.params[index].courseId,
        subjectId: this.paramsForm.params[index].subjectId,
        gradeId: this.paramsForm.params[index].gradeId,
        index,
      });
      // }
      this.$set(this.paramsForm.params[index], "isEdit", true);
    },
    // 点击保存，表单校验，如果通过，调用接口，将数据变为只读状态
    async saveData(index) {
      console.log(index);
      console.log(this.paramsForm.params[index]);
      let form = this.paramsForm.params[index];
      // this.$set(this.paramsForm.params[index], "isEdit", false);
      let data = {
        classId: form.classId,
        classroomId: form.classroomId,
        courseCount: form.courseCount,
        courseId: form.courseId,
        gradeId: form.gradeId,
        id: form.id,
        planId: Number(this.planId),
        schoolCode: form.schoolCode,
        staffCode: form.staffCode,
        subjectId: form.subjectId,
      };
      await this.$store.dispatch("updatePlanData", data).then((res) => {
        if (res.success) {
          if (res.data.length > 0) {
            let newData = res.data[0];
            for (let key in newData) {
              this.$set(this.paramsForm.params[index], `${key}`, newData[key]);
            }
          }
          this.$set(this.paramsForm.params[index], "isEdit", false);
        }
      });
    },
    // 自动保存计划接口
    async saveAutoData(index) {
      console.log(index);
      console.log(this.paramsForm.params[index]);
      let form = this.paramsForm.params[index];
      // this.$set(this.paramsForm.params[index], "isEdit", false);
      let data = {
        courseId: form.courseId,
        gradeId: form.gradeId,
        id: form.id,
        planId: Number(this.planId),
        schoolCode: form.schoolCode,
        staffCode: form.staffCode,
        subjectId: form.subjectId,
      };
      if (form.courseId) {
        data.courseId = form.courseId;
      }
      if (form.classId) {
        data.classId = form.classId;
      }
      if (form.classroomId) {
        data.classroomId = form.classroomId;
      }
      if (form.courseCount) {
        data.courseCount = form.courseCount;
      }
      await this.$store
        .dispatch("autoUpdatePlanData", data)
        .then((res) => {
          if (res.success) {
            if (res.data.length > 0) {
              let newData = res.data[0];
              for (let key in newData) {
                if (newData.courseId === undefined) {
                  this.$set(this.paramsForm.params[index], "courseId", null);
                }
                if (newData.classId === undefined) {
                  this.$set(this.paramsForm.params[index], "classId", null);
                }
                if (newData.classroomId === undefined) {
                  this.$set(this.paramsForm.params[index], "classroomId", null);
                }
                if (newData.courseCount === undefined) {
                  this.$set(
                    this.paramsForm.params[index],
                    "courseCount",
                    undefined
                  );
                }
                this.$set(
                  this.paramsForm.params[index],
                  `${key}`,
                  newData[key]
                );
                setTimeout(() => {
                  this.updateIndexData = [];
                }, 1000);
              }
            }
          }
        })
        .catch(() => {
          this.isAllSucess = false;
        });
    },
    // 删除某一项授课计划
    async deletePlanData(data) {
      this.$confirm(
        data.courseName
          ? this.$t("el.planCourse.confirmDelete") + `${data.courseName}?`
          : this.$t("el.planCourse.confirmDeleteCourse"),
        this.$t("el.common.prompt"),
        {
          confirmButtonText: this.$t("el.common.confirm"),
          cancelButtonText: this.$t("el.common.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.deleteData(data);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("el.courseClassManage.cancelRemove"),
          });
        });
    },
    // 调用接口删除数据
    async deleteData(data) {
      await this.$store
        .dispatch("deletePlanData", data.id)
        .then((res) => {
          if (res.success) {
            this.$message({
              type: "success",
              message: this.$t("el.classManage.deleteSuccess"),
            });
            this.paramsForm.params.splice(data.index, 1);
            // this.$refs.tRef.setCourseDisabled();
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
          this.$refs.tRef.clearAllValidate();
        })
        .catch(() => {
          this.$refs.tRef.clearAllValidate();
        });
    },
    // 选择某一个课程之后，判断基础设置模块此课程是否已设置课程老师和教室，如果有 则自动填充
    autoGetStaffAndClassroom(data) {
      console.log(data);
      this.tableVisible = false;
      if (data.staffCode) {
        this.$set(
          this.paramsForm.params[data.index],
          "staffCode",
          data.staffCode
        );
      } else {
        this.$set(this.paramsForm.params[data.index], "staffCode", null);
      }
      if (data.classroomId) {
        this.$set(
          this.paramsForm.params[data.index],
          "classroomId",
          data.classroomId
        );
      } else {
        this.$set(this.paramsForm.params[data.index], "classroomId", null);
      }
      this.$nextTick(() => {
        this.tableVisible = true;
      });
    },
    // 选择课程之后，数据添加科目id和年级id
    autoSetSubjectAndGradeId(data) {
      console.log(data);
      this.tableVisible = false;
      if (data.subjectId) {
        this.$set(
          this.paramsForm.params[data.index],
          "subjectId",
          data.subjectId
        );
      } else {
        this.$set(this.paramsForm.params[data.index], "subjectId", null);
      }
      if (data.gradeId) {
        this.$set(this.paramsForm.params[data.index], "gradeId", data.gradeId);
      } else {
        this.$set(this.paramsForm.params[data.index], "gradeId", null);
      }
      this.$nextTick(() => {
        this.tableVisible = true;
      });
    },
    // 更改课程之后，班级数据重置
    changeCourseReset(index) {
      this.$set(this.paramsForm.params[index], "classId", null);
    },
    // 更改某一行的班级下拉框数据
    changeClassOptions(data) {
      this.$set(this.paramsForm.params[data.index], "classOptions", data.list);
      console.log(this.paramsForm.params);
    },
    // 更改某一行的授课老师下拉框数据
    changeTeacherOptions(data) {
      this.$set(
        this.paramsForm.params[data.index],
        "teacherOptions",
        data.list
      );
      console.log(this.paramsForm.params);
    },
    // 新增一行
    async addLine() {
      let newForm = {};
      let data = {
        planId: this.planId,
      };
      await this.$store
        .dispatch("addNewPlanData", data)
        .then((res) => {
          console.log(res);
          newForm = {
            id: res.data,
            courseId: null,
            classId: null,
            classroomId: null,
            courseCount: undefined,
            className: "",
            classroomName: "",
            courseName: "",
            gradeName: "",
            planId: this.planId,
            schoolCode: this.schoolCode,
            staffCode: "",
            staffName: "",
            subjectName: "",
            classOptions: [],
            teacherOptions: [],
            isEdit: true,
          };
          this.$nextTick(() => {
            // this.$refs.tRef.setCourseDisabled();
            this.paramsForm.params.unshift(newForm);
            this.$refs.tRef.clearAllValidate();
          });
        })
        .catch(() => {
          this.$refs.tRef.clearAllValidate();
        });
    },
    prev() {
      this.$emit("changeStep", 0);
    },
    // 点击生成课表 调用手动保存 遍历data数据
    async saveAllData(type, lengthData) {
      for (let index = 0; index < this.paramsForm.params.length; index++) {
        if (!type) {
          await this.saveData(index);
        } else {
          if (lengthData.indexOf(index) > -1) {
            await this.saveAutoData(index);
          }
        }
      }
    },
    // 生成课表
    async next() {
      if (this.paramsForm.params.length === 0) {
        this.$message.error(this.$t("el.planCourse.addTeachingPlan"));
      } else {
        let result = true;
        for (let index = 0; index < this.paramsForm.params.length; index++) {
          let isResult = this.$refs.tRef.validateField(index);
          result = isResult * result;
        }
        if (result) {
          this.pageLoading = true;
          await this.saveAllData();
          await this.$store
            .dispatch("createTeachingSchedule", this.planId)
            .then((res) => {
              if (res.success) {
                this.pageLoading = false;
                this.$emit("changeStep", 2);
              } else {
                this.$message({
                  type: "error",
                  message: res.message,
                });
              }
            })
            .catch(() => {
              this.pageLoading = false;
            });
        } else {
          this.$message.error(this.$t("el.common.CheckFormCorrectly"));
          this.pageLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.teaching-plan {
  position: relative;
  &-add {
    font-size: 13px;
    color: #6049ff;
    margin: 0;
    padding: 20px 0 10px;
    i {
      font-size: 12px;
    }
    label {
      cursor: pointer;
    }
  }
  &-operate-btns {
    padding-top: 150px;
    text-align: center;
    .is-plain {
      color: #6049ff;
      background: #fff;
      border-color: #6049ff;
    }
  }
  .save-tips {
    color: #999;
    font-size: 13px;
    font-weight: normal;
  }
  .loading-tips {
    position: absolute;
    left: calc(50% - 60px);
    font-size: 15px;
    bottom: 120px;
    z-index: 99999;
    color: rgba(0, 0, 0, 0.45);
    margin: 0;
  }
  /deep/ .el-loading-spinner .path {
    stroke: #6049ff;
  }
}
</style>
