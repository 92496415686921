<template>
  <div class="t-form">
    <el-form :model="paramsForm" ref="rForm" :rules="paramsForm.paramsRules">
      <el-table
        v-if="tableVisible"
        :show-overflow-tooltip="true"
        border
        fit
        :data="paramsForm.params"
        style="width: 100%"
      >
        <el-table-column align="center" width="180">
          <template slot="header">
            <span>
              <label class="r-import">*</label>
              {{ $t("el.planCourse.CourseName") }}
            </span>
          </template>
          <template slot-scope="scope">
            <div class="single-line" v-show="!scope.row.isEdit">
              <tooltip-over
                :content="scope.row.courseName"
                class="class-menu-name"
              ></tooltip-over>
            </div>
            <div v-if="scope.row.isEdit">
              <el-form-item
                :prop="'params.' + scope.$index + '.courseId'"
                :rules="paramsForm.paramsRules.courseId"
              >
                <!-- :remote-method="getCourseList"
               remote
              reserve-keyword -->
                <el-select
                  filterable
                  v-model="scope.row.courseId"
                  @focus="getCourseList('')"
                  :placeholder="$t('el.planCourse.selectCourse')"
                  :loading="courseLoading"
                  @change="
                    changeCourse(scope.row.courseId, scope.row, scope.$index)
                  "
                >
                  <el-option
                    v-for="item in planCourseOptions"
                    :value="item.id"
                    :label="item.courseName"
                    :key="item.id"
                    :disabled="item.disabled"
                  >
                    <!-- :disabled="checkCourseDisabled(item.id)" -->
                    {{ item.courseName }}
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot="header">
            <span> {{ $t("el.schoolResourceManage.grade") }} </span>
          </template>
          <template slot-scope="scope">
            <!-- <p class="single-line" v-show="!scope.row.isEdit"> -->
            {{ scope.row.gradeName }}
            <!-- <el-tooltip
              :disabled="computedIsDisabled(scope.row.gradeName)"
              :content="scope.row.gradeName"
              placement="top-start"
            >
              <span style="margin-left: 10px">{{ scope.row.gradeName }}</span>
            </el-tooltip> -->
            <!-- </p> -->
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot="header">
            <span> {{ $t("el.schoolResourceManage.subjects") }} </span>
          </template>
          <template slot-scope="scope">
            <tooltip-over
              :content="scope.row.subjectName"
              class="class-menu-name"
            ></tooltip-over>
          </template>
        </el-table-column>
        <el-table-column width="180" align="center">
          <template slot="header">
            <span> {{ $t("el.planCourse.instructor") }} </span>
          </template>
          <template slot-scope="scope">
            <div class="single-line" v-show="!scope.row.isEdit">
              <tooltip-over
                :content="scope.row.staffName"
                class="class-menu-name"
              ></tooltip-over>
            </div>
            <p v-show="scope.row.isEdit">
              <el-form-item :prop="'params.' + scope.$index + '.staffCode'">
                <!-- :remote-method="
                    (val) => {
                      getTeacherList(val, scope.row);
                    }
                  "
                  remote
                  reserve-keyword -->
                <el-select
                  v-if="scope.row.isEdit"
                  :loading="teacherLoading"
                  filterable
                  v-model="scope.row.staffCode"
                  @focus="getTeacherList('', scope.row, scope.$index)"
                  @change="changeTeacher(scope.row.staffCode, scope.row)"
                  :placeholder="$t('el.planCourse.selectTeacher')"
                >
                  <el-option
                    v-for="item in planTeacherOptions"
                    :value="item.staffCode"
                    :key="item.staffCode"
                    :label="item.staffName"
                  >
                    {{ item.staffName }}
                  </el-option>
                </el-select>
              </el-form-item>
            </p>
          </template>
        </el-table-column>
        <el-table-column width="180" align="center">
          <template slot="header">
            <span> {{ $t("el.planCourse.classroom") }} </span>
          </template>
          <template slot-scope="scope">
            <p class="single-line" v-show="!scope.row.isEdit">
              {{ scope.row.classroomName }}
            </p>
            <p v-show="scope.row.isEdit">
              <el-form-item :prop="'params.' + scope.$index + '.classroomId'">
                <!--
                  reserve-keyword
                  :remote-method="
                    (val) => {
                      getClassroomList(val, scope.row);
                    }
                  "
                   remote -->
                <el-select
                  filterable
                  @focus="getClassroomList('', scope.row, scope.$index)"
                  :loading="classroomLoading"
                  @change="changeClassroom(scope.row.classroomId, scope.row)"
                  v-model="scope.row.classroomId"
                  :placeholder="$t('el.planCourse.chooseClassroom')"
                >
                  <el-option
                    v-for="item in planClassroomOptions"
                    :value="item.id"
                    :label="item.name"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </el-option>
                </el-select>
              </el-form-item>
            </p>
          </template>
        </el-table-column>
        <el-table-column
          width="145"
          align="center"
          :label="$t('el.common.operate')"
        >
          <template slot-scope="scope">
            <el-button
              class="t-form-operate-btn"
              v-show="!scope.row.isEdit"
              type="primary"
              size="small"
              @click="editData(scope.$index, scope.row)"
              >{{ $t("el.common.edit") }}</el-button
            >
            <el-button
              class="t-form-operate-btn"
              v-show="scope.row.isEdit"
              @click="savePlan(scope.$index)"
              type="primary"
              size="small"
              >{{ $t("el.common.save") }}</el-button
            >
            <el-button
              class="t-form-operate-btn"
              type="primary"
              size="small"
              @click="deleteData(scope.$index)"
              >{{ $t("el.common.delete") }}</el-button
            >
          </template>
        </el-table-column>
        <template slot="empty">
          <div class="no-data-pic">
            <img
              src="@/assets/images/pic_empty_people@2x.png"
              alt=""
              style="width: 270px"
            />
          </div>
          <div class="no-data-text">{{ $t("el.common.noData") }}</div>
        </template>
      </el-table>
    </el-form>
  </div>
</template>

<script>
import {
  getPlanCourseList,
  getPlanTeacherList,
  getClassroomList,
} from "@/api/manage/basic-setting";
import tooltipOver from "@/components/biz-components/tooltipOver/index.vue";
export default {
  name: "wForm",
  components: {
    tooltipOver,
  },
  props: {
    schoolCode: {
      type: String,
      default: () => "",
    },
    canSave: {
      type: Boolean,
      default: () => false,
    },
    courseOptions: {
      type: Array,
      default: () => [],
    },
    classOptions: {
      type: Array,
      default: () => [],
    },
    teacherOptions: {
      type: Array,
      default: () => [],
    },
    classroomOptions: {
      type: Array,
      default: () => [],
    },
    paramsForm: {
      type: Object,
      default: () => ({}),
    },
    tableVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      courseLoading: false,
      teacherLoading: false,
      classroomLoading: false,
      planCourseOptions: [],
      planTeacherOptions: [],
      planClassroomOptions: [],
      collectCourseIds: [],
    };
  },
  created() {
    // this.getCourseList();
  },
  watch: {
    "$i18n.locale": {
      handler(val, old) {
        this.$refs["rForm"] && this.$refs["rForm"].clearValidate();
      },
      immediate: true,
      deep: true,
    },
    "paramsForm.params": function (newVal, oldVal) {
      this.collectCourseIds = [];
      newVal.forEach((item) => {
        if (item.courseId !== null) {
          this.collectCourseIds.push(item.courseId);
          console.log(this.collectCourseIds, "IDS");
        }
      });
    },
    // planCourseOptions(val) {
    //   let newCourseOptions = val;
    //   if (newCourseOptions) {
    //     newCourseOptions.forEach(item => {
    //       if(this.collectCourseIds.indexOf(item.id)===-1){
    //         this.$set(item, 'disabled', false);
    //       }else{
    //         this.$set(item, 'disabled', true);
    //       }
    //       console.log(item.id)
    //       console.log(this.collectCourseIds)
    //       // this.collectCourseIds.forEach(ele => {
    //       //   if (item.id === ele) {
    //       //     console.log(item.id,ele,'777777777777777777777777777777')
    //       //     this.$set(item, 'disabled', true);
    //       //   } else {
    //       //     this.$set(item, 'disabled', false);
    //       //   }
    //       // });
    //     });
    //     console.log(newCourseOptions);
    //     console.log(this.paramsForm.params,'787878787878');
    //   }
    // },
  },
  methods: {
    // 获取课程列表
    getCourseList(val) {
      this.courseLoading = true;
      let params = { courseName: val };
      getPlanCourseList(params).then((res) => {
        let courseList = res.data;
        courseList.forEach((item) => {
          if (this.collectCourseIds.indexOf(item.id) !== -1) {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
        });
        this.planCourseOptions = courseList;
        this.courseLoading = false;
      });
    },
    // 监听课程更改，改变相应的数据
    changeCourse(id, row, index) {
      this.planCourseOptions.forEach((courseItem) => {
        if (courseItem.id === id) {
          row.subjectName = courseItem.subjectName;
          row.subjectId = courseItem.subjectId;
          row.courseName = courseItem.courseName;
          row.gradeName = courseItem.gradeName;
          row.gradeId = courseItem.gradeId;
          row.staffCode = null;
          row.teacherName = null;
          row.classroomId = null;
          row.classroomId = null;
        }
      });
      this.$emit("changeCanSave", true);
      // this.collectCourseIds.splice(this.collectCourseIds.indexOf(id),1,id)
      this.getCollectCourseIds();
      this.getCourseList();
    },
    // 获取当前已经有的ID数据
    getCollectCourseIds() {
      this.collectCourseIds = [];
      let courseList = [...this.paramsForm.params];
      courseList.forEach((item) => {
        if (item.courseId != null) {
          this.collectCourseIds.push(item.courseId);
        }
      });
    },
    // 获取焦点时候获取当前条件下老师列表
    getTeacherList(val, item, index) {
      let validate = this.validateField(index);
      if (validate) {
        this.planTeacherOptions = [];
        this.teacherLoading = true;
        let params = {
          subjectId: item.subjectId,
          gradeId: item.gradeId,
          schoolCode: this.schoolCode,
          staffName: val,
          courseId: item.courseId,
        };
        getPlanTeacherList(params).then((res) => {
          this.planTeacherOptions = res.data;
          this.teacherLoading = false;
        });
      } else {
        this.planTeacherOptions = [];
      }
      // if(item.courseId===null){
      //   this.$message.error("请先选择课程");
      // }else{
      //   let params = {
      //   subjectId: item.subjectId,
      //   gradeId: item.gradeId,
      //   schoolCode: this.schoolCode,
      //   staffName: val,
      // };
      // getPlanTeacherList(params).then(res=>{
      //   this.planTeacherOptions = res.data;
      //   this.teacherLoading=false
      // })
      // }
    },
    // 改变老师之后在对象中加入老师名字
    changeTeacher(staffCode, row) {
      this.planTeacherOptions.forEach((staff) => {
        if (staff.staffCode === staffCode) {
          row.staffName = staff.staffName;
        }
      });
      this.$emit("changeCanSave", true);
    },
    // 获取教室列表
    getClassroomList(val, item, index) {
      let validate = this.validateField(index);
      if (validate) {
        this.planClassroomOptions = [];
        this.classroomLoading = true;
        let params = {
          schoolCode: this.schoolCode,
          name: val,
          enable: 1,
          offset: 0,
          limit: 999,
        };
        getClassroomList(params).then((res) => {
          this.planClassroomOptions = res.data.records;
          this.classroomLoading = false;
        });
      } else {
        this.planClassroomOptions = [];
      }
    },
    // 改变教室之后在对象中加入教室名字
    changeClassroom(classroomId, row) {
      this.planClassroomOptions.forEach((item) => {
        if (item.id === classroomId) {
          row.classroomName = item.name;
        }
      });
      this.$emit("changeCanSave", true);
    },
    // 校验 行内表单
    validateField(index) {
      let result = true;
      this.$refs.rForm.validateField(
        "params." + index + ".courseId",
        (valid) => {
          if (valid !== "") {
            console.log(456464596);
            result = false;
          } else {
            console.log(11111111);
            result = true;
          }
        }
      );
      // for (const item of this.$refs.rForm.fields) {
      //   if (~~item.prop.split(".")[1] === index) {
      //     this.$refs.rForm.validateField(item.prop, (valid) => {
      //       console.log(valid)
      //       if (valid !== "") {
      //         result = false;
      //       }else{
      //         result = true;
      //       }
      //     });
      //   }
      // }
      // console.log()
      // this.$refs.rForm.validateField('params.'+index+'.courseId',valid=>{
      //   console.log(valid)
      // })
      return result;
    },
    // 点击保存
    savePlan(index) {
      let isvalidate = this.validateField(index);
      // 如果当前行 保存 通过校验，调用接口
      if (isvalidate) {
        this.$emit("saveData", index);
      }
    },
    // 编辑
    async editData(index, item) {
      console.log(index, item);
      this.getTeacherList("", item);
      this.getCourseList("");
      this.getClassroomList("", item);
      this.$emit("editData", index);
    },
    // 删除
    deleteData(index) {
      console.log(index);
      this.$emit("deleteData", index);
    },
  },
};
</script>

<style lang="less" scoped>
.t-form {
  &-operate-btn {
    color: #6049ff;
    background: none;
    box-shadow: none;
    border: none;
    padding: 9px 0;
  }
  .r-import {
    color: #f56c6c;
    margin-right: 4px;
    vertical-align: middle;
  }
  .el-button + .el-button {
    margin: 0;
  }
  .el-button {
    padding: 0 10px;
  }
  /deep/ .el-table--border {
    color: #282828;
    font-size: 14px;
    th {
      background: #f5f6f7;
      padding: 10px 0;
      color: #282828;
      font-weight: normal;
    }
    .el-form-item__error {
      padding-top: 0;
      width: 100%;
    }
    .el-form-item__content {
      line-height: 30px;
    }
    .cell {
      overflow: unset;
      > p {
        margin: 0;
        height: 30px;
        line-height: 30px;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-input__inner {
        // border: none;
        line-height: 1px !important;
        height: 30px;
      }
      .el-input__icon {
        line-height: 30px;
      }
      .el-input-number {
        line-height: 30px;
        width: 100%;
        .el-input__inner {
          padding: 0;
        }
      }
      .el-input-number__decrease,
      .el-input-number__increase {
        display: none;
      }
    }
  }
  /deep/ input::-webkit-outer-spin-button,
  /deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  /deep/ input[type="number"] {
    -moz-appearance: textfield !important;
  }
  /deep/ input::-ms-input-placeholder {
    text-align: center;
  }
  /deep/ input::-webkit-input-placeholder {
    text-align: center;
  }
  .no-data-pic {
    font-size: 0;
    padding-top: 10px;
    line-height: 0;
  }
  .no-data-text {
    color: rgba(100, 108, 149, 0.25);
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-top: -20px;
  }
}
</style>
