<template>
  <div v-loading="allLoading" class="questionnaire-manage">
    <xm-table
      ref="xmTable"
      :fatch-method="fatchMethod"
      :form-items="formItems"
      :columns="columns"
      :formOptions="{
        hiddenDefaultFormBtn: true,
      }"
      :autoLoad="false"
      :row-key="getRowKeys"
      noEmptyText="el.common.noData"
      @changeQueryParams="changeQueryParams"
      @reset-form="resetFormCallback"
    >
      <template slot="formBtn">
        <el-button
          type="primary"
          size="small"
          class="teachManage-tool-btnicon"
          @click="query()"
          >{{ $t("el.common.search") }}</el-button
        >
        <el-button
          size="small"
          class="teachManage-tool-btnicon"
          @click="resetForm()"
          >{{ $t("el.common.reset") }}</el-button
        >
        <el-button type="primary" size="small" @click="add()">{{
          $t("el.common.add")
        }}</el-button>
      </template>
      <template slot="otherHandle" slot-scope="{ row }">
        <el-button type="text" @click="handleOperateView(row)">{{
          $t("el.planCourse.viewResults")
        }}</el-button>
        <el-button type="text" @click="handleOperateEdit(row)">{{
          $t("el.common.edit")
        }}</el-button>
        <el-button type="text" @click="handleOperateDelete(row)">{{
          $t("el.common.delete")
        }}</el-button>
      </template>
    </xm-table>
    <c-dialog
      v-if="dialogVisible"
      ref="dialogQuestionnaireRef"
      :formItems="classFormItems"
      :formOptions="dialogFormOptions"
      :visible="dialogVisible"
      :optionsData="optionsData"
      @submit-form="submitForm"
      @close-dialog="closeClassDialog"
    >
    </c-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
  getQuestionnaireList,
  deleteQuestionnaireById,
  addQuestionnaire,
  updateQuestionnaire,
  getQuestionnaireGrade,
  questionnaireCanDelete,
} from "@/api/manage/questionnaire-manage";
import CDialog from "@/components/biz-components/CDialog/index.vue";
import { getschoolYearListTwo } from "@/api/manage/select-course";

export default {
  components: {
    CDialog,
  },
  props: {},
  data() {
    return {
      allLoading: false,
      // 学校学部下拉数据
      schoolList: [],
      // 状态下拉数据
      statusOptions: [
        { label: this.$t("el.planCourse.notStart"), value: 2 }, // 未开始
        { label: this.$t("el.planCourse.inProgress"), value: 0 },
        { label: this.$t("el.planCourse.ended"), value: 1 },
      ],
      dialogVisible: false, // 编辑新增弹窗是否展示
      dialogType: "add", // 弹窗类型
      dialogData: {}, // 弹窗表单数据
      formOptions: {},
      optionsData: {}, // 弹框配置
      isSelectDefault: true, // 是否默认选择学校/学部
      nowSchoolCode: "", // 当前学校/学部
      gradeOptions: [],
      twoYearSchool: [],
      currentYearList: [], // 当前学年 List
    };
  },
  computed: {
    ...mapState({
      // 学年下拉数据
      schoolYearOptions(state) {
        return state.dict.SCHOOL_YEAR.map((item) => ({
          label: item.dictionaryText,
          value: item.dictionaryKey,
        }));
      },
      // 学期下拉数据
      termOptions(state) {
        return state.dict.TERM.map((item) => ({
          label: item.dictionaryText,
          value: item.dictionaryKey,
        }));
      },
      tenantId: (state) => state.schoolCode,
    }),
    ...mapGetters(["attendSchoolList"]),
    // 定义查询表单
    formItems() {
      return [
        {
          itemType: "input",
          prop: "questionnaireName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.questionnaireName"),
          clearable: true,
        },
        // 学年
        {
          itemType: "select",
          prop: "questionnaireYear",
          size: "small",
          options: this.schoolYearOptions,
          "popper-append-to-body": false,
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.academicYear"),
          clearable: true,
        },
        // 学期
        {
          itemType: "select",
          prop: "questionnaireTerm",
          size: "small",
          options: this.termOptions,
          "popper-append-to-body": false,
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.courseList.semester"),
          clearable: true,
        },
        // 状态
        {
          itemType: "select",
          prop: "questionnaireStatus",
          size: "small",
          options: this.statusOptions,
          "popper-append-to-body": false,
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.state"),
          clearable: true,
        },
        // 开放时间
        {
          itemType: "daterange",
          prop: "_releaseDate",
          prop1: "questionnaireBeginTime",
          prop2: "questionnaireEndTime",
          size: "small",
          "start-placeholder": this.$t("el.planCourse.releaseDate"),
          "end-placeholder": this.$t("el.planCourse.deadline"),
          label: this.$t("el.planCourse.releaseDate"),
          "popper-append-to-body": false,
          clearable: true,
          "value-format": "yyyy-MM-dd",
        },
      ];
    },
    // 定义列
    columns() {
      return [
        {
          label: this.$t("el.planCourse.questionnaireName"),
          prop: "questionnaireName",
        },
        {
          label: this.$t("el.planCourse.academicYear"),
          prop: "questionnaireYearName",
        },
        {
          label: this.$t("el.courseList.semester"),
          prop: "questionnaireTermName",
        },
        {
          label: this.$t("el.planCourse.releaseRange"),
          prop: "gradeIdNames",
        },
        {
          label: this.$t("el.planCourse.releaseTime"),
          prop: "questionnaireBeginTime",
        },
        {
          label: this.$t("el.planCourse.Deadline"),
          prop: "questionnaireEndTime",
        },
        {
          label: this.$t("el.planCourse.state"),
          prop: "questionnaireStatus",
          formatter: (row, column, value) => {
            const t = this.statusOptions.find((i) => i.value === value);
            if (t) {
              return t.label;
            }
            return "";
          },
        },
        {
          label: this.$t("el.common.operate"),
          slotName: "otherHandle",
          width: "200px",
          fix: "right",
        },
      ];
    },
    // 新增编辑弹窗中的表单配置项
    classFormItems() {
      return [
        {
          itemType: "input",
          prop: "questionnaireName",
          label: this.$t("el.planCourse.questionnaireName"),
          placeholder: this.$t("el.common.pleaseEnter"),
          labelWidth: this.$t("el.common.width120_240"),
          clearable: true,
          itemStyle: "width:220px;",
          maxlength: 20,
          // showWordLimit: true,
          formStyle: "width:100%;padding-left:60px;box-sizing: border-box;",
        },
        {
          itemType: "selectMulti",
          prop: "academicYearAndSemester",
          label: this.$t("el.planCourse.academicYearAndSemester"),
          labelWidth: this.$t("el.common.width120_240"),
          clearable: true,
          itemStyle: "width:160px;",
          maxlength: 10,
          formStyle: "width:100%;padding-left:60px;box-sizing: border-box;",
          multi: [
            {
              prop: "questionnaireYear",
              placeholder: this.$t("el.planCourse.academicYearSelect"),
              options: [...this.currentYearList, ...this.twoYearSchool],
            },
            {
              prop: "questionnaireTerm",
              placeholder: this.$t("el.planCourse.semesterSelect"),
              options: [...this.termOptions],
            },
          ],
        },
        {
          itemType: "daterange",
          prop: "_questionnaireTime",
          prop1: "questionnaireBeginTime",
          prop2: "questionnaireEndTime",
          "start-placeholder": this.$t("el.planCourse.releaseDate"),
          "end-placeholder": this.$t("el.planCourse.deadline"),
          label: this.$t("el.planCourse.releaseTime"),
          labelWidth: this.$t("el.common.width120_240"),
          itemStyle: "width:220px;",
          formStyle: "width:100%;padding-left:60px;box-sizing: border-box;",
          "value-format": "yyyy-MM-dd",
          pickerOptions: {
            disabledDate(time) {
              const currentDate = new Date();
              currentDate.setHours(0, 0, 0, 0);
              return time.getTime() < currentDate.getTime(); // 可以选择当天
            },
          },
        },
        {
          itemType: "select",
          prop: "_gradeIds",
          label: this.$t("el.planCourse.releaseRange"),
          labelWidth: this.$t("el.common.width120_240"),
          itemStyle: "width:220px;",
          formStyle: "width:100%;padding-left:60px;box-sizing: border-box;",
          multiple: true,
          options: this.gradeOptions,
          disabled: this.dialogType === "edit",
        },
      ];
    },
    // 新增编辑弹窗表单校验
    newFormOptions() {
      return {
        // 班级弹框配置
        rules: {
          questionnaireName: [
            {
              required: true,
              message: this.$t("el.planCourse.enterQuestionnaireName"),
              trigger: "blur",
            },
            {
              min: 1,
              max: 20,
              message: this.$t("el.planCourse.ContainsCharactersCustom", [20]),
              trigger: "blur",
            },
            {
              validator: (rule, value, callback) => {
                if (value) {
                  if (value.trim() === "") {
                    callback(
                      new Error(this.$t("el.planCourse.enterQuestionnaireName"))
                    );
                  }
                }
                callback();
              },
              trigger: "blur",
            },
          ],
          academicYearAndSemester: [
            {
              required: true,
              trigger: "change",
              validator: (rule, value, callback) => {
                if (value) {
                  if (!value.questionnaireYear) {
                    callback(
                      new Error(this.$t("el.planCourse.academicYearSelect"))
                    );
                  } else if (!value.questionnaireTerm) {
                    callback(
                      new Error(this.$t("el.planCourse.semesterSelect"))
                    );
                  } else {
                    callback();
                  }
                } else {
                  callback(
                    new Error(
                      this.$t("el.planCourse.academicYearAndSemesterSelect")
                    )
                  );
                }
              },
            },
          ],
          _questionnaireTime: [
            {
              required: true,
              message: this.$t("el.planCourse.selectReleaseTime"),
              trigger: "blur",
            },
          ],
          _gradeIds: [
            {
              required: true,
              message: this.$t("el.planCourse.selectReleaseRange"),
              trigger: "blur",
            },
          ],
        },
      };
    },
    dialogFormOptions() {
      return this.formOptions;
    },
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    // 获取列表数据的请求方法
    fatchMethod(params) {
      return getQuestionnaireList(params)
        .then((result) => {
          return result;
        })
        .catch(() => {
          return null;
        });
    },
    // 查看结果
    handleOperateView(row) {
      this.$router.push({
        path: "/planCourseManage/questionnaireResult",
        query: {
          id: row.id,
        },
      });
    },
    // 编辑
    handleOperateEdit(row) {
      this.handleGetGradeOptions();
      this.dialogVisible = true;
      this.dialogType = "edit";
      this.optionsData = {
        dialogTitle: this.$t("el.planCourse.editQuestionnaire"),
        width: this.$i18n.locale === "zh_CN" ? "600px" : "800px",
      };
      this.formOptions = {};

      this.$nextTick(() => {
        this.formOptions = this.newFormOptions;
        let item = this.twoYearSchool.some(
          (item) => row.questionnaireYear === item.value
        );
        if (item) {
          this.currentYearList = [];
        } else {
          this.currentYearList = [
            {
              value: row.questionnaireYear,
              label: row.questionnaireYearName,
            },
          ];
        }

        this.$refs["dialogQuestionnaireRef"].queryParams = {
          id: row.id,
          questionnaireName: row.questionnaireName,
          academicYearAndSemester: {
            questionnaireYear: row.questionnaireYear,
            questionnaireTerm: +row.questionnaireTerm,
          },
          _questionnaireTime: [
            row.questionnaireBeginTime,
            row.questionnaireEndTime,
          ],
          questionnaireBeginTime: row.questionnaireBeginTime,
          questionnaireEndTime: row.questionnaireEndTime,
          _gradeIds: (row.gradeIds || "").split(",").map((i) => +i),
        };
        this.$refs["dialogQuestionnaireRef"].$refs["form"].clearValidate();
      });
    },
    /**
     * 删除
     * 若无人提交问卷或问卷未开始，二次确认后删除
     * 若已有人提交问卷，不允许删除
     */
    async handleOperateDelete(row) {
      const { data } = await questionnaireCanDelete(row.id);
      if (data) {
        // 可删除
        this.$confirm("", this.$t("el.common.deleteConfirm"), {
          center: true,
          customClass: "el-message-box--confirm el-message-box--danger",
          message: this.$createElement(
            "div",
            {
              class: "el-message-box--delete",
            },
            this.$t("el.planCourse.questionnaireDeleteConfirm")
          ),
        }).then(() => {
          deleteQuestionnaireById(row.id).then(() => {
            this.$message.success(this.$t("el.classManage.deleteSuccess"));
            this.query();
          });
        });
      } else {
        this.$message.error(this.$t("el.planCourse.questionnairCanntDelete"));
      }
    },
    // 查询
    query() {
      if (this.$refs["xmTable"]) {
        this.$refs["xmTable"].query();
      }
    },
    // 重置
    resetForm() {
      this.$refs["xmTable"].resetForm();
    },
    resetFormCallback(queryParams) {
      this.setDefaultSchool(queryParams, this.schoolList);
    },
    // 设置默认学校
    setDefaultSchool(queryParams, schoolList) {
      if (schoolList && schoolList.length > 0) {
        this.$set(
          queryParams,
          "questionnaireSchool",
          schoolList[0].questionnaireSchool
        );
      }
    },
    // 添加
    add() {
      this.handleGetGradeOptions();
      this.dialogVisible = true;
      this.dialogType = "add";
      this.optionsData = {
        dialogTitle: this.$t("el.planCourse.addQuestionnaire"),
        width: this.$i18n.locale === "zh_CN" ? "600px" : "800px",
      };
      this.formOptions = {};
      this.$nextTick(() => {
        this.formOptions = this.newFormOptions;
        this.$refs[
          "dialogQuestionnaireRef"
        ].queryParams.academicYearAndSemester = {
          questionnaireYear: "",
          questionnaireTerm: "",
        };
        this.$refs["dialogQuestionnaireRef"].$refs["form"].clearValidate();
      });
    },
    // 新建班级提交
    submitForm(refFormName, dialogForm) {
      if (!this.allLoading) {
        this.allLoading = true;
        refFormName.validate(async (valid) => {
          if (valid) {
            let params = {
              ...dialogForm,
              questionnaireTerm:
                dialogForm.academicYearAndSemester.questionnaireTerm,
              questionnaireYear:
                dialogForm.academicYearAndSemester.questionnaireYear,
              gradeIds: dialogForm._gradeIds.join(","),
            };
            delete params.academicYearAndSemester;
            if (dialogForm.id) {
              // 编辑
              await updateQuestionnaire({ ...params, id: dialogForm.id })
                .then(() => {
                  this.$message.success(
                    this.$t("el.studentManage.editSuccess")
                  );
                  this.closeClassDialog();
                  this.query();
                })
                .finally(() => {
                  this.allLoading = false;
                });
            } else {
              // 新增
              await addQuestionnaire(params)
                .then(() => {
                  this.$message.success(this.$t("el.classManage.addSuccess"));
                  this.closeClassDialog();
                  this.query();
                })
                .finally(() => {
                  this.allLoading = false;
                });
            }
          } else {
            this.allLoading = false;
            return false;
          }
        });
      }
    },
    // 关闭dialog
    closeClassDialog() {
      this.dialogVisible = false;
    },
    // 获取问卷发放范围  学生管理模块内全部年级
    handleGetGradeOptions() {
      getQuestionnaireGrade().then((res) => {
        this.gradeOptions = (res?.data || []).map(({ gradeId, gradeName }) => ({
          label: gradeName,
          value: gradeId,
        }));
      });
    },

    // 获取学校列表
    async getAuthSchoolList() {
      await this.$store.dispatch("getSchoolList");
      this.attendSchoolList.forEach((el) => {
        el.questionnaireSchool = el.schoolCode;
      });
      this.schoolList = this.attendSchoolList;
      if (this.schoolList.length > 0) {
        this.isSelectDefault = true;
        this.nowSchoolCode = this.attendSchoolList[0].questionnaireSchool;
        this.$nextTick(() => {
          this.query();
        });
      }
    },
    changeQueryParams(formData) {
      if (formData.questionnaireSchool) {
        if (formData.questionnaireSchool !== this.nowSchoolCode) {
          this.isSelectDefault = false;
          this.nowSchoolCode = formData.questionnaireSchool;
        }
      }
    },
    // 获取近两年的学年下拉
    getSchoolYearList() {
      getschoolYearListTwo().then(({ status, data }) => {
        if (status === 200) {
          this.twoYearSchool = data.map(({ name, id }) => ({
            label: name,
            value: id,
          }));
        }
      });
    },
  },
  created() {
    // this.getAuthSchoolList();
  },
  mounted() {
    this.getSchoolYearList();
    this.query();
  },
};
</script>

<style lang="less" scoped>
.questionnaire-manage {
  padding: 30px 50px;
  background-color: #fff;
  border-radius: 10px;
  /deep/ .el-button {
    padding: 9px 15px;
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
  /deep/ .el-button--default {
    color: #6049ff;
    background-color: #fff;
    border-color: #6049ff !important;
  }
}

/deep/ .el-dialog {
  text-align: left;
}
/deep/ .cell {
  .el-button {
    padding: 9px 5px;
    & + .el-button {
      margin-left: 0;
    }
  }
}
</style>
