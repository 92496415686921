<template>
  <div
    class="import-tools"
    v-loading.fullscreen.lock="uploadLoading"
    :element-loading-text="elementLoadingText"
    element-loading-background="rgba(255,255,255,0.9)"
  >
    <a target="_blank" :href="downHref">
      <el-button>
        <span class="button-text">{{
          $t("el.infrastructure.downloadTemplate")
        }}</span>
      </el-button>
    </a>
    <el-upload
      class="upload-demo"
      :action="action"
      accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      :show-file-list="false"
      :headers="myHeaders"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :on-progress="handleProgress"
    >
      <el-button plain size="small">{{ $t("el.common.import") }}</el-button>
    </el-upload>
    <import-result
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      :dialog-title="$t('el.planCourse.importResult')"
      :dialog-text-content="dialogTextContent"
      :confirmText="confirmText"
      :footerTip="footerTip"
      :excelResultHref="excelResultHref"
      @handleClose="handleClose"
      @handleConfirm="handleConfirm"
    />
  </div>
</template>

<script>
import { getToken } from "@/core/auth";
import ImportResult from "./import-result.vue";
export default {
  components: {
    ImportResult,
  },
  props: {
    downHref: {
      type: String,
      default: "",
    },
    elementLoadingText: {
      type: String,
      default: "",
    },
    action: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      myHeaders: { Authorization: getToken() },
      uploadLoading: false,
      dialogVisible: false,
      dialogTextContent: {
        type: "",
        text: "",
      },
      confirmText: this.$t("el.planCourse.downloadToView"),
      footerTip: this.$t("el.planCourse.showImportRecord"),
      excelResultHref: "",
    };
  },
  methods: {
    beforeUpload(file) {
      this.uploadLoading = true;
      this.loadingPage = true;
    },
    handleSuccess(response) {
      if (response.success) {
        this.$emit("handleSuccess");
        if (
          response.data.flag !== undefined &&
          response.data.flag === "success"
        ) {
          this.excelResultHref = "";
          this.$message({
            type: "success",
            message: this.$t("el.planCourse.scheduleImportSuccess"),
          });
        } else {
          this.dialogVisible = true;
          this.excelResultHref = response.data.fileUrl;
          this.dialogTextContent.text = response.data.info;
        }
      } else {
        this.$message({
          type: "error",
          message: response.message,
        });
      }
      this.uploadLoading = false;
      this.loadingPage = false;
    },
    handleProgress(event, file, fileList) {
      console.log(event, file, fileList);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleConfirm() {
      this.handleClose();
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.import-tools {
  margin-left: 10px;
  display: flex;
  /deep/ .el-button {
    a {
      color: @themeNewPurple;
    }
  }
  .upload-demo {
    margin-left: 10px;
  }
}
</style>
