<template>
  <div class="view-res-box">
    <div class="back">
      <el-button
        round
        size="small"
        style="border-radius: 16px"
        @click="goback()"
        i
      >
        &lt; {{ $t("el.common.back") }}</el-button
      >
      <span style="margin-left: 10px">
        {{ $t("el.planCourse.selectCourses") }} /
        <span class="gary"> {{ $t("el.planCourse.viewResults") }}</span></span
      >
    </div>
    <xm-table
      ref="xmTable"
      :fatch-method="fatchMethod"
      :form-items="formItems"
      :columns="columns"
      :formOptions="{
        hiddenDefaultFormBtn: true,
      }"
      :tableOptions="{
        height: tableHeight,
      }"
      :noEmptyText="$t('el.common.noData')"
      @changeQueryParams="changeQueryParams"
      @reset-form="resetFormCallback"
    >
      <template slot="formBtn">
        <el-button
          size="small"
          class="teachManage-tool-btnicon"
          @click="resetForm"
          >{{ $t("el.common.reset") }}</el-button
        >
        <el-button
          type="primary"
          size="small"
          class="teachManage-tool-btnicon"
          @click="query"
          >{{ $t("el.common.screening") }}</el-button
        >
      </template>
    </xm-table>
  </div>
</template>

<script>
import {
  getClasslist,
  getCourseCategory,
  getCourseSubject,
  getAllGradeList,
  getResultList,
  getClassListByGradeId,
} from "@/api/manage/select-course.js";
import { Calendar } from "element-ui";
export default {
  name: "ViewResult",
  components: {},
  props: {
    taskId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tableHeight: undefined,
      classDisabled: true,
      ruleForm: {},
      rules: {},
      tableData: [],
      value1: [],
      courseCategoryList: [], // 课程类别下拉数据
      courseSubjectList: [], // 科目下拉数据
      schoolGradeList: [], // 学校内年级列表
      systemGradeList: [], // 系统内年级列表
      classList: [], // 班级列表
    };
  },
  created() {
    this.getCourseSubjectList();
    this.getCourseCategoryList();
    this.getCourseRangeList();
    this.getSystemGradeList();
  },
  computed: {
    formItems() {
      return [
        // 报名时间
        {
          itemType: "daterange",
          prop: "signUpTime",
          size: "small",
          prop1: "startTime",
          prop2: "endTime",
          "popper-append-to-body": false,
          "value-format": "yyyy-MM-dd",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.courseClassManage.createTime"),
          clearable: true,
        },
        // 报名学生
        {
          itemType: "input",
          prop: "studentName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.registeredStudents"),
          clearable: true,
          itemStyle: "width:150px;",
        },
        // 年级
        {
          itemType: "select",
          prop: "gradeId",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.courseList.grade"),
          selectDefault: false,
          clearable: true,
          itemStyle: "width:150px;",
          options: this.schoolGradeList,
        },
        // 班级
        {
          itemType: "select",
          prop: "classId",
          size: "small",
          placeholder: this.$t("el.planCourse.classPlaceholder"),
          label: this.$t("el.planCourse.class"),
          selectDefault: false,
          clearable: true,
          itemStyle: "width:150px;",
          disabled: this.classDisabled,
          options: this.classList,
        },
        // 用户手机号
        {
          itemType: "input",
          prop: "phone",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.userPhoneNumber"),
          clearable: true,
          itemStyle: "width:150px;",
        },
        // 课程名称
        {
          itemType: "input",
          prop: "courseName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.courseNameText"),
          clearable: true,
          itemStyle: "width:150px",
        },
        // 课程来源
        {
          itemType: "select",
          prop: "courseSourse",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.courseSource"),
          clearable: true,
          itemStyle: "width:150px;",
          options: [
            { label: "机构", value: 1 },
            { label: "区域", value: 2 },
          ],
        },
        // 适用年级
        {
          itemType: "select",
          prop: "gradeIds",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.applyGrade"),
          clearable: true,
          itemStyle: "width:150px;",
          options: this.systemGradeList,
        },
        // 科目
        {
          itemType: "select",
          prop: "subjectId",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.courseList.subject"),
          clearable: true,
          itemStyle: "width:150px;",
          defaultParam: "defaultSchool",
          options: this.courseSubjectList,
        },

        // 课程类别
        {
          itemType: "select",
          prop: "categoryId",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.courseCategory"),
          selectDefault: false,
          clearable: true,
          itemStyle: "width:150px;",
          options: this.courseCategoryList,
        },
        // 状态
        {
          itemType: "select",
          prop: "dropCourse",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.state"),
          selectDefault: false,
          clearable: true,
          itemStyle: "width:150px;",
          options: [
            { label: "已报名", value: 0 },
            { label: "已退课", value: 1 },
          ],
        },
        {
          itemType: "daterange",
          prop: "refundTime",
          size: "small",
          prop1: "startTime",
          prop2: "endTime",
          "popper-append-to-body": false,
          "value-format": "yyyy-MM-dd",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.refundTime"),
          clearable: true,
        },
      ];
    },
    columns() {
      return [
        // 报名时间
        {
          label: this.$t("el.courseClassManage.createTime"),
          prop: "chooseTime",
          width: "200px",
        },
        // 报名学生
        {
          label: this.$t("el.planCourse.registeredStudents"),
          prop: "studentName",
          width: "100px",
        },
        // 年级
        {
          label: this.$t("el.courseList.grade"),
          prop: "gradeName",
          width: "100px",
        },
        // 班级
        {
          label: this.$t("el.planCourse.class"),
          prop: "className",
          width: "100px",
        }, // 实付金额
        {
          label: this.$t("el.order.shifujine") + this.$t("el.order.yuan"),
          prop: "actualAmount",
          width: "120px",
          formatter: (row, column, value) => {
            return value === null || value === undefined ? "-" : value;
          },
        },
        // 手机号
        {
          label: this.$t("el.planCourse.userPhoneNumber"),
          prop: "phone",
          width: "120px",
        },
        // 课程名称
        {
          label: this.$t("el.planCourse.CourseName"),
          prop: "courseName",
          width: "180px",
        },
        // 课程班级
        {
          label: this.$t("el.planCourse.courseClass"),
          prop: "courseClassName",
          width: "140px",
        },
        // 课程来源
        {
          label: this.$t("el.planCourse.courseSource"),
          prop: "courseSource",
          width: "80px",
          formatter: ({ courseSource }) =>
            courseSource * 1 === 1 ? "机构" : "区域",
        },
        // 适用年级
        {
          label: this.$t("el.planCourse.applyGrade"),
          prop: "gradeIds",
          width: "160px",
        },
        // 科目
        {
          label: this.$t("el.courseList.subject"),
          prop: "subject",
          width: "120px",
        },
        // 课程类别
        {
          label: this.$t("el.planCourse.courseCategory"),
          prop: "courseType",
          width: "100px",
        },
        // 状态
        {
          label: this.$t("el.planCourse.state"),
          prop: "dropCourse",
          width: "70px",
          formatter: ({ dropCourse }) => ["报名", "退课"][dropCourse * 1],
        },
        // 退课时间
        {
          label: this.$t("el.planCourse.refundTime"),
          prop: "refundTime",
          width: "160px",
          formatter: ({ refundTime }) => refundTime || "-",
        },
      ];
    },
  },
  methods: {
    goback() {
      // this.$parent.pageState = 1;
      this.$router.go(-1);
    },
    query() {
      if (this.$refs["xmTable"]) {
        this.$refs["xmTable"].query();
      }
    },
    handParams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      return params;
    },
    fatchMethod(params) {
      const { signUpTime, refundTime, ...rest } = params;
      const [signUpStartTime, signUpEndTime] = Array.isArray(signUpTime)
        ? signUpTime
        : [];
      const [refundStartTime, refundEndTime] = Array.isArray(refundTime)
        ? refundTime
        : [];
      return getResultList(
        this.handParams({
          signUpStartTime,
          signUpEndTime,
          refundStartTime,
          refundEndTime,
          taskId: this.taskId,
          ...rest,
        })
      )
        .then((result) => {
          if (result.status === 200) {
            return result;
          }
          return [];
        })
        .catch(() => {
          return null;
        });
    },

    resetForm() {
      this.$refs["xmTable"].resetForm();
    },

    resetFormCallback(params) {
      console.log("重置回调", params);
    },

    changeQueryParams({ gradeId }, { gradeId: oldGradeId }) {
      if (gradeId && gradeId !== oldGradeId) {
        this.$refs["xmTable"].setFormValue("classId", "");
        getClassListByGradeId(gradeId).then(({ status, data }) => {
          if (status === 200) {
            this.classDisabled = false;
            this.classList = data.map(({ className, classId }) => ({
              label: className,
              value: classId,
            }));
          }
        });
      } else if (!gradeId) {
        this.$refs["xmTable"].setFormValue("classId", "");
        this.classDisabled = true;
      }
    },

    // 获取学校内年级列表
    getCourseRangeList() {
      getClasslist().then(({ status, data }) => {
        if (status === 200) {
          this.schoolGradeList = data.map(({ gradeName, gradeId }) => ({
            label: gradeName,
            value: gradeId,
          }));
        }
      });
    },

    // 获取系统内年级列表
    getSystemGradeList() {
      getAllGradeList().then(({ status, data }) => {
        if (status === 200) {
          this.systemGradeList = data.map(({ name, id }) => ({
            label: name,
            value: id,
          }));
        }
      });
    },

    // 获取课程类别下拉数据
    getCourseCategoryList() {
      getCourseCategory().then(({ status, data }) => {
        if (status === 200) {
          this.courseCategoryList = data.map(({ cnName, id }) => ({
            label: cnName,
            value: id,
          }));
        }
      });
    },

    // 获取科目下拉数据
    getCourseSubjectList() {
      getCourseSubject().then(({ status, data }) => {
        if (status === 200) {
          this.courseSubjectList = data.map(({ name, id }) => ({
            label: name,
            value: id,
          }));
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.view-res-box {
  .back {
    margin-bottom: 20px;
  }
  .gary {
    color: gray;
    font-size: 14px;
  }
}
</style>
