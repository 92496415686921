<template>
  <div class="teacher-attendance" :key="isTeacherAttendance">
    <div v-if="isTeacherAttendance" class="syncedTip">
      {{ $t("el.planCourse.syncedTip") }}
    </div>
    <xm-table
      ref="xmTable"
      :fatch-method="fatchMethod"
      :form-items="formItems"
      :columns="columns"
      :formOptions="{
        hiddenDefaultFormBtn: true,
      }"
      :autoLoad="false"
      :row-key="getRowKeys"
      noEmptyText="el.common.noData"
      @selection-change="selectionChange"
      @changeQueryParams="changeQueryParams"
      @reset-form="resetFormCallback"
    >
      <template slot="formBtn">
        <el-button
          type="primary"
          size="small"
          class="teachManage-tool-btnicon"
          @click="query()"
          >{{ $t("el.common.search") }}</el-button
        >
        <el-button
          size="small"
          class="teachManage-tool-btnicon"
          @click="resetForm()"
          >{{ $t("el.common.reset") }}</el-button
        >
        <el-button size="small" @click="handlBeulkModification">{{
          $t("el.planCourse.bulkModification")
        }}</el-button>
      </template>
      <template slot="otherHandle" slot-scope="{ row }">
        <el-button
          v-if="isTeacherAttendance"
          :loading="row.loading"
          type="text"
          @click="handleChangeAttendance(row)"
          >{{ $t("el.planCourse.modifyAttendance") }}</el-button
        >
        <el-button
          v-if="!isTeacherAttendance && row.status === 0"
          :loading="row.loading"
          type="text"
          @click="handleChangeAttendanceStudent(row)"
          >{{ $t("el.planCourse.changedToAbsence") }}</el-button
        >
        <el-button
          v-if="!isTeacherAttendance && row.status === 1"
          :loading="row.loading"
          type="text"
          @click="handleChangeAttendanceStudent(row)"
          >{{ $t("el.planCourse.changeToNormal") }}</el-button
        >
      </template>
    </xm-table>
    <c-dialog
      v-if="dialogVisible"
      ref="dialogTeacherAttendance"
      :formItems="formDialogItems"
      :formOptions="dialogFormOptions"
      :visible="dialogVisible"
      :optionsData="optionsData"
      @submit-form="submitForm"
      @close-dialog="closeClassDialog"
      @onSelectChange="onSelectChange"
    >
      <div slot="customView" v-if="!isBulkQuery">
        <div class="teacher-attendance-dialog-content">
          <div class="content">
            <p class="content-p">
              <span class="content-label">{{
                $t("el.planCourse.school") + $t("el.common.colon")
              }}</span
              ><span class="content-value">{{ rowData.schoolName }}</span>
            </p>
            <p class="content-p">
              <span class="content-label">{{
                $t("el.planCourse.course") + $t("el.common.colon")
              }}</span
              ><span class="content-value">{{ rowData.courseName }}</span>
            </p>
            <p class="content-p">
              <span class="content-label">{{
                $t("el.planCourse.teachingClasses") + $t("el.common.colon")
              }}</span
              ><span class="content-value">{{ rowData.className }}</span>
            </p>
            <p class="content-p">
              <span class="content-label">{{
                $t("el.planCourse.teachingTime") + $t("el.common.colon")
              }}</span
              ><span class="content-value"
                >{{ moment(rowData.courseDate, "yyyy-MM-DD") }}
                {{ rowData.periodStart }}-{{ rowData.periodEnd }}</span
              >
            </p>
          </div>
        </div>
      </div>
    </c-dialog>
  </div>
</template>

<script>
import {
  teacherAttendancePage,
  updateAttendance,
  updateAttendanceById,
  studentAttendancePage,
  updateStudentAttendance,
  updateStudentAttendanceById,
  fetchTeacherList,
} from "@/api/manage/course-list";
import { handparams } from "@/utils";
import moment from "moment";
import CDialog from "@/components/biz-components/CDialog/index.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    CDialog,
  },
  props: {},
  data() {
    return {
      selections: null,
      dialogVisible: false,
      isBulkQuery: false,
      optionsData: {
        dialogTitle: this.$t("el.planCourse.bulkModification"),
        dialogTitleText: "",
        width: "422px",
      },
      formDialogItems: [],
      dialogFormOptions: {},
      schoolList: [],
      courseList: [],
      courseClassList: [],
      formData: {
        schoolCode: "", // 所在学校/学部
        courseName: "", // 课程名称
        classId: "", // 教学班
        teachingTime: "", // 导入时间
        staffName: "", // 姓名
        telephone: "", // 手机号码
        status: "", // 出勤状态
      },
      rowData: null, // 修改考勤单条数据
      isTeacherAttendance: true, // 老师考勤和学生考勤共用界面，用此字段判断
      isSelectDefault: true, // 是否默认选择学校/学部
      nowSchoolCode: "", // 当前学校/学部
      isAllowFetch: true, // 切换路由的时候 schoolCode被赋值了2次，一次初始化，一次置为第一个选择项
      teacherList: [],
      remoteLoading: false,
    };
  },
  methods: {
    moment(value, format) {
      return moment(value).format(format);
    },
    /**
     * 获取列表数据的请求方法
     */
    fatchMethod(params) {
      if (!params.teachingTime) {
        params.startDate = params.endDate = "";
      } else {
        params.startDate += ":00";
        params.endDate += ":00";
      }
      params.courseId = params.courseName;
      let data = handparams(params);
      delete data.teachingTime;
      delete data.courseName;
      if (this.isTeacherAttendance) {
        return teacherAttendancePage(data)
          .then((result) => {
            return result;
          })
          .catch(() => {
            return null;
          });
      } else {
        return studentAttendancePage(data)
          .then((result) => {
            return result;
          })
          .catch(() => {
            return null;
          });
      }
    },
    /**
     * 筛选
     */
    query() {
      if (this.$refs["xmTable"]) {
        this.$refs["xmTable"].query();
      }
    },
    /**
     * 重置
     */
    resetForm() {
      this.$refs["xmTable"].resetForm();
    },
    resetFormCallback(queryParams) {
      this.setDefaultSchool(queryParams, this.schoolList);
    },
    // 设置默认学校
    setDefaultSchool(queryParams, schoolList) {
      if (schoolList && schoolList.length > 0) {
        this.$set(queryParams, "schoolCode", schoolList[0].schoolCode);
        this.isAllowFetch = false;
        this.getCourseCLassList();
        this.getCourseList();
      }
    },
    fetchTeacherList(courseId, schoolCode) {
      let params = {
        courseId: courseId,
        schoolCode: schoolCode,
      };
      fetchTeacherList(params).then((res) => {
        this.teacherList = res.data;
        this.teacherList = this.formateList(
          this.teacherList,
          "userId",
          "staffName"
        );
        this.formDialogItems = this.dialogItemsAttendance;
      });
    },
    async handleChangeAttendance(row) {
      await this.fetchTeacherList(row.courseId, row.schoolCode);
      this.dialogFormOptions = this.dialogFormOptionsAttendance;
      this.optionsData = {
        dialogTitle: this.$t("el.planCourse.modifyAttendance"),
        dialogTitleText: "",
        width: this.$t("el.common.widthP40_50"),
      };
      this.isBulkQuery = false;
      this.rowData = row;
      this.dialogVisible = true;
      this.$nextTick(() => {
        let params = {
          userId: row.userId, // TODO:
          staffName: row.userId,
          telephone: row.telephone,
          status: row.status,
        };
        this.$refs["dialogTeacherAttendance"].queryParams = params;
      });
    },
    onSelectChange(item, id) {
      item.options.forEach((el) => {
        if (id === el.userId) {
          this.$refs["dialogTeacherAttendance"].queryParams.telephone =
            el.mobile;
        }
      });
    },
    handlBeulkModification() {
      if (this.selections && this.selections.length) {
        this.optionsData.dialogTitleText = this.$t("el.planCourse.selectData", [
          this.selections.length,
        ]);
        this.formDialogItems = this.dialogItemsBulk;
        this.dialogFormOptions = this.dialogFormOptionsBulk;
        this.isBulkQuery = true;
        this.dialogVisible = true;
      } else {
        this.$message.warning(this.$t("el.planCourse.selectFirst"));
      }
    },
    selectionChange(selections) {
      this.selections = selections;
    },
    getRowKeys(row) {
      return row.id;
    },
    submitForm(refFormName, dialogForm) {
      refFormName.validate(async (valid) => {
        if (valid) {
          if (this.isBulkQuery) {
            let ids = [];
            this.selections.forEach((el) => {
              ids.push(el.id);
            });
            let params = {
              ids: ids,
              status: dialogForm.status,
            };
            if (this.isTeacherAttendance) {
              updateAttendance(params).then(() => {
                this.$message.success(this.$t("el.common.querySuccess"));
                this.query();
                this.$refs.xmTable.$refs.table.clearSelection();
                this.selections = [];
              });
            } else {
              updateStudentAttendance(params).then(() => {
                this.$message.success(this.$t("el.common.querySuccess"));
                this.query();
                this.$refs.xmTable.$refs.table.clearSelection();
                this.selections = [];
              });
            }
          } else {
            let params = {
              userId: dialogForm.staffName,
              status: dialogForm.status,
              teacherAttendanceId: this.rowData.id,
            };
            updateAttendanceById(params).then(() => {
              this.$message.success(this.$t("el.common.querySuccess"));
              //   this.query();
              this.$refs["xmTable"].fatchListData();
            });
          }
          this.closeClassDialog();
        }
      });
    },
    // 关闭dialog
    closeClassDialog() {
      this.dialogVisible = false;
    },
    async init() {
      this.$nextTick(() => {
        this.query();
      });
    },
    // 获取学校列表
    async getAuthSchoolList() {
      await this.$store.dispatch("getSchoolList");
      this.schoolList = this.attendSchoolList;
      if (this.schoolList.length > 0) {
        this.isSelectDefault = true;
        this.isAllowFetch = true;
        this.nowSchoolCode = this.attendSchoolList[0].schoolCode;
      }
      return this.attendSchoolList;
    },
    getRemoteCourseList(courseName) {
      if (courseName.length >= 2) {
        this.remoteLoading = true;
        this.getCourseList(courseName);
      } else {
        this.courseList = [];
      }
    },
    // 获取课程下拉框数据
    async getCourseList(courseName) {
      if (!this.formData.classId && !courseName) return;
      let data = {
        // schoolCode: this.formData.schoolCode,
        classId: this.formData.classId,
        courseName: courseName || "",
        courseTableType: 1,
      };
      await this.$store.dispatch("getTableCourseOptions", data).then((res) => {
        let courseList = res.data;
        this.courseList = this.formateList(courseList, "id", "courseName");
        this.remoteLoading = false;
      });
    },
    // 获取课程班级下拉框数据
    async getCourseCLassList(v = "") {
      if (!v) {
        this.courseClassList = [];
        return;
      }
      let data = {
        schoolCode: this.formData.schoolCode,
        courseId: this.formData.courseName,
        courseTableType: 1,
        className: v,
      };
      await this.$store
        .dispatch("getTotalTableClassOptions", data)
        .then((res) => {
          let courseClassList = res.data;
          this.courseClassList = this.formateList(
            courseClassList,
            "id",
            "className"
          );
        });
    },
    changeQueryParams(formData) {
      this.formData = formData;
      if (formData.schoolCode) {
        if (formData.schoolCode !== this.nowSchoolCode) {
          this.isSelectDefault = false;
          this.nowSchoolCode = formData.schoolCode;
        }
      }
    },
    // 列表数据格式化
    formateList(data, valueData, labelData) {
      data.forEach((item) => {
        this.$set(item, "value", item[valueData]);
        this.$set(item, "label", item[labelData]);
      });
      return data;
    },
    handleChangeAttendanceStudent(row) {
      // 修改学生考勤
      this.$confirm("", "", {
        center: true,
        customClass: "el-message-box--confirm-right",
        message:
          row.status === 0
            ? this.$t("el.planCourse.changedToAbsenceConfirm")
            : this.$t("el.planCourse.changeToNormalConfim"),
      }).then(() => {
        let params = {
          status: row.status === 0 ? 1 : 0,
          studentAttendanceId: row.id,
        };
        updateStudentAttendanceById(params).then(() => {
          this.$message.success(this.$t("el.common.querySuccess"));
          //   this.query();
          this.$refs["xmTable"].fatchListData();
        });
      });
    },
    // 远程搜索教学班
    handleRemoteClass(v) {
      this.getCourseCLassList(v);
    },
  },
  created() {},
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters(["attendSchoolList"]),
    statusOptions() {
      return [
        {
          value: 0,
          label: this.$t("el.planCourse.normal"),
        },
        {
          value: 1,
          label: this.$t("el.planCourse.absenteeism"),
        },
      ];
    },
    // 定义查询表单
    formItems() {
      let self = this;
      return [
        // {
        //   itemType: "select",
        //   prop: "schoolCode",
        //   size: "small",
        //   options: this.schoolList,
        //   "popper-append-to-body": true,
        //   placeholder: this.$t("el.common.pleaseSelect"),
        //   label: this.$t("el.MyHome.depart"),
        //   selectDefault: this.isSelectDefault,
        // },
        {
          itemType: "select",
          prop: "courseName",
          size: "small",
          options: this.courseList,
          "popper-append-to-body": true,
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.CourseName"),
          clearable: true,
          filterable: true,
          remote: true,
          "remote-method": this.getRemoteCourseList,
          loading: this.remoteLoading,
        },
        {
          itemType: "select",
          prop: "classId",
          size: "small",
          options: this.courseClassList,
          "popper-append-to-body": true,
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.teachingClasses"),
          filterable: true,
          remote: true,
          "remote-method": this.handleRemoteClass,
          clearable: true,
        },
        {
          itemType: "datetimerange",
          prop: "teachingTime",
          size: "small",
          prop1: "startDate",
          prop2: "endDate",
          "popper-append-to-body": true,
          "value-format": "yyyy-MM-dd HH:mm",
          "start-placeholder": this.$t("el.common.startTime"),
          "end-placeholder": this.$t("el.common.endTime"),
          format: "yyyy-MM-dd HH:mm",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.teachingTime"),
          clearable: true,
          "time-arrow-control": true,
        },
        {
          itemType: "input",
          prop: self.isTeacherAttendance ? "staffName" : "studentName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.authDialog.name"),
          clearable: true,
        },
        {
          itemType: "input",
          prop: self.isTeacherAttendance ? "telephone" : "studentNumber",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: self.isTeacherAttendance
            ? this.$t("el.forgotPwd.MobileNumber")
            : this.$t("el.courseClassManage.studentNumber"),
          clearable: true,
        },
        {
          itemType: "select",
          prop: "status",
          size: "small",
          options: this.statusOptions,
          "popper-append-to-body": true,
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.statusOfAttendance"),
          clearable: true,
        },
      ];
    },
    /**
     * 定义列
     */
    columns() {
      let self = this;
      return [
        {
          type: "selection",
          "reserve-selection": true,
          width: "48px",
        },
        {
          label: self.isTeacherAttendance
            ? this.$t("el.planCourse.teacherName")
            : this.$t("el.authDialog.name"),
          prop: self.isTeacherAttendance ? "staffName" : "studentName",
        },
        {
          label: self.isTeacherAttendance
            ? this.$t("el.forgotPwd.MobileNumber")
            : this.$t("el.courseClassManage.studentNumber"),
          prop: self.isTeacherAttendance ? "telephone" : "studentNumber",
          width: "100px",
        },
        // {
        //   label: this.$t("el.dataAnalysis.schoolOrDepartment"),
        //   prop: "schoolName",
        // },
        {
          label: this.$t("el.planCourse.course"),
          prop: "courseName",
        },
        {
          label: this.$t("el.planCourse.teachingClasses"),
          prop: "className",
        },
        {
          label: this.$t("el.planCourse.teachingDate"),
          prop: "courseDate",
          formatter(row, column, cellValue) {
            return moment(cellValue).format("yyyy-MM-DD");
          },
          width: "120px",
        },
        {
          label: this.$t("el.planCourse.startTime"),
          prop: "periodStart",
        },
        {
          label: this.$t("el.planCourse.endTime"),
          prop: "periodEnd",
        },
        {
          label: this.$t("el.planCourse.statusOfAttendance"),
          prop: "status",
          formatter(row, column, cellValue) {
            return self.statusOptions[cellValue].label;
          },
        },
        {
          label: this.$t("el.common.operate"),
          slotName: "otherHandle",
          fix: "right",
          width: "160px",
        },
      ];
    },
    // 批量修改弹窗-form数据
    dialogItemsBulk() {
      return [
        {
          itemType: "radio",
          prop: "status",
          label: this.$t("el.planCourse.statusOfAttendance"),
          labelWidth: this.$t("el.common.width120_220"),
          clearable: true,
          itemStyle: "width:220px;",
          maxlength: 10,
          formStyle: "width:100%;box-sizing: border-box;",
          options: [
            {
              title: this.$t("el.planCourse.normal"),
              label: 0,
            },
            {
              title: this.$t("el.planCourse.absenteeism"),
              label: 1,
            },
          ],
        },
      ];
    },
    // 修改考勤-form数据
    dialogItemsAttendance() {
      let self = this;
      return [
        {
          itemType: "select",
          prop: self.isTeacherAttendance ? "staffName" : "studentName",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: self.isTeacherAttendance
            ? this.$t("el.planCourse.teacherName")
            : this.$t("el.authDialog.name"),
          labelWidth: this.$t("el.common.width120_220"),
          clearable: true,
          itemStyle: "width:220px;",
          maxlength: 10,
          formStyle: "width:100%;box-sizing: border-box;",
          filterable: true,
          options: this.teacherList,
          "popper-append-to-body": true,
        },
        {
          itemType: "text",
          prop: self.isTeacherAttendance ? "telephone" : "studentNumber",
          label: self.isTeacherAttendance
            ? this.$t("el.forgotPwd.MobileNumber")
            : this.$t("el.courseClassManage.studentNumber"),
          labelWidth: this.$t("el.common.width120_220"),
          clearable: true,
          itemStyle: "width:220px;",
          maxlength: 10,
          formStyle: "width:100%;box-sizing: border-box;",
        },
        {
          itemType: "radio",
          prop: "status",
          label: this.$t("el.planCourse.statusOfAttendance"),
          labelWidth: this.$t("el.common.width120_220"),
          clearable: true,
          itemStyle: "width:220px;",
          maxlength: 10,
          formStyle: "width:100%;box-sizing: border-box;",
          options: [
            {
              title: this.$t("el.planCourse.normal"),
              label: 0,
            },
            {
              title: this.$t("el.planCourse.absenteeism"),
              label: 1,
            },
          ],
        },
      ];
    },
    // 批量修改弹窗-form rules
    dialogFormOptionsBulk() {
      return {
        rules: {},
      };
    },
    // 修改考勤-form rules
    dialogFormOptionsAttendance() {
      return {
        rules: {
          staffName: [
            {
              required: true,
              message: this.$t("el.planCourse.teacherNameInput"),
              trigger: "blur",
            },
          ],
          telephone: [
            {
              required: true,
              trigger: "blur",
            },
          ],
          status: [
            {
              required: true,
              trigger: "blur",
            },
          ],
        },
      };
    },
  },
  watch: {
    $route: {
      handler(val) {
        this.selections = null;
        this.dialogVisible = false;
        this.isBulkQuery = false;
        this.formDialogItems = [];
        this.dialogFormOptions = {};
        this.schoolList = [];
        this.courseList = [];
        this.courseClassList = [];
        this.rowData = null; // 修改考勤单条数据
        this.nowSchoolCode = ""; // 当前学校/学部
        this.isSelectDefault = true;
        if (val.name === "teacherAttendance") {
          this.isTeacherAttendance = true;
          this.formData = {
            schoolCode: "", // 所在学校/学部
            courseName: "", // 课程名称
            classId: "", // 教学班
            teachingTime: "", // 导入时间
            staffName: "", // 姓名
            telephone: "", // 手机号码
            status: "", // 出勤状态
          };
        } else {
          this.isTeacherAttendance = false;
          this.formData = {
            schoolCode: "", // 所在学校/学部
            courseName: "", // 课程名称
            classId: "", // 教学班
            teachingTime: "", // 导入时间
            studentName: "", // 姓名
            studentNumber: "", // 学号
            status: "", // 出勤状态
          };
        }
        this.isAllowFetch = false;
        this.init();
      },
      immediate: true,
    },
    formData: {
      handler(newVal, oldVal) {
        if (this.isAllowFetch) {
          if (
            newVal.schoolCode !== oldVal.schoolCode &&
            newVal.courseName === oldVal.courseName &&
            newVal.classId === oldVal.classId
          ) {
            this.$refs["xmTable"].setFormValue("courseName", "");
            this.$refs["xmTable"].setFormValue("classId", "");
            this.getCourseCLassList();
            this.getCourseList();
          } else if (newVal.courseName !== oldVal.courseName) {
            this.getCourseCLassList();
          } else if (newVal.classId !== oldVal.classId) {
            this.getCourseList();
          }
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.teacher-attendance {
  padding: 30px 50px;
  background-color: #fff;
  border-radius: 10px;
  .syncedTip {
    text-align: right;
    font-size: 14px;
    color: #807f7f;
    margin-bottom: 12px;
  }
  .teacher-attendance-dialog-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    .content {
      // width: 80%;
      display: flex;
      flex-wrap: wrap;
      .content-p {
        margin-right: 24px;
        line-height: 44px;
      }
    }
  }
  /deep/ .el-button {
    padding: 9px 15px;
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
  /deep/ .el-button--default {
    color: #6049ff;
    background-color: #fff;
    border-color: #6049ff !important;
  }
  .c-dialog /deep/ .el-dialog__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .c-dialog /deep/ .el-form {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .el-form-item {
      display: flex;
      &.btn-content {
        justify-content: center;
      }
    }
    .el-form-item__content {
      min-width: calc(100% - 160px);
    }
  }
  .c-dialog {
    /deep/ .btn-content {
      text-align: center !important;
    }
  }
}
</style>
