<template>
  <!-- 总课表/导入课表管理 -->
  <div class="total-course-list" :key="isTotalCourseListByImport">
    <div class="list-params">
      <!-- <div class="list-params-wrap long">
        <label> {{ $t("el.planCourse.school") + $t("el.common.colon") }}</label>
        <el-select
          v-model="formData.schoolCode"
          :placeholder="$t('el.planCourse.selectCourse')"
          class="filter-item"
        >
          <el-option
            v-for="(option, i) in schoolList"
            v-bind="option"
            :key="i"
          />
        </el-select>
      </div> -->
      <div class="list-params-wrap">
        <label>{{ $t("el.planCourse.course") + $t("el.common.colon") }}</label>
        <el-select
          v-model="formData.courseId"
          :placeholder="$t('el.planCourse.selectCourse')"
          clearable
          filterable
          class="filter-item"
          :remote="isTotalCourseListByImport ? true : false"
          :remote-method="getRemoteCourseList"
          :loading="remoteLoading"
        >
          <el-option
            v-for="(option, i) in courseList"
            v-bind="option"
            :key="i"
          />
        </el-select>
      </div>
      <div class="list-params-wrap">
        <label>{{
          $t("el.planCourse.courseClass") + $t("el.common.colon")
        }}</label>
        <el-select
          v-model="formData.classId"
          :placeholder="$t('el.planCourse.SelectClass')"
          clearable
          class="filter-item"
        >
          <el-option
            v-for="(option, i) in courseClassList"
            v-bind="option"
            :key="i"
          />
        </el-select>
      </div>
      <div class="list-params-wrap">
        <label>{{ $t("el.planCourse.teacher") + $t("el.common.colon") }}</label>
        <el-select
          v-model="formData.userId"
          :placeholder="$t('el.planCourse.selectTeacher')"
          clearable
          filterable
          class="filter-item"
        >
          <el-option
            v-for="(option, i) in teacherList"
            v-bind="option"
            :key="i"
          />
        </el-select>
      </div>
      <div class="list-params-wrap">
        <label>{{
          $t("el.planCourse.classroom") + $t("el.common.colon")
        }}</label>
        <el-select
          v-model="formData.classroomId"
          :placeholder="$t('el.planCourse.chooseClassroom')"
          clearable
          class="filter-item"
        >
          <el-option
            v-for="(option, i) in clssroomList"
            v-bind="option"
            :key="i"
          />
        </el-select>
      </div>
      <div class="list-params-wrap">
        <label>{{
          $t("el.planCourse.periodTime") + $t("el.common.colon")
        }}</label>
        <el-time-picker
          v-if="showPicker"
          v-model="formData.dateRange"
          arrow-control
          is-range
          :range-separator="$t('el.planCourse.to')"
          :start-placeholder="$t('el.planCourse.startTime')"
          :end-placeholder="$t('el.planCourse.endTime')"
          :placeholder="$t('el.planCourse.selectTimeRange')"
          :format="'HH:mm'"
          @change="
            (dateRange) => changeData(dateRange, 'startPeriod', 'endPeriod')
          "
          @blur="timePickerBlur"
          @clear="timePickerBlur"
        />
      </div>
      <el-button
        @click="
          loadingDetails = true;
          getGeneralWeekData();
        "
        type="primary"
        size="small"
        class="mt20"
        >{{ $t("el.common.screening") }}</el-button
      >
      <el-button @click="reset()" size="small">{{
        $t("el.common.reset")
      }}</el-button>
      <el-button
        size="small"
        @click="exportCourseTable"
        v-loading="exportLoading"
        :disabled="exportLoading"
        >{{ $t("el.common.export") }}</el-button
      >
      <import-tools
        v-if="isTotalCourseListByImport"
        :downHref="baseUrl + '/model/modelDownload?type=phkh_course'"
        :action="baseUrl + '/import/course/importCourse'"
        :elementLoadingText="$t('el.planCourse.totalScheduleByImportLoading')"
        @handleSuccess="handleSuccessImport"
      />
    </div>
    <div class="week-show-content">
      <week-start-end-components
        @changeWeekDateRange="changeWeekDateRange"
      ></week-start-end-components>
      <div v-loading="loadingDetails" class="total-course-list-content">
        <table>
          <tr class="table-header">
            <th v-for="(item, index) in weekData" :key="index">
              {{ $t(numberTransformChinese(index)) }} {{ item }}
            </th>
          </tr>
        </table>

        <div v-show="maxLength !== 0" class="total-course-list-content-data">
          <div class="day-wrap" v-for="(num, nidex) in 7" :key="nidex">
            <div
              :class="{ 'border-left': nidex === 0 }"
              v-for="(item, index) in listData[`w${num}`]"
              :key="index"
            >
              <div class="day-content" v-if="item.coursePeriod">
                <el-popover
                  placement="top-start"
                  popper-class="hover-plan-course-details"
                  offset="50"
                  width="220"
                  trigger="hover"
                >
                  <div class="hover-details">
                    <p class="course-name">
                      {{ $t("el.myTimeTable.Courses") }}
                      {{ item.courseName }}
                    </p>
                    <p class="course-name">
                      {{ $t("el.myTimeTable.Class") }}
                      {{ item.className }}
                    </p>
                    <p class="course-name">
                      {{ $t("el.myTimeTable.Time") }}
                      {{ weekData[nidex] }}
                      {{ item.coursePeriod }}
                    </p>
                    <p class="course-name">
                      {{ $t("el.myTimeTable.Teacher") }}
                      {{ item.staffName }}
                    </p>
                    <p class="course-name">
                      {{ $t("el.myTimeTable.Classroom") }}
                      {{ item.classroomName }}
                    </p>
                  </div>
                  <div slot="reference">
                    <p class="day-content-period one-line-ellipsis">
                      {{ item.coursePeriod }}
                    </p>
                    <p class="day-content-name">{{ item.courseName }}</p>
                    <p class="day-content-details one-line-ellipsis">
                      <span class="class-name">{{ item.className }} </span>
                      <span class="staff-name"> / {{ item.staffName }}</span>
                    </p>
                  </div>
                </el-popover>
              </div>
              <div class="day-content" v-else>
                <p class="no-detail" v-if="index === 0">
                  {{ $t("el.planCourse.noArrangement") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-show="maxLength === 0" class="empty-content-data">
          <div style="padding-top: 111px; line-height: 0">
            <img
              src="@/assets/images/pic_empty_people@2x.png"
              alt=""
              style="width: 270px"
            />
          </div>
          <div
            style="
              color: rgba(100, 108, 149, 0.25);
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 1px;
              padding-bottom: 120px;
            "
          >
            {{ $t("el.common.noData") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getdiffdate, getFormatterTime } from "@/utils/index";
import {
  exportCourseTable,
  exportImportCourse,
} from "@/api/manage/course-list";

import { mapGetters } from "vuex";
import weekStartEndComponents from "../../../components/use-components/week-startEnd-components";
import ImportTools from "@/components/import-tools/index.vue";

export default {
  components: { weekStartEndComponents, ImportTools },
  // 定义数据
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      queryParams: {},
      // allLoading: false,
      exportLoading: false,
      // schoolList: [],
      courseList: [],
      courseClassList: [],
      teacherList: [],
      clssroomList: [],
      startDate: "",
      endDate: "",
      defaultSchoolCode: "",
      weekData: [],
      formData: {
        schoolCode: "",
        courseId: "",
        classId: "",
        userId: "",
        classroomId: "",
        dateRange: "",
        startPeriod: "",
        endPeriod: "",
        courseTableType: 1,
      },
      listData: {
        w1: [],
        w2: [],
        w3: [],
        w4: [],
        w5: [],
        w6: [],
        w7: [],
      },
      maxLength: null,
      loadingDetails: false,
      isreset: false,
      showPicker: true,
      isTotalCourseListByImport: false, // 判断是否为导入课表管理；总课表页面复制一份，页面名称为【导入课表管理】，总课表页面和逻辑保留，导入课表管理只展示导入的排课数据
      remoteLoading: false,
    };
  },
  // 计算属性
  computed: {
    ...mapGetters(["attendSchoolList"]),
  },
  watch: {
    /**
     * 更改学校的时候，重新请求
     * 课程下拉框数据
     * 课程班级下拉框数据
     * 老师下拉框数据
     * 教室下拉框数据
     * */
    "formData.schoolCode": {
      handler(val, old) {
        console.log("old========", old);
        console.log(val, old);
        if (old !== "") {
          if (val) {
            if (!this.isreset) {
              this.getResetSchool();
            }
          }
        }
      },
      deep: true,
    },
    "formData.courseId": {
      handler(val, old) {
        console.log(val, old);
        if (!this.isreset) {
          this.getResetCourse();
        }
      },
      deep: true,
    },
    "formData.classId": {
      handler(val, old) {
        console.log(val, old);
        if (!this.isreset) {
          this.getResetClass();
        }
      },
      deep: true,
    },
    "formData.userId": {
      handler(val, old) {
        console.log(val, old);
        if (!this.isreset) {
          this.getResetTeacher();
        }
      },
      deep: true,
    },
    "formData.classroomId": {
      handler(val, old) {
        console.log(val, old);
        if (!this.isreset) {
          this.getResetClassroom();
        }
      },
      deep: true,
    },
    "formData.dateRange": {
      handler(val, old) {
        console.log(val, old);
        if (val !== null) {
          if (val.length === 2) {
            if (val[0] === "") {
              this.formData.dateRange[0] = new Date();
            } else if (val[1] === "") {
              this.formData.dateRange[1] = new Date();
            } else {
              console.log(this.formData.startPeriod);
              console.log(this.formData.endPeriod);
            }
          }
        } else {
          console.log(this.formData.startPeriod);
          console.log(this.formData.endPeriod);
          this.formData.startPeriod = this.formData.endPeriod = "";
        }
      },
      deep: true,
    },
    $route: {
      handler(val) {
        console.log(val.name === "totalCourseListByImport");
        if (val.name === "totalCourseListByImport") {
          this.isTotalCourseListByImport = true;
          this.reset();
        } else {
          this.isTotalCourseListByImport = false;
          this.reset();
        }
      },
      immediate: true,
    },
  },
  async created() {
    // await this.getAuthSchoolList();
  },
  methods: {
    // 选择时间段
    changeData(data, prop1, prop2) {
      this.showPicker = false;
      console.log(data);
      if (data === null) {
        this.$nextTick(() => {
          this.showPicker = true;
        });
      } else {
        if (data.length !== 2) return;
        console.log(data[0] === "");
        console.log(data[1]);
        if (data[0] !== "" && data[1] !== "") {
          this.formData[prop1] = getFormatterTime(data[0]).substring(0, 5);
          this.formData[prop2] = getFormatterTime(data[1]).substring(0, 5);
        } else {
          this.$message.error("请输入正确的开始时间和结束时间");
          this.formData.dateRange =
            this.formData.startPeriod =
            this.formData.endPeriod =
              "";
          this.showPicker = true;
        }
        console.log(this.formData);
      }
    },
    // 时间选择器 失焦
    timePickerBlur() {
      console.log(8888);
      this.$nextTick(() => {
        this.showPicker = true;
      });
    },
    // 更改选择周
    changeWeekDateRange(data, isChangeLanguage) {
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.weekData = getdiffdate(this.startDate, this.endDate, "/");
      if (data.type === "") {
        this.loadingDetails = true;
        this.getGeneralWeekData();
      }
    },
    // 获取学校列表
    // async getAuthSchoolList() {
    //   await this.$store.dispatch("getSchoolList");
    //   this.schoolList = this.attendSchoolList;
    //   if (this.schoolList.length > 0) {
    //     this.defaultSchoolCode = this.attendSchoolList[0].schoolCode;
    //     this.formData.schoolCode = this.attendSchoolList[0].schoolCode;
    //   } else {
    //     this.maxLength = 0;
    //   }
    //   return this.attendSchoolList;
    // },
    // 列表数据格式化
    formateList(data, valueData, labelData) {
      data.forEach((item) => {
        this.$set(item, "value", item[valueData]);
        this.$set(item, "label", item[labelData]);
      });
      return data;
    },
    getRemoteCourseList(courseName) {
      if (this.isTotalCourseListByImport) {
        if (courseName.length >= 2) {
          this.remoteLoading = true;
          this.getCourseList(courseName);
        } else {
          this.courseList = [];
        }
      }
    },
    // 获取课程下拉框数据
    async getCourseList(courseName) {
      console.log(this.formData.classId);
      if (
        this.isTotalCourseListByImport &&
        !this.formData.classId &&
        !courseName
      )
        return;
      let data = {
        // schoolCode: this.formData.schoolCode,
        classId: this.formData.classId,
        // userId: this.formData.userId,
        // classroomId: this.formData.classroomId,
        // startPeriod: this.formData.startPeriod,
        // endPeriod: this.formData.endPeriod,
        courseName: courseName || "",
        courseTableType: 1,
      };
      if (!this.isTotalCourseListByImport) {
        data = {
          schoolCode: this.formData.schoolCode,
          classId: this.formData.classId,
          userId: this.formData.userId,
          classroomId: this.formData.classroomId,
          // startPeriod: this.formData.startPeriod,
          // endPeriod: this.formData.endPeriod,
          courseTableType: 1,
        };
      }
      await this.$store
        .dispatch(
          !this.isTotalCourseListByImport
            ? "getTotalTableCourseOptions"
            : "getTableCourseOptions",
          data
        )
        .then((res) => {
          let courseList = res.data;
          this.courseList = this.formateList(courseList, "id", "courseName");
          this.remoteLoading = false;
        });
      // await this.getFindIsExist(this.courseList, "courseId");
    },
    // 获取课程班级下拉框数据
    async getCourseCLassList() {
      let data = {
        schoolCode: this.formData.schoolCode,
        courseId: this.formData.courseId,
        // userId: this.formData.userId,
        // classroomId: this.formData.classroomId,
        // startPeriod: this.formData.startPeriod,
        // endPeriod: this.formData.endPeriod,
        courseTableType: 1,
      };
      if (!this.isTotalCourseListByImport) {
        data = {
          schoolCode: this.formData.schoolCode,
          courseId: this.formData.courseId,
          userId: this.formData.userId,
          classroomId: this.formData.classroomId,
          // startPeriod: this.formData.startPeriod,
          // endPeriod: this.formData.endPeriod,
          courseTableType: 1,
        };
      }
      await this.$store
        .dispatch("getTotalTableClassOptions", data)
        .then((res) => {
          let courseClassList = res.data;
          this.courseClassList = this.formateList(
            courseClassList,
            "id",
            "className"
          );
        });
      // await this.getFindIsExist(this.courseClassList, "classId");
    },
    // 获取老师下拉框数据
    async getTeacherList() {
      let data = {
        schoolCode: this.formData.schoolCode,
        // courseId: this.formData.courseId,
        // classId: this.formData.classId,
        // classroomId: this.formData.classroomId,
        // startPeriod: this.formData.startPeriod,
        // endPeriod: this.formData.endPeriod,
        courseTableType: 1,
      };
      if (!this.isTotalCourseListByImport) {
        data = {
          schoolCode: this.formData.schoolCode,
          courseId: this.formData.courseId,
          classId: this.formData.classId,
          classroomId: this.formData.classroomId,
          // startPeriod: this.formData.startPeriod,
          // endPeriod: this.formData.endPeriod,
          courseTableType: 1,
        };
      }
      await this.$store
        .dispatch("getTotalTableTeacherOptions", data)
        .then((res) => {
          let teacherList = res.data;
          this.teacherList = this.formateList(
            teacherList,
            "userId",
            "staffName"
          );
        });
      // await this.getFindIsExist(this.teacherList, "userId");
    },
    // 获取教室下拉框数据
    async getClassroomList() {
      let data = {
        schoolCode: this.formData.schoolCode,
        // courseId: this.formData.courseId,
        // classId: this.formData.classId,
        // userId: this.formData.userId,
        // startPeriod: this.formData.startPeriod,
        // endPeriod: this.formData.endPeriod,
        courseTableType: 1,
      };
      if (!this.isTotalCourseListByImport) {
        data = {
          schoolCode: this.formData.schoolCode,
          courseId: this.formData.courseId,
          classId: this.formData.classId,
          userId: this.formData.userId,
          // startPeriod: this.formData.startPeriod,
          // endPeriod: this.formData.endPeriod,
          courseTableType: 1,
        };
      }
      await this.$store
        .dispatch("getTotalTableClassroomOptions", data)
        .then((res) => {
          let clssroomList = res.data;
          this.clssroomList = this.formateList(clssroomList, "id", "name");
        });
      // await this.getFindIsExist(this.clssroomList, "classroomId");
    },
    // 下拉框列表 更改的时候，判断是否还存在该数据的值，没有则重置
    async getFindIsExist(data, idName) {
      console.log(data, idName);
      if (data.length === 0) {
        this.formData[idName] = "";
      } else if (idName !== "") {
        let isExist = false;
        data.forEach((item) => {
          if (item[idName] === this.formData[idName]) {
            isExist = true;
          }
        });
        if (!isExist) {
          this.formData[idName] = "";
        }
      }
    },
    // 初始化 请求下拉框的字段
    async init() {
      console.log("init======");
      if (!this.isTotalCourseListByImport) {
        await this.getCourseList();
      }
      await this.getCourseCLassList();
      await this.getTeacherList();
      await this.getClassroomList();
      await this.getGeneralWeekData();
    },
    // 重置条件
    async reset() {
      this.courseList =
        this.courseClassList =
        this.teacherList =
        this.clssroomList =
          [];
      // this.formData.schoolCode = this.defaultSchoolCode;
      this.formData.courseId =
        this.formData.classId =
        this.formData.userId =
        this.formData.classroomId =
        this.formData.dateRange =
        this.formData.startPeriod =
        this.formData.endPeriod =
          "";
      this.listData = this.$options.data().listData;
      this.loadingDetails = true;
      this.isreset = true;
      await this.init();
      this.isreset = false;
    },
    // 更改学校
    async getResetSchool() {
      this.formData.courseId =
        this.formData.classId =
        this.formData.userId =
        this.formData.classroomId =
          "";
      await this.getCourseList();
      await this.getCourseCLassList();
      await this.getTeacherList();
      await this.getClassroomList();
    },
    /**
     * 更改课程之后(非清除课程id)，重新请求
     * 班级下拉框数据
     * 老师下拉框数据
     * 教室下拉框数据
     */
    async getResetCourse() {
      await this.getCourseCLassList();
      await this.getTeacherList();
      await this.getClassroomList();
    },
    async getResetClass() {
      await this.getCourseList();
      await this.getTeacherList();
      await this.getClassroomList();
    },
    async getResetTeacher() {
      await this.getCourseList();
      await this.getCourseCLassList();
      await this.getClassroomList();
    },
    async getResetClassroom() {
      await this.getCourseList();
      await this.getCourseCLassList();
      await this.getTeacherList();
    },
    // 日期数字转中文
    numberTransformChinese(index) {
      return [
        "el.common.Monday",
        "el.common.Tuesday",
        "el.common.Wednesday",
        "el.common.Thursday",
        "el.common.Friday",
        "el.common.Saturday",
        "el.common.Sunday",
      ][index];
    },
    // table周数据补全
    listDataFormate() {
      // 先取数组的最大长度值
      let maxLength = 0;
      for (let key in this.listData) {
        if (this.listData[key].length > 0) {
          if (this.listData[key].length > maxLength) {
            maxLength = this.listData[key].length;
          }
        }
      }
      console.log(maxLength);
      this.maxLength = maxLength;
      // 补全结构
      for (let key in this.listData) {
        for (let o = 0; o < maxLength; o++) {
          if (this.listData[key].length < maxLength) {
            let addLength = maxLength - this.listData[key].length;
            for (let ele = 0; ele < addLength; ele++) {
              this.listData[key].push([]);
            }
          }
        }
      }
      console.log(this.listData);
    },
    // 参数处理
    handparams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      return params;
    },
    // 导出课表
    exportCourseTable() {
      this.exportLoading = true;
      let exportFormData = { ...this.formData };
      let params = this.handparams(exportFormData);
      params.startDate = this.startDate.replaceAll("/", "-");
      params.endDate = this.endDate.replaceAll("/", "-");
      console.log(params);
      delete params.dateRange;
      if (this.isTotalCourseListByImport) {
        exportImportCourse(params).then((res) => {
          let url = window.URL.createObjectURL(res);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute(
            "download",
            params.startDate + "至" + params.endDate + "周课表.xlsx"
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.exportLoading = false;
        });
      } else {
        exportCourseTable(params).then((res) => {
          let url = window.URL.createObjectURL(res);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute(
            "download",
            params.startDate + "至" + params.endDate + "周课表.xlsx"
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.exportLoading = false;
        });
      }
    },
    // 获取课程表数据
    async getGeneralWeekData() {
      if (this.formData.endPeriod < this.formData.startPeriod) {
        this.$message.error("开始时间不能大于结束结束时间，请重新选择");
        this.loadingDetails = false;
      } else {
        this.listData = this.$options.data().listData;
        this.maxLength = null;
        let params = this.handparams(this.formData);
        params.startDate = this.startDate.replaceAll("/", "-");
        params.endDate = this.endDate.replaceAll("/", "-");
        console.log(params);
        delete params.dateRange;
        // 根据isTotalCourseListByImport判断当前是总课表还是导入课表管理，调用不同接口
        await this.$store
          .dispatch(
            this.isTotalCourseListByImport
              ? "getGeneralImportCourse"
              : "getGeneralCourseTable",
            params
          )
          .then((res) => {
            let data = res.data;
            if (Array.isArray(data)) {
              if (data.length > 0) {
                data.forEach((item) => {
                  for (let key in this.listData) {
                    console.log(key);
                    if (key === item.dayOfWeek) {
                      this.listData[key] = item.periodContents;
                    }
                  }
                });
                console.log(this.listData);
                this.loadingDetails = false;
                this.listDataFormate();
              } else {
                this.loadingDetails = false;
                this.maxLength = 0;
              }
            } else {
              this.loadingDetails = false;
              this.maxLength = 0;
            }
          })
          .catch(() => {
            this.loadingDetails = false;
          });
      }
    },
    handleSuccessImport() {
      // 导入成功的回调
      this.getGeneralWeekData();
    },
  },
};
</script>

<style lang="less" scoped>
.total-course-list {
  background: #ffffff;
  padding: 30px 50px;
  border-radius: 10px;
  /deep/ .el-form {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      margin-bottom: 22px;
      display: flex;
      margin-right: 20px;
    }
  }
  /deep/ .el-scrollbar {
    height: 340px;
  }
  /deep/ .el-button {
    padding: 9px 15px;
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
  /deep/ .el-button--default,
  .el-button--default:hover {
    color: #6049ff;
    background-color: #fff;
    border-color: #6049ff !important;
  }
  &-content {
    margin-top: 20px;
    table {
      border-collapse: collapse;
      width: 100%;
      .table-header {
        border: 1px solid #ebebeb;
        width: 100%;
      }
      th {
        background: #f5f6f7;
        font-size: 14px;
        color: #323232;
        font-weight: normal;
        width: calc(100% / 7);
        height: 50px;
        line-height: 50px;
        border-right: 1px solid #ebebeb;
        text-align: center;
        padding-left: 12px;
      }
    }
    &-data {
      display: flex;
      width: 100%;
      p {
        margin: 0;
      }
      .day-wrap {
        width: calc(100% / 7);
        background: #fff;
      }
      .day-content {
        height: 65px;
        border: 1px solid #ebebeb;
        border-top: none;
        border-left: none;
        padding: 20px 12px;
        font-size: 14px;
        color: #323232;
        &-period {
          color: #000;
          font-weight: bold;
          margin-bottom: 8px;
        }
        &-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          margin-bottom: 8px;
        }
        &-details {
          margin-bottom: 8px;
        }
        .one-line-ellipsis {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          height: 15px;
        }
        .no-detail {
          color: rgba(#000, 0.25);
        }
      }
      .border-left {
        border-left: 1px solid #ebebeb;
      }
    }
  }
  .week-show-content {
    margin-top: 25px;
    min-height: 420px;
  }
  .list-params {
    display: flex;
    flex-wrap: wrap;
    &-wrap {
      margin-bottom: 22px;
      display: inline-block;
      margin-right: 10px;
      vertical-align: top;
    }
    .long {
      .el-select {
        width: 310px;
      }
    }
    label {
      text-align: right;
      vertical-align: middle;
      font-size: 14px;
      color: #606266;
      line-height: 40px;
      padding: 0 12px 0 0;
    }
  }
  .empty-content-data {
    text-align: center;
    border: 1px solid #ebebeb;
    border-top: none;
  }
  .el-loading-spinner {
    padding: 20px 0;
  }
}
</style>
<style lang="less">
.hover-plan-course-details {
  p {
    color: #6049ff;
    font-size: 14px;
    font-weight: bold;
    margin: 5px 0;
  }
}
</style>
